import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RegisterProductConstants } from '../../../pos-redux/registerProduct/registerProduct.constants';
import { ProductGroupConfiguration } from '../../../pos-redux/registerProduct/productGroupConfiguration.constants';
import { setRegisterProduct, setProductData, getPaginatedData } from '../../../pos-redux/registerProduct/registerProduct.actions';
import './itemGroup.component.scss';
import { productGroupService } from '../../../services/product-group.service';
import { LoadPanel } from 'devextreme-react/load-panel';
import styled from 'styled-components';
import { Button } from 'devextreme-react/button';
import { setWarning } from '../../../pos-redux/errorHandler/errorHandler.actions';
import { isMobile } from 'react-device-detect';
import LazyImage from 'react-lazy-blur-image';
import { preLoadImagePath } from '../../../helpers/images';
import { localStorageService } from '../../../services/api/utilities/localStorageService';
import { ButtonName, posMessage, ScreenName } from '../../../helpers/pos-message';
import { logs } from '../../../pos-redux/troubleshoot/troubleshoot.actions';

function AddItemGroupComponent() {
    const dispatch = useDispatch();
    const mainGroupSelector = useSelector(state => state.RegisterProduct);

    const { productGroupColumn, productGroupConfiguration } = useSelector(state => state.uiConfiguration);
    const [uiConfiguration] = useSelector(state => [state.uiConfiguration]);

    var gridRowContainerCss = "";
    for (let index = 0; index < productGroupColumn; index++) {
        gridRowContainerCss = gridRowContainerCss + " 300px"
    }
    const CustomGrid = styled.div`
        display: grid;
        @media screen and (min-width: 767px) {
            grid-template-columns: ${gridRowContainerCss};
            }
        @media screen and (max-width: 767px) {
            grid-template-columns: 50% 50%;
            }
        grid-template-rows: auto;
        `;

    const clickCancel = () => {
        logs(ScreenName.ItemGroupComponent, ButtonName.Back, "registerProductRedirect()", uiConfiguration);
        registerProductRedirect();
    }

    const registerProductRedirect = () => {
        if (!mainGroupSelector.isActive)
            return;
        if (mainGroupSelector.name === RegisterProductConstants.ADD_ITEM_GROUP) {
            dispatch(setRegisterProduct(RegisterProductConstants.ADD_PRODUCT, true));
        } else if (mainGroupSelector.name === RegisterProductConstants.ADD_SUBITEM_GROUP) {
            dispatch(setRegisterProduct(RegisterProductConstants.ADD_ITEM_GROUP, true));
        } else if (mainGroupSelector.name === RegisterProductConstants.ALL_PRODUCTS_COMPONENT) {
            var payload = {
                selectedMainGroup: mainGroupSelector.selectedMainGroup
            }
            dispatch(setProductData(RegisterProductConstants.SET_REGISTER_PRODUCT_MAINGROUP_SELECTED, payload));
            dispatch(setRegisterProduct(RegisterProductConstants.ADD_SUBITEM_GROUP, true));
        }
    }

    useEffect(() => {
        resetData(true);
        getProductMainGroups();
    }, [])

    const getProductMainGroups = async () => {
        try {
            if (!localStorageService.getMainGroups()) {
                var data = await productGroupService.getProductMainGroup();
                localStorageService.setMainGroups(data);
            }
            var data = localStorageService.getMainGroups();

            var payload = {
                productMainGroup: data,
                currentPage: 1
            }
            payload.paginationData = getPaginatedData(payload.productMainGroup, payload.currentPage);
            dispatch(setProductData(RegisterProductConstants.SET_REGISTER_PRODUCT_MAINGROUPS, payload));
        } catch {
            resetData(false);
        }

    }

    const clickItem = (item) => {
        logs(ScreenName.ItemGroupComponent, item.mainGroup, "Item is selected ID: " + item.id, uiConfiguration);
        var payload = {
            selectedMainGroup: item
        }
        dispatch(setProductData(RegisterProductConstants.SET_REGISTER_PRODUCT_MAINGROUP_SELECTED, payload));
        resetData(true);
        dispatch(setRegisterProduct(RegisterProductConstants.ADD_SUBITEM_GROUP, true));
    }

    const resetData = (loading) => {
        var resetPayLoad = {
            productMainGroup: [],
            currentPage: 1,
            paginationData: [],
            loading: loading
        }
        dispatch(setProductData(RegisterProductConstants.SET_REGISTER_PRODUCT_MAINGROUPS, resetPayLoad));
    }

    const renderGroupName = (productGroup, index) => {
        var classHTML = '';
        var heightCssClass = isMobile ? "" : "";

        if (Number.isInteger((index + 1) / 3)) {
            classHTML = "btn btn-primary cursor-hand place-Center text-break" + heightCssClass;
        } else {
            classHTML = "btn btn-primary me-2 cursor-hand place-Center text-break" + heightCssClass;
        }
        return (
            <div className="grid-element me-2 mb-2 subgroup">
                <Button
                    type="normal"
                    stylingMode="outlined"
                    className={classHTML} onClick={() => clickItem(productGroup)}>
                    {productGroup.imageURL && (
                        <LazyImage
                            transitionTime={50}
                            placeholder={`${preLoadImagePath.default_imge}`}
                            uri={productGroup.imageURL}
                            render={(src, style) => <img src={src} style={style} className="productImage" alt="img" hidden={productGroupConfiguration === ProductGroupConfiguration.ShowText} />}
                        />

                    )}
                    <span>{productGroupConfiguration === ProductGroupConfiguration.ShowIcon ? "" : productGroup.mainGroup}</span>
                </Button>
            </div>
        );
    }

    const onNext = () => {
        logs(ScreenName.ItemGroupComponent, ButtonName.NextPage, "setProductData()", uiConfiguration);
        if (Math.ceil(mainGroupSelector.productMainGroup.length / mainGroupSelector.dataLimit) > mainGroupSelector.currentPage) {
            var payload = {
                ...mainGroupSelector,
                currentPage: mainGroupSelector.currentPage + 1
            }
            payload.paginationData = getPaginatedData(payload.productMainGroup, payload.currentPage);
            dispatch(setProductData(RegisterProductConstants.SET_REGISTER_PRODUCT_MAINGROUPS, payload));
        } else {
            setWarning(RegisterProductConstants.NO_ITEM_FOUND);
        }
    }

    const onPrevious = () => {
        logs(ScreenName.ItemGroupComponent, ButtonName.LastPage, "setProductData()", uiConfiguration);
        if (mainGroupSelector.currentPage > 1) {
            var payload = {
                ...mainGroupSelector,
                currentPage: mainGroupSelector.currentPage === 0 ? 1 : mainGroupSelector.currentPage - 1
            }
            payload.paginationData = getPaginatedData(payload.productMainGroup, payload.currentPage);
            dispatch(setProductData(RegisterProductConstants.SET_REGISTER_PRODUCT_MAINGROUPS, payload));
        } else {
            setWarning(RegisterProductConstants.NO_ITEM_FOUND);
        }
    }

    return (
        <div className="tab-pane fade" id="item-group-compoonent" role="tabpanel" aria-labelledby="nav-profile-tab">
            <div className={isMobile ? "d-flex align-items-center justify-content-center flex-column mv-grid-btn" : "d-flex align-items-center justify-content-center flex-column"}>
                <div className="text-center break-word mt-4">
                    <h2>Velg varegruppe</h2>
                    <LoadPanel
                        message={posMessage.loading}
                        shadingColor="rgba(0,0,0,0.4)"
                        visible={mainGroupSelector.loading}
                        showIndicator={true}
                    />

                    <CustomGrid className={isMobile ? "jusify-content-centter buttonGropu my-4" : "jusify-content-centter buttonGropu my-4 gridHeight gridButtonItemWrapper"}>
                        {
                            mainGroupSelector.paginationData.map((value, index) => {
                                return renderGroupName(value, index);
                            })
                        }
                    </CustomGrid>

                    <div className="d-flex justify-content-center  my-4 pt-4 actionButtonGroup" id="product-btn">
                        <Button
                            type="success"
                            stylingMode="contained"
                            disabled={mainGroupSelector.currentPage > 1 ? false : true}
                            className="btn btn-primary cursor-hand" onClick={onPrevious}>
                            <img className="vippsIconPhone" src={`${preLoadImagePath.whiteLeftArrow}`} alt="img" /> <span>{ButtonName.LastPage}</span>
                        </Button>
                        <Button
                            stylingMode="contained"
                            className="btn btn-primary cursor-hand" onClick={clickCancel}>
                            <img className={isMobile ? "customIcon mb-1" : "customIcon"} src={`${preLoadImagePath.undo_white}`} alt="img" /> <span>{ButtonName.Back}</span>
                        </Button>
                        <Button
                            type="success"
                            stylingMode="contained"
                            disabled={Math.ceil(mainGroupSelector.productMainGroup.length / mainGroupSelector.dataLimit) > mainGroupSelector.currentPage ? false : true}
                            className="btn btn-primary cursor-hand" onClick={onNext} >
                            {!isMobile
                                ?
                                <> <span>Neste side</span>  <img className="vippsIconPhone" src={`${preLoadImagePath.whiteRightArrow}`} alt="img" />  </>
                                :
                                <>  <img className="vippsIconPhone" src={`${preLoadImagePath.whiteRightArrow}`} alt="img" /> <span>{ButtonName.NextPage}</span>  </>
                            }
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export { AddItemGroupComponent }