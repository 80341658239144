import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setHeader } from '../../pos-redux/header/header.actions';
import { headerConstants } from '../../pos-redux/header/header.constants';
import { Button } from 'devextreme-react/button';
import { setStart, initStart } from '../../pos-redux/start/start.actions';
import { startConstants } from '../../pos-redux/start/start.constants';
import { setPayment } from '../../pos-redux/payment/payment.actions';
import { PaymentConstants } from '../../pos-redux/payment/payment.constants';
import { initEmployee } from '../../pos-redux/employee/employee.actions';
import { initProduct } from '../../pos-redux/registerProduct/product.actions';
import { initVipps } from '../../pos-redux/payment/vipps/vippsPayment.action';
import { initSelectedproduct } from '../../pos-redux/registerProduct/addProductWithQty.actions';
import { initTerminal } from '../../pos-redux/payment/terminal/terminal.actions';
import { initBags, resetBagQty } from '../../pos-redux/selectBag/selectBag.actions';
import LazyImage from 'react-lazy-blur-image';
import { preLoadImagePath } from '../../helpers/images';
import { avaibleLight } from '../../pos-redux/uiConfiguration/uiConfiguration.actions';
import { useEffect } from 'react';
import { ButtonName, lightEnum, ScreenName } from '../../helpers/pos-message';
import { logs } from '../../pos-redux/troubleshoot/troubleshoot.actions';
import { receiptService } from '../../services/receipt.service';
import { initGiftCard } from '../../pos-redux/payment/giftCard/giftCard.actions';

function GreetComponent() {
    const [uiConfiguration, selectBagTypes, startMember, startTroubleShoot, startPayment] = useSelector(state => [state.uiConfiguration, state.selectBag, state.start, state.troubleShootReducer, state.payment]);
    const history = useHistory();
    const dispatch = useDispatch();
    const eventOccurence = ['manual', 'automatic'];
    var timerId;

    useEffect(() => {
        if (startTroubleShoot?.data?.data) {
            if (startTroubleShoot?.data?.data.LightTowerVisible) {
                dispatch(avaibleLight(lightEnum.TurnOn));
            }
        }
        receiptPrint();
    }, []);

    const receiptPrint = async () => {
        if (startPayment.name === PaymentConstants.POS_CONFIRM_ORDER) {
            var responseOrder = startPayment.data?.posOrder;
            if (responseOrder) {
                var responsePrint = await receiptService.printOrder(responseOrder);
                timerId = setTimeout(() => redirectToHome(eventOccurence[1]), uiConfiguration.greetScreenTimerInSecond * 1000)  // milliseconds exceute
            }
        }
    }

    const redirectToHome = (occurence) => {
        logs(ScreenName.Greet, ButtonName.Ok, "redirectToHome", uiConfiguration);
        // this prevent, when it occurs from timeout automatic type and not at greet page. remove clearTime from timer.
        if (occurence === eventOccurence[1] && window.location.hash.split('#')[1] != '/greet') {
            clearTimeout(timerId);
            return;
        }

        dispatch(setHeader(headerConstants.START, true));
        dispatch(setStart(startConstants.MEMBER_COMFIRMATION, true));
        dispatch(setPayment(PaymentConstants.PAYMENT_METHOD, true));
        dispatch(initBags());
        dispatch(initProduct());
        dispatch(initSelectedproduct());
        dispatch(initEmployee());
        dispatch(initTerminal());
        dispatch(initVipps());
        dispatch(initStart());
        dispatch(initGiftCard());

        // if (selectBagTypes.data.length > 0) {
        //     dispatch(resetBagQty(selectBagTypes.data));
        // }

        history.push("/welcome");
    }

    const greetHeader = uiConfiguration.greetHeader;
    const greetSubHeader = uiConfiguration.greetSubHeader;

    return (
        <div id="greetComponent" className="d-flex align-items-center justify-content-center flex-column h-100-vh">
            <div className="text-center">
                <h2 className="new-line">{greetHeader}</h2>
                <LazyImage
                    placeholder={`${preLoadImagePath.default_image_150}`}
                    uri={`${preLoadImagePath.invoice_black}`}
                    className="width-150px"
                    render={(src, style) => <img className="img-150 my-5" src={src} alt="img" />}
                />
                {/* <img className="width-150px my-5" src={invoice} alt="img" /> */}
                <h2 className="new-line mb-4">{greetSubHeader}</h2>
                <Button
                    type={'success'}
                    stylingMode="contained"
                    width={'20%'}
                    className="btn btn-primary mx-auto px-5 cursor-hand justify-content-center btn-icon" onClick={() => redirectToHome(eventOccurence[0])}>
                    {ButtonName.Ok}
                </Button>
            </div>
        </div>
    );
}

export { GreetComponent }