import { ApiUrlConstants } from '../services/api/apiUrl.constants';

const preloadImgDir = ApiUrlConstants.PreLoadImage.ImageDirEndPoint;

export const preLoadImagePath = {
    bag1: preloadImgDir + 'bag1.jpg',
    bag2: preloadImgDir + 'bag2.jpg',
    bag2: preloadImgDir + 'bag3.jpg',
    vipps_logo_white: preloadImgDir + 'vipps-logo-white.svg',
    tap_icon: preloadImgDir + 'tap-icon.svg',
    default_image_245: preloadImgDir + 'default-image-245.png',
    default_image_55: preloadImgDir + 'default-image-55.png',
    logo: preloadImgDir + 'logo.svg',
    card: preloadImgDir + 'card.svg',
    invoice_black: preloadImgDir + 'invoice_black.svg',
    arrow_group_loader: preloadImgDir + 'arrow_group_loader.svg',
    vipps_icon_phone: preloadImgDir + 'vipps_icon_phone.svg',
    black_cart: preloadImgDir + 'black_cart.svg',
    cart_white: preloadImgDir + 'cart_white.svg',
    check: preloadImgDir + 'check.svg',
    basket: preloadImgDir + 'basket.svg',
    cancel: preloadImgDir + 'cancel.svg',
    no_barcode: preloadImgDir + 'no-barcode.svg',
    speaker: preloadImgDir + 'speaker.svg',
    user: preloadImgDir + 'user.svg',
    user2: preloadImgDir + 'user2.svg',
    not_user: preloadImgDir + 'not-user.svg',
    white_cross_top: preloadImgDir + 'white_cross_top.svg',
    user_black: preloadImgDir + 'user_black.png',
    avdeling: preloadImgDir + 'avdeling.png',
    telephone: preloadImgDir + 'telephone.png',
    bukket: preloadImgDir + 'bukket.svg',
    default_image_400: preloadImgDir + 'default-image-400.png',
    default_image_150: preloadImgDir + 'default-image-150.png',
    default_imge_100_26: preloadImgDir + 'default-image-100x26.png',
    default_imge_150_109: preloadImgDir + 'default-image-150x109.png',
    default_imge_200: preloadImgDir + 'default-image-200.png',
    default_imge: preloadImgDir + 'default-image.png',
    delete: preloadImgDir + 'delete.svg',
    deleteblack: preloadImgDir + 'deleteblack.svg',
    whiteLeftArrow: preloadImgDir + 'white_left_arrow.svg',
    whiteRightArrow: preloadImgDir + 'white_right_arrow.svg',
    close: preloadImgDir + 'close.svg',
    user_black_customer: preloadImgDir + 'user_black.svg',
    cart_black: preloadImgDir + 'cart_black.svg',
    undo_white: preloadImgDir + 'undo.png',
    loading_squre: preloadImgDir + 'Loading.gif',
    warning_black: preloadImgDir + 'warning.png',
    lock_black: preloadImgDir + 'lock.png',
    clock_black: preloadImgDir + 'timer.png',
    Rfid: preloadImgDir + 'rfid.jpeg',
    giftCard : preloadImgDir + 'giftcard.svg',
    no_giftCard : preloadImgDir + 'no-giftcard.svg',
    scanGiftCard: preloadImgDir + 'ScanGiftCard.png',
    currency_symbol: preloadImgDir + 'currency_symbol.png', 
    partialPaymentGiftcard: preloadImgDir + 'PartialPaymentGiftcard-fill-svgrepo-com.svg',
    partialPaymentVipps: preloadImgDir +'PartialPaymentVipps-rgb-orange-pos.svg',
    partialPaymentCredit: preloadImgDir +'PartialPaymentCredit-card-svgrepo-com.svg',
    preloadImgDir: preloadImgDir,
    preloadImgDir: preloadImgDir    
};