import './bankTerminalWaiting.component.scss';
import { useHistory } from 'react-router-dom';
import LazyImage from 'react-lazy-blur-image';
import { useEffect } from 'react';
import { TerminalConstants } from '../../../pos-redux/payment/terminal/terminal.constants';
import { useSelector } from 'react-redux';
import { checkPaymentStatus, CountDownTimer, cancelpayment, redirectToScreen, initTerminal } from '../../../pos-redux/payment/terminal/terminal.actions';
import { useDispatch } from 'react-redux';
import { PaymentConstants } from '../../../pos-redux/payment/payment.constants';
import { setPayment, setPOSOrder } from '../../../pos-redux/payment/payment.actions';
import { orderPosService } from '../../../services/orderPos.service';
import { receiptService } from '../../../services/receipt.service';
import { preLoadImagePath } from '../../../helpers/images';
import { Button } from 'devextreme-react/button';
import { MessageConstants } from '../../../pos-redux/message/message.constants';
import { ButtonName, ScreenName } from '../../../helpers/pos-message';
import { logs } from '../../../pos-redux/troubleshoot/troubleshoot.actions';
import { setWarning, toastCenterPosition } from '../../../pos-redux/errorHandler/errorHandler.actions';
import { withDrawalGiftCardStatusSuccess } from '../../../pos-redux/payment/giftCard/giftCard.actions';


function BankTerminalWaiting() {
    const [terminalPayment, message, stateOrder, stateMembers, employeeDetail, uiConfiguration, stateGiftCard] = useSelector(state => [state.terminalPayment, state.message, state.product, state.start, state.employeeDetail, state.uiConfiguration, state.giftCardPayment]);
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        if (terminalPayment.actionType === TerminalConstants.TERMINAL_INITIATE_PAYMENT_SUCCESS) {
            dispatch(checkPaymentStatus(uiConfiguration));
        }

        checkBankTerminalStatus();
    });

    const checkBankTerminalStatus = async () => {
        if (terminalPayment.actionType === TerminalConstants.TERMINAL_PAYMENT_STATUS_SUCCESS
            && terminalPayment.data.Status === TerminalConstants.TerminalPaymentStatus.TransaksjonOK) {
            // if (stateMembers.data) {
            //     stateOrder.OrderPos.order.customerNo = stateMembers.data.customerNo;
            // } 
            await createOrder(stateOrder.OrderPos, terminalPayment);
        }
        else if (terminalPayment.actionType === TerminalConstants.TERMINAL_PAYMENT_STATUS_SUCCESS
            && terminalPayment.data.Status !== null && terminalPayment.data.Status !== TerminalConstants.TerminalPaymentStatus.TransaksjonOK) {
            dispatch(redirectToScreen(uiConfiguration));
        }
    }

    const createOrder = async (order, terminalPayment) => {
        var ledgerPayments = stateGiftCard?.paymentTransactionModel?.registeredLedgerPayments || [];
        let payAmount = 0;

        if (stateGiftCard?.paymentTransactionModel && Object.keys(stateGiftCard?.paymentTransactionModel).length > 0) {
            payAmount = stateGiftCard?.paymentTransactionModel.remainingAmount;
        } else {
            payAmount = stateOrder.OrderPos.order.orderTotal;
        }
        terminalPayment.data['amount'] = payAmount;

        var responseOrder = await orderPosService.orderPOS(order, terminalPayment.data, PaymentConstants.BANK_TERMINAL, employeeDetail, ledgerPayments);
        if (responseOrder) {
            if (responseOrder.paymentTransactionModel != null) {
                let errorMessage = responseOrder.paymentTransactionModel.errorMessage;
                if (responseOrder.paymentTransactionModel.remainingAmount > 0 || errorMessage) {
                    setWarning(errorMessage, toastCenterPosition);
                    dispatch(initTerminal());
                    dispatch(withDrawalGiftCardStatusSuccess(responseOrder.paymentTransactionModel));
                    dispatch(setPayment(PaymentConstants.PAYMENT_METHOD, true));
                    return;
                }
            }

            var type = { posOrder: responseOrder };
            dispatch(setPOSOrder(PaymentConstants.POS_CONFIRM_ORDER, true, type));
            history.push("/greet");
        }
    }

    // const showTimer = () => {
    //     if (terminalPayment.actionType === TerminalConstants.TERMINAL_PAYMENT_STATUS_SUCCESS) {
    //         return CountDownTimer(uiConfiguration.paymentGatewayTimerInSecond);
    //     }
    // }

    const cancelPayment = () => {
        logs(ScreenName.BankTerminalWaitingComponent, ButtonName.Cancel, "cancelpayment", uiConfiguration);
        dispatch(cancelpayment(uiConfiguration));
    }

    return (
        <div>
            {/* <div class="payment-timer">{showTimer()}</div> */}
            <div className="d-flex align-items-center justify-content-center flex-column h-90-vh">
                <div className="text-center">
                    <h2>{message.data.message.split('\n').map((txt) => <>{txt}<br /></>)}</h2>
                    <div className="scroll-prompt my-5 pt-4" scroll-prompt="" ng-show="showPrompt" style={{ opacity: '1' }}>
                        <div className="scroll-prompt-arrow-container">
                            <div className="scroll-prompt-arrow cursor-hand">
                                <LazyImage
                                    placeholder={`${preLoadImagePath.default_imge_150_109}`}
                                    uri={`${preLoadImagePath.arrow_group_loader}`}
                                    render={(src, style) => <img className="pb-2 logo-arrow img-150x109" src={src} alt="img" />}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="d-flex py-4 flex-column flex-lg-row place-Center" >
                        {message.data.message === MessageConstants.Terminal.InserCardWarningMsg ?
                            <Button
                                disabled={terminalPayment.actionType === TerminalConstants.TERMINAL_PAYMENT_STATUS_SUCCESS ? terminalPayment.data.CardStatus : false}
                                className="dx-datagrid-search-text btn btn-cancel-color btn cursor-hand btn-icon me-lg-5 mb-4 mb-lg-0" onClick={cancelPayment} >{ButtonName.Cancel}</Button>
                            :
                            <></>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export { BankTerminalWaiting }