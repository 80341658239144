import packageJSON from '../../../package.json';

export const currencyOptions = new Intl.NumberFormat('nb-NB', {
    style: 'currency',
    currency: 'NOK',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
}).resolvedOptions();

export const delay = async (delayInms) => {
    return new Promise(resolve => {
        setTimeout(() => {
            resolve(2);
        }, delayInms);
    });
}

export const regxNumber = /^[0-9\b]+$/;

export const AppVersion = packageJSON.version;


export const priceFormat = (price) => { 
    if (price) {
        return price.toLocaleString('nb-NB', {
            ...currencyOptions,
            style: 'decimal',
        })
    } else {
        price = 0;
        return price.toLocaleString('nb-NB', {
            ...currencyOptions,
            style: 'decimal',
        })
    }
}

export const dateFormat = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
}

// This is for weight scaling product to idenity from productInfo table.
const productWeightScalingSpecial = [1024, 4096];


export const isSpecialProduct = (product) => {
    let isSpecialProduct = productWeightScalingSpecial.find(x => x === product.spesial);
    return isSpecialProduct;
}

export const ellipsisText = (input) => input.length > 4 ? `${input.substring(0, 4)}...` : input;
