import { withNavigationWatcher } from '../common/contexts/navigation';
import { WelcomePage } from './pages/WelcomePage/welcome.page';
import { HomePage } from './pages/HomePage/home.page';
import { GreetPage } from './pages/GreetPage/greet.page';
import { TroubleShootPage } from './pages/TroubleshootPage/troubleshoot.page';

const routes = [
  {
    path: '/welcome',
    component: WelcomePage
  },
  {
    path: '/home',
    component: HomePage
  },
  {
    path: '/greet',
    component: GreetPage
  },
  {
    path: '/troubleshoot',
    component: TroubleShootPage
  }
];

export default routes.map(route => {
  return {
    ...route,
    component: withNavigationWatcher(route.component)
  };
});
