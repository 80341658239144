import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { PaymentConstants } from '../../pos-redux/payment/payment.constants';
import { setPayment, setPOSOrder } from '../../pos-redux/payment/payment.actions';
import './paymentMethod.component.scss';
import { Button } from 'devextreme-react/button';
import LazyImage from 'react-lazy-blur-image';
import { initiateTerminalPayment, initTerminal, waitingTimer } from '../../pos-redux/payment/terminal/terminal.actions';
import { preLoadImagePath } from '../../helpers/images';
import { ButtonName, posMessage, ScreenName } from '../../helpers/pos-message';
import { LoadPanel } from 'devextreme-react';
import { showUILoader } from '../../pos-redux/commonUI/commonUI.actions';
import { logs } from '../../pos-redux/troubleshoot/troubleshoot.actions';
import { CustomerAccountType } from '../../pos-redux/customer/customer.constant';
import { setWarning, toastCenterPosition, setError } from '../../pos-redux/errorHandler/errorHandler.actions';
import { priceFormat } from '../../helpers/helper';
import { VippsPaymentConstants } from '../../pos-redux/payment/vipps/vippsPayment.constant';
import { paymentMethodEnum } from '../../helpers/pos-message';

function AddPaymentMethod() {
    const [stateOrder, commonUI, customerDetail, uiConfiguration, stateGiftCard] = useSelector(state => [state.product, state.commonUIReducer, state.customerDetail, state.uiConfiguration, state.giftCardPayment]);
    const dispatch = useDispatch();
    const [paymentText, setPaymentText] = useState('Velg betalingsmåte');
 
    const paymentNameMap = {
        [paymentMethodEnum.BankTerminal]: 'bankkort',
        [paymentMethodEnum.Vipps]: 'vipps',
        [paymentMethodEnum.GiftCard]: 'gavekort'
    };

    const imagePathMap = {
        [paymentMethodEnum.BankTerminal]: preLoadImagePath.partialPaymentCredit,
        [paymentMethodEnum.Vipps]: preLoadImagePath.partialPaymentVipps,
        [paymentMethodEnum.GiftCard]: preLoadImagePath.partialPaymentGiftcard
    };

    useEffect(() => {
        if (!uiConfiguration.vippsPaymentAvailable && !uiConfiguration.hasMemberScreen) {
            clickPaymentTerminal();
        }

        if (giftCardPaymentLines()) {
            setPaymentText('Velg betalingsmåte for resterende beløp');
        }

    }, []);

    const giftCardPaymentLines = () => {
        var isGiftCardLines = false;
        if (stateGiftCard?.paymentTransactionModel && Object.keys(stateGiftCard?.paymentTransactionModel).length > 0) {
            if (stateGiftCard?.paymentTransactionModel.registeredLedgerPayments.length > 0) {
                isGiftCardLines = true;
            }
        }
        return isGiftCardLines;
    }

    const clickPaymentTerminal = async () => {
        logs(ScreenName.PaymentMethodComponent, ButtonName.Bankkort, "initiateTerminalPayment, initTerminal, setPayment", uiConfiguration);
        dispatch(showUILoader(true));
        await waitingTimer(500);
        dispatch(initTerminal());
        let payAmount = 0;
        if (stateGiftCard?.paymentTransactionModel && Object.keys(stateGiftCard?.paymentTransactionModel).length > 0) {
            payAmount = stateGiftCard?.paymentTransactionModel.remainingAmount;
        } else {
            payAmount = stateOrder.OrderPos.order.orderTotal;
        }

        if (payAmount > 0) {
            var result = await dispatch(initiateTerminalPayment(payAmount, uiConfiguration));
            if (result && result.data) {
                dispatch(setPayment(PaymentConstants.BANK_TERMINAL_WAITING, true));
            }
        }

        dispatch(showUILoader(false));
    }

    const alreadyPaidByVipps = () => {
        //Check if the vipps-payment exists in giftcardLines; don't accept it again.
        var result = false;
        if (stateGiftCard?.paymentTransactionModel && Object.keys(stateGiftCard?.paymentTransactionModel).length > 0) {
            if (stateGiftCard?.paymentTransactionModel?.registeredLedgerPayments.length > 0) {
                var payByVipps = stateGiftCard?.paymentTransactionModel.registeredLedgerPayments.find(x => x.paymentMethod === paymentMethodEnum.Vipps);
                if (payByVipps)
                    result = true;
            }
        }
        return result;
    }

    const clickVipps = () => {
        if (!alreadyPaidByVipps()) {
            logs(ScreenName.PaymentMethodComponent, "Vipps", "setPayment: " + PaymentConstants.REGISTER_VIPS, uiConfiguration);
            dispatch(setPayment(PaymentConstants.REGISTER_VIPS, true));
        }
    }

    const clickInvoice = () => {
        logs(ScreenName.PaymentMethodComponent, ButtonName.Faktura, "setPayment: " + PaymentConstants.PAYMENT_INVOICE, uiConfiguration);
        dispatch(setPayment(PaymentConstants.PAYMENT_INVOICE, true));
    }

    const clickInvoiceAndReceipt = () => {
        logs(ScreenName.PaymentMethodComponent, ButtonName.Ansattkonto, "setPayment: " + PaymentConstants.PAYMENT_INVOICE, uiConfiguration);

        if (customerDetail.data && customerDetail.data.id) {
            dispatch(setPayment(PaymentConstants.PAYMENT_INVOICE, true));
        } else {
            setWarning(posMessage.invalidCustomer, toastCenterPosition);
            logs(ScreenName.PaymentInvoiceComponent, ButtonName.Ansattkonto, "InvalidCustomer", uiConfiguration);
        }
    }

    const clickGiftCard = async () => {
        logs(ScreenName.PaymentMethodComponent, "Gavekort", "clickGiftCard: " + PaymentConstants.GIFT_CARD_SCAN, uiConfiguration);
        dispatch(setPayment(PaymentConstants.GIFT_CARD_SCAN, true));
    }

    const paymentMethodName = (data) => {
        return 'Betalt med ' + (paymentNameMap[data.paymentMethod] || '');
    };

    const getImageByPaymentName = (data) => {
        return imagePathMap[data.paymentMethod] || '';
    }; 

    const paymentDetailSection = (payment) => {
        return (<>
            <div class="item-left text-grey-color item-left-custom margin-bottom12 background-yellow left-items">
                <img className="width25" src={`${getImageByPaymentName(payment)}`} alt="img" />
                {paymentMethodName(payment)}
            </div>
            <div class="item-right px-4 text-grey-color item-right-custom margin-bottom12 background-yellow right-items">kr {priceFormat(payment.amount)}</div></>
        );
    }

    const mutiplePayments = (data) => {
        return (<div class="grid-container my-2 pt-2 justify-content-center">
            <div className="giftCardItems p-5 nogap" style={{ 'padding': 'padding: 10px 0 0 0 !important;' }}>
                <div className="grid-Items item1 grid-Items-flex-start flex-direction-column-only display-ruby">
                    <div class="item-left text-grey-color item-left-custom margin-bottom12 background-green left-items">Ordrebeløp</div>
                    <div class="item-right px-4 text-grey-color item-right-custom margin-bottom12 background-green right-items">kr {priceFormat(stateOrder.OrderPos?.order?.orderTotal)}</div>

                    {data.registeredLedgerPayments.map((ledgerPayment) => <>
                        {paymentDetailSection(ledgerPayment)}
                    </>)
                    }
                    <div class="item-left text-grey-color item-left-custom margin-bottom12 background-red left-items">Resterende beløp</div>
                    <div class="item-right px-4 text-grey-color item-right-custom margin-bottom12 background-red right-items">kr {priceFormat(data.remainingAmount)}</div>
                </div>
            </div>
        </div>);
    }

    const renderAnsattkonto = () => {
        return (
            <Button
                type="success"
                stylingMode="contained"
                className="btn btn-primary w-auto cursor-hand btn-icon text-bold" onClick={clickInvoiceAndReceipt}>
                <LazyImage
                    placeholder={`${preLoadImagePath.default_image_55}`}
                    uri={`${preLoadImagePath.tap_icon}`}
                    render={(src, style) => <img src={src} style={style} alt="img" className="w-30 me-3 img-50" />}
                />
                Ansattkonto
            </Button>
        );
    }

    const paymentDetails = (position) => {
        if (stateGiftCard?.paymentTransactionModel && Object.keys(stateGiftCard?.paymentTransactionModel).length > 0) {
            if (position === posMessage.upper) {
                if (stateGiftCard?.paymentTransactionModel?.registeredLedgerPayments.length <= 3) {
                    return mutiplePayments(stateGiftCard?.paymentTransactionModel);
                }
            }
            if (position === posMessage.bottom) {
                if (stateGiftCard?.paymentTransactionModel?.registeredLedgerPayments.length > 3) {
                    return mutiplePayments(stateGiftCard?.paymentTransactionModel);
                }
            }
        }
        return <></>
    }

    const fakturaButtonVisibility = () => {
        return uiConfiguration.hasMemberScreen && uiConfiguration.allowCustomerCredit != CustomerAccountType.Activated;
    }

    return (
        <div id="addPaymentMethod" className="d-flex align-items-center justify-content-center flex-column">
            <div className="inner-section-height d-flex align-items-center justify-content-center">
                <div className="text-center">
                    <h2>{paymentText}</h2>
                    {
                        giftCardPaymentLines() ? paymentDetails(posMessage.upper) : <></>
                    }

                    <LoadPanel
                        message={posMessage.loading}
                        shadingColor="rgba(0,0,0,0.4)"
                        visible={commonUI.loading}
                        showIndicator={true}
                    />
                    <div className="d-flex my-5 pt-4 buttonSection justify-content-center">
                        <Button
                            type="success"
                            stylingMode="contained"
                            className="btn btn-primary cursor-hand btn-icon text-bold" onClick={clickPaymentTerminal}>
                            <LazyImage
                                placeholder={`${preLoadImagePath.default_image_55}`}
                                uri={`${preLoadImagePath.card}`}
                                render={(src, style) => <img src={src} style={style} alt="img" className="w-30 me-3 img-50" />}
                            />
                            {ButtonName.Bankkort}
                        </Button>
                        {
                            uiConfiguration.vippsPaymentAvailable ?
                                <Button
                                    disabled={alreadyPaidByVipps()}
                                    type="success"
                                    style={{ backgroundColor: "#ff5b24" }}
                                    stylingMode="contained"
                                    className="btn text-center logoButton cursor-hand btn-icon text-bold" onClick={clickVipps}>
                                    <LazyImage
                                        placeholder={`${preLoadImagePath.default_imge_100_26}`}
                                        uri={`${preLoadImagePath.vipps_logo_white}`}
                                        render={(src, style) => <img src={src} style={style} alt="img" className="w-30 me-1 img-100" />}
                                    />
                                </Button>
                                :
                                <div className='w-30'></div>
                        }
                        {
                            fakturaButtonVisibility
                                ?
                                <Button
                                    type="success"
                                    stylingMode="contained"
                                    className="btn btn-primary cursor-hand btn-icon text-bold" onClick={clickInvoice}>
                                    <LazyImage
                                        placeholder={`${preLoadImagePath.default_image_55}`}
                                        uri={`${preLoadImagePath.tap_icon}`}
                                        render={(src, style) => <img src={src} style={style} alt="img" className="w-30 me-3 img-50" />}
                                    />
                                    {ButtonName.Faktura}
                                </Button>
                                : (uiConfiguration.allowCustomerCredit === CustomerAccountType.Activated && customerDetail.data && customerDetail.data.id) ? renderAnsattkonto() :
                                    <></>
                        }
                        {
                            uiConfiguration.isGiftCardButtonVisible ?
                                <Button
                                    type="success"
                                    stylingMode="contained"
                                    className={fakturaButtonVisibility ? "btn btn-primary cursor-hand btn-icon text-bold logoButton" : "btn btn-primary cursor-hand btn-icon text-bold"} onClick={clickGiftCard}>
                                    <LazyImage
                                        placeholder={`${preLoadImagePath.giftCard}`}
                                        uri={`${preLoadImagePath.giftCard}`}
                                        render={(src, style) => <img src={src} style={style} alt="img" className="w-30 me-3 img-50" />}
                                    />
                                    {ButtonName.Gavekort}
                                </Button>
                                :
                                <div className='w-30'></div>
                        }
                    </div>
                    {
                        giftCardPaymentLines() ? paymentDetails(posMessage.bottom) : <></>
                    }
                </div>
            </div>
        </div>
    );
}

export { AddPaymentMethod }