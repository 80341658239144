import { useDispatch, useSelector } from 'react-redux';
import './giftCardPayment.component.scss';
import { NumberPad } from '../../base/numberPad.component';
import { setNumber } from '../../../pos-redux/base/numberPad.action';
import { setPayment, setPOSOrder } from '../../../pos-redux/payment/payment.actions';
import { PaymentConstants } from '../../../pos-redux/payment/payment.constants';
import { useEffect } from 'react';
import { setWarning, toastCenterPosition, setError } from '../../../pos-redux/errorHandler/errorHandler.actions';
import { Button } from 'devextreme-react/button';
import { preLoadImagePath } from '../../../helpers/images';
import { ButtonName, posMessage, ScreenName } from '../../../helpers/pos-message';
import { logs } from '../../../pos-redux/troubleshoot/troubleshoot.actions';
import { priceFormat } from '../../../helpers/helper';
import { withDrawFromGiftCard, withDrawalGiftCardStatusSuccess } from '../../../pos-redux/payment/giftCard/giftCard.actions';
import { orderPosService } from '../../../services/orderPos.service';
import { useHistory } from 'react-router-dom';
import { LoadPanel } from 'devextreme-react/load-panel';
import { showUILoader } from '../../../pos-redux/commonUI/commonUI.actions';
import { paymentMethodEnum } from '../../../helpers/pos-message';

function GiftCardPaymentComponent() {
    const [numberpad, stateOrder, stateGiftCard, uiConfiguration, employeeDetail, commonUI] = useSelector(state => [state.numberPad, state.product, state.giftCardPayment, state.uiConfiguration, state.employeeDetail, state.commonUIReducer]);
    const dispatch = useDispatch();
    const history = useHistory();
    const maximumFractionDigits = 2;
    const regxAmount = /^\d+\,?\d*$/;

    useEffect(() => {
        let payableAmount = getAmount();
        dispatch(setNumber(priceFormat(payableAmount)));
    }, []);


    const getAmount = () => {
        let orderAmount = stateOrder.OrderPos?.order?.orderTotal;
        let giftCardAmount = stateGiftCard?.data?.balance;
        let giftRemainingAmount = stateGiftCard?.paymentTransactionModel?.remainingAmount;
        let payableAmount = 0;
        if (giftRemainingAmount > 0) {
            payableAmount = giftRemainingAmount > giftCardAmount ? giftCardAmount : giftRemainingAmount;
        } else {
            payableAmount = orderAmount > giftCardAmount ? giftCardAmount : orderAmount;
        }

        return payableAmount;
    }

    const closeComponent = () => {
        logs(ScreenName.GiftCardPaymentComponent, ButtonName.Cancel, "closeComponent", uiConfiguration);
        dispatch(setPayment(PaymentConstants.PAYMENT_METHOD, true));
    }

    const clickOK = async () => {

        logs(ScreenName.GiftCardPaymentComponent, ButtonName.Ok, "initiatePayment giftcard amount and verify  ", uiConfiguration);

        let payAmount = numberpad.number;
        if (!payAmount) {
            setWarning('Vennligst fyll inn beløp', toastCenterPosition, ScreenName.GiftCardPaymentComponent);
            return;
        }

        if (payAmount.indexOf(',') > -1) {
            payAmount = payAmount.replace(',', '.');
        }

        //dispatch(setPayment(PaymentConstants.POPUP_FAILURE, true));
        // check that amount is cover in gift=card , call the same api as well. of order.
        if (parseFloat(payAmount) > stateGiftCard?.data?.balance) { //100 > 6
            setWarning('Beløpet du har skrevet inn er større enn saldoen på gavekortet. Skriv et mindre beløp.', toastCenterPosition, ScreenName.GiftCardPaymentComponent);
            return;
        }

        if (parseFloat(payAmount) > getPayableAmount()) { //60 > 60
            setWarning('Beløpet du har valgt er større enn ordrebeløpet.', toastCenterPosition, ScreenName.GiftCardPaymentComponent);
            return;
        }

        dispatch(showUILoader(true));

        var giftCardVM = {
            paymentRequest: {
                giftCardNumber: stateGiftCard?.data?.cardNo,
                amount: payAmount,
                paymentMethod: paymentMethodEnum.GiftCard
            },
            registeredLedgerPayments: stateGiftCard?.paymentTransactionModel?.registeredLedgerPayments || [],
            orderSum: stateOrder.OrderPos?.order?.orderTotal
        }

        var response = await dispatch(withDrawFromGiftCard(giftCardVM));

        if (response) {
            if (response.errorMessage) {
                setWarning(response.errorMessage, toastCenterPosition, ScreenName.GiftCardPaymentComponent);
                dispatch(showUILoader(false));
                return;
            }

            let sumLines = response.registeredLedgerPayments.reduce((acc, curr) => acc + curr.amount, 0);

            if (parseFloat(sumLines) === stateOrder.OrderPos?.order?.orderTotal && response.remainingAmount === 0) { //60 > 60
                await createOrder(stateOrder.OrderPos, response.registeredLedgerPayments);
                dispatch(showUILoader(false));
                return;
            }

            dispatch(showUILoader(false));
            dispatch(setPayment(PaymentConstants.PAYMENT_METHOD, true));
            return;
        }
    }

    const createOrder = async (order, paymentGiftCard) => {
        var responseOrder = await orderPosService.orderPOS(order, null, PaymentConstants.GIFT_CARD, employeeDetail, paymentGiftCard);
        if (responseOrder) {

            if (responseOrder.paymentTransactionModel != null) {
                let errorMessage = responseOrder.paymentTransactionModel.errorMessage;
                if (responseOrder.paymentTransactionModel.remainingAmount > 0 || errorMessage) {
                    setWarning(errorMessage, toastCenterPosition);
                    dispatch(withDrawalGiftCardStatusSuccess(responseOrder.paymentTransactionModel));
                    dispatch(setPayment(PaymentConstants.PAYMENT_METHOD, true));
                    return;
                }
            }
            var type = { posOrder: responseOrder };
            dispatch(setPOSOrder(PaymentConstants.POS_CONFIRM_ORDER, true, type));
            history.push("/greet");
        }
    }

    const numberChange = (val) => {
        if (val === '' || regxAmount.test(val)) {
            logs(ScreenName.GiftCardPaymentComponent, "NumberChange", "GiftCard Checking, initiateWithdrawalCheck", uiConfiguration);
            var digits = val.split(',');
            if (digits) {
                var afterPeriods = digits[1];
                if (afterPeriods) {
                    if (maximumFractionDigits < afterPeriods.length) {
                        return;
                    }
                }
            }

            numberpad.number = val;
            dispatch(setNumber(numberpad.number));
        }
    }

    const getPayableAmount = () => {
        let orderAmount = stateOrder.OrderPos?.order?.orderTotal;
        let giftRemainingAmount = stateGiftCard?.paymentTransactionModel?.remainingAmount || 0;
        return giftRemainingAmount > 0 ? giftRemainingAmount : orderAmount;
    }

    const payHeaderTxt = () => {
        let ledgerPaymentsCount = stateGiftCard?.paymentTransactionModel?.registeredLedgerPayments.length || 0;
        let giftRemainingAmount = 0;
        if (ledgerPaymentsCount > 0) {
            giftRemainingAmount = stateGiftCard?.paymentTransactionModel?.remainingAmount || 0;
        }

        return giftRemainingAmount > 0 ? 'Resterende beløp' : 'Ordrebeløp';
    }

    return (
        <div id="giftCardDeductPayment" className="d-flex align-items-center justify-content-center flex-column h-90-vh">
            <div className="text-center max-50 dx-theme-generic-typography">
                <LoadPanel
                    message={posMessage.loading}
                    shadingColor="rgba(0,0,0,0.4)"
                    visible={commonUI.loading}
                    showIndicator={true}
                />
                <h2 className="dx-font-m dx-font-xs">Velg hvilket beløp du ønsker å belaste gavekortet med</h2>
                <div className="giftCardItems p-5 nogap">
                    <div className="grid-Items item1 grid-Items-flex-start">
                        <div className="text-grey-color item-left-custom margin-bottom12 background-green">{payHeaderTxt()}</div>
                        <div className="text-grey-color item-left-custom margin-bottom12 background-yellow">
                            <img className="width25" src={`${preLoadImagePath.partialPaymentGiftcard}`} alt="img" />Gavekortsaldo</div>
                        <div className="padding-Belast-gavekortet-med item-left-custom"> <strong> Belast gavekortet med</strong></div>
                        <Button
                            stylingMode="contained"
                            className="dx-datagrid-search-text btn btn-cancel-color btn mx-auto px-5 mt-3 cursor-hand justify-content-center btn-icon margin-top-50"
                            onClick={closeComponent}> {ButtonName.Cancel}</Button>
                    </div> 

                    <div className="grid-Items item2 grid-Items-flex-start">
                        <div className="px-4 text-grey-color item-right-custom margin-bottom12 background-green">kr {priceFormat(getPayableAmount())}</div>                        
                        <div className="px-4 text-grey-color item-right-custom margin-bottom12 background-yellow">kr {priceFormat(stateGiftCard?.data?.balance)}</div>
                        <div className="inputGroupGift">
                            <img className="" src={`${preLoadImagePath.currency_symbol}`} alt="img" />
                            <input ref={(input) => { input && input.focus() }} placeholder="Beløp" className="dx-theme-background-color inputAmountGift dx-theme-border-color text-bold"
                                type="text" value={numberpad.number} onChange={e => numberChange(e.target.value)} />
                        </div>
                        <Button
                            type="success"
                            stylingMode="contained"
                            className="btn btn-primary mx-auto cursor-hand justify-content-center btn-icon margin-top-50" onClick={clickOK}> {ButtonName.Ok}</Button>

                    </div>
                    <div className="grid-Items item3 grid-Items-flex-start">
                        <NumberPad screen="gift-card"></NumberPad>
                    </div>
                </div>
            </div>
        </div>
    );
}

export { GiftCardPaymentComponent }