import { delay } from "../../helpers/helper"
import { employeeService } from "../../services/employee.service"
import { setError } from "../errorHandler/errorHandler.actions"
import { setRegisterProduct } from "../registerProduct/registerProduct.actions"
import { RegisterProductConstants } from "../registerProduct/registerProduct.constants"
import { EmployeeConstants } from "./employee.constants"

//#region Actions
export const employeeDetailRequest = () => {
    return {
        type: EmployeeConstants.EMPLOYEE_DETAIL_REQUEST
    }
}

export const employeeDetailSuccess = response => {
    return {
        type: EmployeeConstants.EMPLOYEE_DETAIL_SUCCESS,
        payload: response
    }
}

export const employeeDetailFailure = error => {
    return {
        type: EmployeeConstants.EMPLOYEE_DETAIL_ERROR,
        payload: error
    }
}

export const initEmployee = () => {
    return {
        type: EmployeeConstants.INIT_EMPLOYEE
    }
}
//#endregion

//#region  Action Handlers
export const getEmployeeDetailByNumber = (num) => async dispatch => {
    dispatch(employeeDetailRequest());
    const response = await employeeService.getEmployeeDetailByNumber(num);
    if (response) {
        dispatch(employeeDetailSuccess(response));
        dispatch(setRegisterProduct(RegisterProductConstants.ADD_PRODUCT, true));
    }
    else {
        setError('no employee with this number found');
        dispatch(employeeDetailFailure('no employee with this number found'));
    }
}
//#endregion