import { RegisterProductConstants } from './registerProduct.constants';

const initialState = { name: RegisterProductConstants.ADD_PRODUCT, isActive: true, reload: true, productMainGroup: [], selectedMainGroup: {}, productSubGroups: [], currentPage: 1, dataLimit: 12, paginationData: [], loading: true, selectedSubGroup: {}, products: [], totalCount: 0, productImages: [], editProduct: {}, selectedAssortment: {}, assortments: [], assortmentChild: { paginationData: [], assortmentAndProduct: [], currentPage: 1, assortmentAndProductCache: [] }, infoDetail: {} };

function RegisterProduct(state = initialState, action) {
    switch (action.type) {
        case RegisterProductConstants.SET_REGISTER_PRODUCT:
            const { name, isActive } = action.payload;
            return {
                ...state,
                name: name,
                isActive: isActive
            };

        case RegisterProductConstants.SET_RELOAD:
            const reload = action.payload;
            return {
                ...state,
                reload: reload,
            };

        case RegisterProductConstants.SET_REGISTER_PRODUCT_MAINGROUPS: {
            const { productMainGroup, currentPage, paginationData, loading } = action.payload;
            return {
                ...state,
                productMainGroup: productMainGroup,
                currentPage: currentPage,
                paginationData: paginationData,
                loading: loading,
                reload: true
            };
        }

        case RegisterProductConstants.SET_REGISTER_PRODUCT_MAINGROUP_SELECTED: {
            return {
                ...state,
                selectedMainGroup: action.payload.selectedMainGroup
            };
        }

        case RegisterProductConstants.SET_REGISTER_PRODUCT_SUBGROUPS: {
            return {
                ...state,
                productSubGroups: action.payload.productSubGroups,
                currentPage: action.payload.currentPage,
                paginationData: action.payload.paginationData,
                loading: action.payload.loading,
                reload: true
            };
        }

        case RegisterProductConstants.PRODUCTS_FROM_SUBGROUPS_SELECTED: {
            return {
                ...state,
                selectedSubGroup: action.payload.selectedSubGroup
            }
        }

        case RegisterProductConstants.PRODUCTS_REQUEST: {
            return {
                ...state,
                loading: true
            };
        }

        case RegisterProductConstants.PRODUCTS_FAILURE: {
            return {
                ...state,
                loading: false
            };
        }

        case RegisterProductConstants.PRODUCTS_SUCCESS: {
            return {
                ...state,
                products: action.payload.products,
                currentPage: action.payload.currentPage,
                loading: false,
                totalCount: action.payload.totalCount,
                productImages: action.payload.productImages,
                reload: true
            };
        }

        case RegisterProductConstants.EDIT_PRODUCT: {
            return {
                ...state,
                editProduct: { ...action.payload },
                loading: false
            }
        }

        case RegisterProductConstants.SET_PARENT_ASSORTMENT_SELECTED: {
            return {
                ...state,
                selectedAssortment: action.payload.selectedAssortment
            };
        }

        case RegisterProductConstants.SET_REGISTER_ASSORTMENTS: {
            const { assortments } = action.payload;
            return {
                ...state,
                assortments: assortments
            };
        }

        case RegisterProductConstants.SET_REGISTER_PRODUCT_ASSORTMENTS: {
            const payLoadAssortment = action.payload;
            return {
                ...state,
                assortmentChild: payLoadAssortment,
                loading: payLoadAssortment.loading,
            };
        }

        case RegisterProductConstants.INIT_REGISTER_PRODUCT: {
            return initialState;
        }

        case RegisterProductConstants.SET_REGISTER_PRODUCT_INFO:
            const payInfo = action.payload;
            return {
                ...state,
                name: payInfo.name,
                isActive: payInfo.isActive,
                infoDetail: payInfo.infoDetail
            };

        default:
            return state
    }
}

export { RegisterProduct };