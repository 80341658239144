import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setStart } from '../../pos-redux/start/start.actions';
import { startConstants } from '../../pos-redux/start/start.constants';
import { Button } from 'devextreme-react/button';
import LazyImage from 'react-lazy-blur-image';
import { preLoadImagePath } from '../../helpers/images';
import { ButtonName, ScreenName } from '../../helpers/pos-message';
import { logs } from '../../pos-redux/troubleshoot/troubleshoot.actions';

function MemberConfirmationComponent() {
    const dispatch = useDispatch();

    const uiConfiguration = useSelector(state => state.uiConfiguration);
    const memberConfirmationTxt = uiConfiguration.memberConfirmationHeader;

    const clickCustomerClubMember = () => {
        logs(ScreenName.Memberconfirmation, ButtonName.CustomerClubMember, ScreenName.AddPhoneNumber, uiConfiguration);
        dispatch(setStart(startConstants.ADD_PHONE_NUMBER, true));
    }
    const clickBusinessCustomer = () => {
        logs(ScreenName.Memberconfirmation, ButtonName.BusinessCustomer, ScreenName.AddCustomerNumberComponent, uiConfiguration);
        dispatch(setStart(startConstants.ADD_CUSTOMER_NUMBER, true));
    }
    const clickNotMember = () => {
        logs(ScreenName.Memberconfirmation, ButtonName.NotMember, startConstants.SELF_SERVICE_CONFIRMATION, uiConfiguration);
        dispatch(setStart(startConstants.SELF_SERVICE_CONFIRMATION, true));
    }

    return (
        <div id="memberConfirmationComponent" className="text-center inner-section-height tab-content d-flex align-items-center justify-content-lg-center flex-column">
            <h2 className="mb-2 mb-md-4 mb-xl-4 new-line">{memberConfirmationTxt}</h2>
            <div className="d-flex py-4 flex-column flex-lg-row">
                <Button
                    type="success"
                    stylingMode="contained"
                    className="btn me-lg-5 mb-4 mb-lg-0 cursor-hand btn-icon" onClick={clickCustomerClubMember}>
                    <LazyImage
                        placeholder={`${preLoadImagePath.default_image_55}`}
                        uri={`${preLoadImagePath.user}`}
                        render={(src, style) => <img src={src} alt="img" className="w-30 me-3 img-50" />}
                    />
                    {ButtonName.CustomerClubMember}
                </Button>
                <Button
                    type="success"
                    stylingMode="contained"
                    className="btn cursor-hand btn-icon" onClick={clickBusinessCustomer}>
                    <LazyImage
                        placeholder={`${preLoadImagePath.default_image_55}`}
                        uri={`${preLoadImagePath.user2}`}
                        render={(src, style) => <img src={src} alt="img" className="w-30 me-3 img-50" />}
                    />
                    {ButtonName.BusinessCustomer}
                </Button>
            </div>
            <div className="text-center pt-0 pt-lg-4 py-xl-4">
                <Button
                    stylingMode="contained"
                    className="btn btn-primary mx-auto w-100-mobile cursor-hand btn-icon" onClick={clickNotMember}>
                    <LazyImage
                        placeholder={`${preLoadImagePath.default_image_55}`}
                        uri={`${preLoadImagePath.not_user}`}
                        render={(src, style) => <img src={src} alt="img" className="w-50 me-3 img-50" />}
                    />
                    {ButtonName.NotMember}
                </Button>
            </div>
        </div>

    );
}

export { MemberConfirmationComponent }