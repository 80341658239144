import { TroubleShootConstants } from '../../pos-redux/troubleshoot/troubleshoot.constants';

const initialState = { actionType: '', loading: false, data: [], error: '' }

function troubleShootReducer(state = initialState, action) {
    switch (action.type) {
        case TroubleShootConstants.GET_REQUEST:
            return {
                ...state,
                actionType: action.type,
                loading: true
            };
        case TroubleShootConstants.GET_SUCCESS:
            return {
                actionType: action.type,
                loading: false,
                data: action.payload,
                error: ''
            }
        case TroubleShootConstants.GET_FAILURE:
            return {
                actionType: action.type,
                loading: false,
                data: [],
                error: action.payload
            }
        case TroubleShootConstants.INIT_AGENT:
            return initialState;
        default:
            return state
    }
}

export { troubleShootReducer };