import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { startConstants } from '../../pos-redux/start/start.constants';
import { MemberConfirmationComponent } from './memberConfirmation.component';
import { CustomerConfirmationComponent } from '../start/customerAccount/customerConfirmation.component';
import { CustomerRegisterPurchaseCompenent } from '../start/customerAccount/customerRegisterPurchase.component';
import { CustomerClubComponent } from './customerClub.component';
import { CorporateCustomerComponent } from './corporateCustomer.component';
import { AddFreeCustomerNumberComponent } from './addFreeCustomerNumber.component';
import { SelfServiceConfirmationComponent } from '../popup/SelfServiceConfirmation.component';
import { setStart } from '../../pos-redux/start/start.actions';
import { MemberRegisterPopUpComponent } from '../popup/memberRegisterPopup.component';
import { MemberCommonPopUpComponent } from '../popup/memberCommonPopUp.component';
import { CustomerAccountConfirmationComponent } from '../popup/customerAccountConfirmation.component';
import { logs } from '../../pos-redux/troubleshoot/troubleshoot.actions';
import { ScreenName } from '../../helpers/pos-message';
import { CustomerConstants, CustomerAccountType } from '../../pos-redux/customer/customer.constant';
import { setHeader } from '../../pos-redux/header/header.actions';
import { headerConstants } from '../../pos-redux/header/header.constants';
import { RegisterProductConstants } from '../../pos-redux/registerProduct/registerProduct.constants';
import { setRegisterProduct } from '../../pos-redux/registerProduct/registerProduct.actions';

function StartComponent() {
    const dispatch = useDispatch();
    const start = useSelector(state => state.start);
    const customerStart = useSelector(state => state.customerDetail);
    const uiConfiguration = useSelector(state => state.uiConfiguration);

    function renderScreen() {
        var renderComponent = null;
        if (uiConfiguration.hasMemberScreen) {
            if (start.name === startConstants.MEMBER_COMFIRMATION && start.isActive)
                renderComponent = <MemberConfirmationComponent />;

            if (start.name === startConstants.ADD_PHONE_NUMBER && start.isActive)
                renderComponent = <CustomerClubComponent />;

            if (start.name === startConstants.ADD_CUSTOMER_NUMBER && start.isActive)
                renderComponent = <CorporateCustomerComponent />;

            if (start.name === startConstants.SELF_SERVICE_CONFIRMATION && start.isActive)
                renderComponent = <SelfServiceConfirmationComponent />;

            if (start.name === startConstants.ADD_NOT_MEMBER_NUMBER && start.isActive)
                renderComponent = <AddFreeCustomerNumberComponent />;

            if (start.name === startConstants.MEMBER_REGISTER_POPUP && start.isActive)
                renderComponent = <MemberRegisterPopUpComponent />;

            if (start.name === startConstants.MEMBER_COMMON_MESSAGE_POPUP && start.isActive)
                renderComponent = <MemberCommonPopUpComponent />;
        } else {
            renderComponent = renderConfirmScreen();
        }

        logs(ScreenName.StartComponent, start.name, start.name, uiConfiguration);

        return renderComponent;
    }

    function renderCustomerScreen() {
        var renderComponent = null;
        if ((customerStart.name === CustomerConstants.CUSTOMER_COMFIRMATION && customerStart.isActive) && uiConfiguration.allowCustomerCredit == CustomerAccountType.Activated)
            renderComponent = <CustomerConfirmationComponent />;

        if ((customerStart.name === CustomerConstants.CUSTOMER_REGISTER_PURCHASE && customerStart.isActive) && uiConfiguration.allowCustomerCredit == CustomerAccountType.Activated)
            renderComponent = <CustomerRegisterPurchaseCompenent />;

        if ((customerStart.name === CustomerConstants.CUSTOMER_ACCOUNT_CONFIRMATION && customerStart.isActive) || (customerStart.name === CustomerConstants.CUSTOMER_COMFIRMATION && customerStart.isActive && !uiConfiguration.isStartTabVisible))
            renderComponent = <CustomerAccountConfirmationComponent />;

        if ((customerStart.name === startConstants.SELF_SERVICE_CONFIRMATION && start.isActive) || (customerStart.name === CustomerConstants.CUSTOMER_COMFIRMATION && customerStart.isActive && !uiConfiguration.isStartTabVisible))
            renderComponent = <SelfServiceConfirmationComponent />;


        return renderComponent;
    }

    function renderConfirmScreen() {
        var renderComponent = null;
        if (uiConfiguration.skipConfirmGodtaPopup) {
            if (uiConfiguration.skipOrderViewFromConfirmationPopUp) {
                dispatch(setHeader(headerConstants.REGISTERVARER, true));
                if (uiConfiguration.rootAssortmentId > 0) {
                    dispatch(setRegisterProduct(RegisterProductConstants.ASSORTMENT_COMPONETNT, true));
                } else {
                    dispatch(setRegisterProduct(RegisterProductConstants.ADD_ITEM_GROUP, true));
                }
            } else {
                dispatch(setHeader(headerConstants.REGISTERVARER, true));
                dispatch(setRegisterProduct(RegisterProductConstants.ADD_PRODUCT, true));
            }
        } else {
            renderComponent = <SelfServiceConfirmationComponent />
        }

        return renderComponent;
    }

    return (
        <div id="startComponent" className="h-100-vh position-relative" >
            <div className="inner-section-height tab-content d-flex align-items-center justify-content-lg-center flex-column " id="nav-tabContent">
                <div className="tab-pane show active" role="tabpanel" aria-labelledby="nav-home-tab">
                    {
                        uiConfiguration.isStartTabVisible && uiConfiguration.allowCustomerCredit != CustomerAccountType.Activated
                            ?
                            renderScreen()
                            :
                            uiConfiguration.allowCustomerCredit == CustomerAccountType.Activated ? renderCustomerScreen() : <SelfServiceConfirmationComponent />
                    }
                </div>
            </div>
        </div >
    );
}

export { StartComponent };