import { React, useEffect } from 'react';
import { useSelector } from 'react-redux';
import cx from "classnames";
import { headerConstants } from '../../pos-redux/header/header.constants';
import { useDispatch } from 'react-redux';
import './header.component.scss';
import { setStart } from '../../pos-redux/start/start.actions';
import { startConstants } from '../../pos-redux/start/start.constants';
import { setHeader } from '../../pos-redux/header/header.actions';
import { setRegisterProduct, setProductData } from '../../pos-redux/registerProduct/registerProduct.actions';
import { RegisterProductConstants } from '../../pos-redux/registerProduct/registerProduct.constants';
import { PaymentConstants } from '../../pos-redux/payment/payment.constants';
import { setPayment } from '../../pos-redux/payment/payment.actions';
import { useHistory } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { cancelPayment } from '../../pos-redux/payment/vipps/vippsPayment.action';
import { localStorageService } from '../../services/api/utilities/localStorageService';
import { MessageConstants } from '../../pos-redux/message/message.constants';
import { Button } from 'devextreme-react/button';
import LazyImage from 'react-lazy-blur-image';
import { preLoadImagePath } from '../../helpers/images';
import { currencyOptions } from '../../helpers/helper';
import { logs } from '../../pos-redux/troubleshoot/troubleshoot.actions';
import { ButtonName, ScreenName } from '../../helpers/pos-message';
import { CustomerConstants, CustomerAccountType } from '../../pos-redux/customer/customer.constant';
import { StatusCheckPopUpComponent } from '../popup/statusCheckPopup.component';
import { posMessage } from '../../helpers/pos-message';

function HeaderComponent(props) {
    const headers = useSelector(state => state.header);
    const registerProduct = useSelector(state => state.RegisterProduct);
    const [payment, vippsPayment, uiConfiguration, stateOrder, stateGiftCard] = useSelector(state => [state.payment, state.vippsPayment, state.uiConfiguration, state.product, state.giftCardPayment]);
    const start = useSelector(state => state.start);
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        changeNavColor();
        setButtonColorAccordingTheme();
    });

    function changeNavColor() {
        var element = document.querySelector(".dx-tab.dx-tab-selected");
        if (element) {
            var style = getComputedStyle(element);
            var default_arrow_color = null;
            var elementButton = document.querySelector(".dx-button-mode-contained.dx-button-success");
            if (elementButton)
                var styleButton = getComputedStyle(elementButton);
            let arrowColor = localStorageService.getThemeProperty() != null ? localStorageService.getThemeProperty() : default_arrow_color;   //window.localStorage.getItem("dx-arrow-color") != null ? window.localStorage.getItem("dx-arrow-color") : default_arrow_color;
            // var pseudoElementStyle = getComputedStyle(document.querySelector(".dx-tab-selected .tab"), '::after')
            if (style.backgroundColor !== "rgba(0, 0, 0, 0)" && (arrowColor == null || arrowColor.color == null)) {
                document.documentElement.style.setProperty("--headerArrowColor", style.backgroundColor);
                var theme = localStorageService.getThemeProperty();
                if (theme) {
                    theme.color = style.backgroundColor;
                    if (styleButton)
                        theme.btnHoverColor = styleButton.backgroundColor;

                    localStorageService.setThemeProperty(theme);
                }

            } else {
                if (arrowColor?.color)
                    document.documentElement.style.setProperty("--headerArrowColor", arrowColor.color);
            }
        }
    }

    function setButtonColorAccordingTheme() {
        var elementButton = document.querySelector(".dx-button-mode-contained.dx-button-success");
        if (elementButton) {
            var styleButton = getComputedStyle(elementButton);
            document.documentElement.style.setProperty('--withOutFoucsColor', styleButton.backgroundColor);
        }
    }

    const clickClose = () => {
        if (headers.name === headerConstants.START) {
            if (start.name == startConstants.MEMBER_COMFIRMATION && start.isActive) {
                history.push("/welcome");
            } else {
                startRedirect();
            }
        } else if (headers.name === headerConstants.REGISTERVARER) {
            if ((registerProduct.name === RegisterProductConstants.ADD_ITEM_GROUP || registerProduct.name === RegisterProductConstants.ADD_SUBITEM_GROUP || registerProduct.name === RegisterProductConstants.ALL_PRODUCTS_COMPONENT) && registerProduct.isActive) {
                dispatch(setRegisterProduct(RegisterProductConstants.ADD_PRODUCT, true));
            }
        } else if (headers.name === headerConstants.BETAL) {
            if (payment.name === PaymentConstants.PAYMENT_METHOD && payment.isActive) {
                dispatch(setHeader(headerConstants.REGISTERVARER, true));
                dispatch(setRegisterProduct(RegisterProductConstants.ADD_PRODUCT, true));
            } else if (payment.name === PaymentConstants.REGISTER_VIPS && payment.isActive) {
                dispatch(setPayment(PaymentConstants.PAYMENT_METHOD, true));
            }
        }
    }

    const clickBack = () => {
        if (headers.name === headerConstants.START) {
            startRedirect();
        } else if (headers.name === headerConstants.REGISTERVARER) {
            registerProductRedirect();
        }
        else if (headers.name === headerConstants.VELGPOSE) {
            dispatch(setHeader(headerConstants.REGISTERVARER, true));
            dispatch(setRegisterProduct(RegisterProductConstants.ADD_PRODUCT, true));
        }
        else if (headers.name === headerConstants.BETAL) {
            if (vippsPayment.data) {
                if (vippsPayment.data.transactionStatus !== 'CANCEL' && vippsPayment.data.orderId)
                    dispatch(cancelPayment(vippsPayment.data.orderId, MessageConstants.Vipps.TranscationSystemName));
            }
            paymentRedirect();
        }
    }
    const startRedirect = () => {
        if (isAddPhoneOrAddCustomerOrAddNotMember()) {
            dispatch(setStart(startConstants.MEMBER_COMFIRMATION, true));
        }
    }
    const clickStart = () => {
        dispatch(setHeader(headerConstants.START, true));
        dispatch(setStart(startConstants.MEMBER_COMFIRMATION, true));
        dispatch(setRegisterProduct(RegisterProductConstants.ADD_PRODUCT, true));
        dispatch(setPayment(PaymentConstants.PAYMENT_METHOD, true));
    }

    const clickRegisterVarer = () => {
        if (headers.name === headerConstants.START) {
            dispatch(setStart(startConstants.SELF_SERVICE_CONFIRMATION, true));
        } else {
            dispatch(setStart(startConstants.SELF_SERVICE_CONFIRMATION, true));
            dispatch(setHeader(headerConstants.REGISTERVARER, true));
            dispatch(setStart(startConstants.MEMBER_COMFIRMATION, true));
            dispatch(setRegisterProduct(RegisterProductConstants.ADD_PRODUCT, true));
            dispatch(setPayment(PaymentConstants.PAYMENT_METHOD, true));
        }
    }

    const clickVelgpose = () => {
        if (headers.name === headerConstants.START) {
            dispatch(setStart(startConstants.SELF_SERVICE_CONFIRMATION, true));
        } else {
            dispatch(setHeader(headerConstants.VELGPOSE, true));
            dispatch(setStart(startConstants.MEMBER_COMFIRMATION, true));
            dispatch(setRegisterProduct(RegisterProductConstants.ADD_PRODUCT, true));
            dispatch(setPayment(PaymentConstants.PAYMENT_METHOD, true));
        }
    }

    const clickBetal = () => {
        if (headers.name === headerConstants.START) {
            dispatch(setStart(startConstants.SELF_SERVICE_CONFIRMATION, true));
        } else {
            dispatch(setHeader(headerConstants.BETAL, true));
            dispatch(setStart(startConstants.MEMBER_COMFIRMATION, true));
            dispatch(setRegisterProduct(RegisterProductConstants.ADD_PRODUCT, true));
            dispatch(setPayment(PaymentConstants.PAYMENT_METHOD, true));
        }
    }

    const registerProductRedirect = () => {
        logs(ScreenName.HeaderComponent, "qty.", "registerProductRedirect", uiConfiguration);
        if (!registerProduct.isActive)
            return;
        if (registerProduct.name !== RegisterProductConstants.ADD_PRODUCT) {
            dispatch(setRegisterProduct(RegisterProductConstants.ADD_PRODUCT, true));
        } else if (registerProduct.name === RegisterProductConstants.ADD_PRODUCT && uiConfiguration.allowCustomerCredit === CustomerAccountType.Activated) {
            dispatch(setHeader(headerConstants.START, true));
            dispatch(setStart(CustomerConstants.CUSTOMER_COMFIRMATION, true));
        } else if (registerProduct.name === RegisterProductConstants.ADD_PRODUCT) {
            if (!uiConfiguration.skipConfirmGodtaPopup) {
                dispatch(setHeader(headerConstants.START, true));
                dispatch(setStart(startConstants.MEMBER_COMFIRMATION, true));
            }
        }
    }

    const paymentRedirect = () => {
        if (payment.name === PaymentConstants.PAYMENT_METHOD && payment.isActive) {
            if (uiConfiguration.skipBagSelector) {
                dispatch(setHeader(headerConstants.REGISTERVARER, true));
                dispatch(setRegisterProduct(RegisterProductConstants.ADD_PRODUCT, true));
            } else {
                dispatch(setHeader(headerConstants.VELGPOSE, true));
            }
        } else {
            dispatch(setPayment(PaymentConstants.PAYMENT_METHOD, true));
        }
    }

    const isAddPhoneOrAddCustomerOrAddNotMember = () => {
        return (start.name === startConstants.ADD_PHONE_NUMBER || start.name === startConstants.ADD_CUSTOMER_NUMBER || start.name === startConstants.ADD_NOT_MEMBER_NUMBER) && start.isActive
    };

    const isBackButtonVisible = () => {
        if (headers.name === headerConstants.START) {
            return isAddPhoneOrAddCustomerOrAddNotMember();
        } else if (headers.name === headerConstants.BETAL) {
            if (stateGiftCard?.paymentTransactionModel && Object.keys(stateGiftCard?.paymentTransactionModel).length > 0) {
                return false;
            }
            return !((payment.name === PaymentConstants.BANK_TERMINAL || payment.name === PaymentConstants.BANK_TERMINAL_WAITING) && payment.isActive);
        } else {
            return headers.name !== headerConstants.START;
        }
    };

    const isCloseButtonVisible = () => {
        if (headers.isActive) {
            if (headers.name === headerConstants.REGISTERVARER && registerProduct.name === RegisterProductConstants.ADD_PRODUCT && registerProduct.isActive) {
                return false;
            } else if (headers.name === headerConstants.BETAL && (payment.name === PaymentConstants.PAYMENT_INVOICE || payment.name === PaymentConstants.BANK_TERMINAL || payment.name === PaymentConstants.BANK_TERMINAL_WAITING) && payment.isActive) {
                return false;
            } else if (headers.name === headerConstants.VELGPOSE) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    }

    const navBarAllTile = () => {
        return (<div className="dx-tabs-wrapper">
            <div className={cx('dx-item', 'dx-tab', 'dx-nav-item', 'nav-link', headers.name === headerConstants.START && headers.isActive === true ? 'dx-tab-selected' : '')} role="tab" aria-selected="false">
                <div className="dx-item-content dx-tab-content dx-nav-item-content">
                    <span className="nav-link-radio-button"></span>
                    <span className="dx-tab-text"> {headerConstants.START}</span>
                    <span id="header-arrow-color" className={(headers.name === headerConstants.START && headers.isActive === true ? 'nav-link-arrow-after tab' : 'nav-link-arrow-after')} ></span>
                </div>
            </div>
            <div className={cx('dx-item', 'dx-tab', 'dx-nav-item', 'nav-link', headers.name === headerConstants.REGISTERVARER && headers.isActive === true ? 'dx-tab-selected' : '')} role="tab" aria-selected="false">
                <div className="dx-item-content dx-tab-content dx-nav-item-content">
                    <span className="nav-link-radio-button"></span>
                    <span className="dx-tab-text"> {headerConstants.REGISTERVARER}</span>
                    <span id="header-arrow-color" className={(headers.name === headerConstants.REGISTERVARER && headers.isActive === true ? 'nav-link-arrow-after tab' : 'nav-link-arrow-after')}></span>
                </div>
            </div>
            {uiConfiguration.skipBagSelector ? <div></div> :
                <div className={cx('dx-item', 'dx-tab', 'dx-nav-item', 'nav-link', headers.name === headerConstants.VELGPOSE && headers.isActive === true ? 'dx-tab-selected' : '')} role="tab" aria-selected="false">
                    <div className="dx-item-content dx-tab-content dx-nav-item-content">
                        <span className="nav-link-radio-button"></span>
                        <span className="dx-tab-text"> {headerConstants.VELGPOSE}</span>
                        <span id="header-arrow-color" className={(headers.name === headerConstants.VELGPOSE && headers.isActive === true ? 'nav-link-arrow-after tab' : 'nav-link-arrow-after')}></span>
                    </div>
                </div>
            }
            <div className={cx('dx-item', 'dx-tab', 'dx-nav-item', 'nav-link', headers.name === headerConstants.BETAL && headers.isActive === true ? 'dx-tab-selected' : '')} role="tab" aria-selected="false">
                <div className="dx-item-content dx-tab-content dx-nav-item-content">
                    <span className="nav-link-radio-button"></span>
                    <span className="dx-tab-text"> {headerConstants.BETAL}</span>
                    <span id="header-arrow-color" className={(headers.name === headerConstants.BETAL && headers.isActive === true ? 'nav-link-arrow-after tab' : 'nav-link-arrow-after')}></span>
                </div>
            </div>
        </div>);
    }

    const navBarTile = () => {
        return (<div className="dx-tabs-wrapper">
            <div className={cx('dx-item', 'dx-tab', 'dx-nav-item', 'nav-link', headers.name === headerConstants.START && headers.isActive === true ? 'dx-tab-selected' : '')} role="tab" aria-selected="false">
                <div className="dx-item-content dx-tab-content dx-nav-item-content">
                    <span className="nav-link-radio-button"></span>
                    <span className="dx-tab-text"> {headerConstants.START}</span>
                    <span id="header-arrow-color" className={(headers.name === headerConstants.START && headers.isActive === true ? 'nav-link-arrow-after tab' : 'nav-link-arrow-after')} ></span>
                </div>
            </div>
            <div className={cx('dx-item', 'dx-tab', 'dx-nav-item', 'nav-link', headers.name === headerConstants.REGISTERVARER && headers.isActive === true ? 'dx-tab-selected' : '')} role="tab" aria-selected="false">
                <div className="dx-item-content dx-tab-content dx-nav-item-content">
                    <span className="nav-link-radio-button"></span>
                    <span className="dx-tab-text"> {headerConstants.REGISTERVARER}</span>
                    <span id="header-arrow-color" className={(headers.name === headerConstants.REGISTERVARER && headers.isActive === true ? 'nav-link-arrow-after tab' : 'nav-link-arrow-after')}></span>
                </div>
            </div>
        </div>);
    }

    const priceFormat = (price) => {
        return price.toLocaleString('nb-NB', {
            ...currencyOptions,
            style: 'decimal',
        })
    }

    const cartButtonAtNavBar = () => {
        var qty = 0;
        var price = 0;
        if (stateOrder.OrderPos) {
            qty = stateOrder.OrderPos.totalQuantityPOSOrderLines || 0;
            price = stateOrder.OrderPos.order.orderTotal || 0;
        }

        if (registerProduct.name === RegisterProductConstants.ASSORTMENT_COMPONETNT) {
            return (
                <Button
                    id="header-padding-button"
                    type="success"
                    stylingMode="contained"
                    className="btn btn-primary cursor-hand btn-icon" onClick={() => registerProductRedirect()}>
                    <div id="badge"> {qty} </div>
                    <LazyImage
                        placeholder={`${preLoadImagePath.default_image_55}`}
                        uri={`${preLoadImagePath.cart_white}`}
                        render={(src, style) => <img className="w-35 me-1 img-30" src={src} alt="img" />}
                    />
                    <span className="whiteSpace"> kr {priceFormat(price)}</span>
                </Button>)
        }
    }

    return (
        <app-navbar class="d-flex align-item-center">
            {isMobile ?
                isBackButtonVisible() ? <div><span className="icon dx-icon-back cursor-hand" onClick={clickBack}></span></div> : ''
                :
                isBackButtonVisible() ? <div id="header-padding" className="dx-button-mode-contained btn-primary dx-theme-border-color header-back d-flex cursor-hand" onClick={clickBack}><span className="mt-1 icon dx-icon-arrowleft"></span><span className="header-text mt-1">{ButtonName.Back}</span>  </div> : ''
            }

            <dx-nav-bar role="tablist" class="nav nav-tabs dx-list-border-visible dx-tabs dx-widget dx-visibility-change-handler dx-collection dx-navbar dx-tabs-expanded mx-3" tabindex="0" aria-activedescendant="dx-3ca8d242-72c5-1b2c-9a0a-e431cde5a9a3">
                {uiConfiguration.showCartButtonAtTop ? navBarTile() : navBarAllTile()}
            </dx-nav-bar>

            {registerProduct.isActive ? cartButtonAtNavBar() : ''}
            {parseInt(localStorageService.getApiErrorCount()) >= posMessage.apiErrorLimitRate ? <StatusCheckPopUpComponent /> : ''}
        </app-navbar>
    );
};




export { HeaderComponent };