import { SelectBagConstants } from "./selectBag.constants";

const initialState = {
    actionType: '',
    loading: false,
    data: [],
    error: ''
};

function selectBag(state = initialState, action) {
    switch (action.type) {
        case SelectBagConstants.BAG_TYPE_STATUS_REQUEST:
            return {
                ...state,
                actionType: action.type,
                loading: true
            }

        case SelectBagConstants.BAG_TYPE_STATUS_SUCCESS:
            return {
                actionType: action.type,
                loading: false,
                data: action.payload,
                error: ''
            }

        case SelectBagConstants.BAG_TYPE_STATUS_FAILURE:
            return {
                actionType: action.type,
                loading: false,
                data: [],
                error: action.payload
            }

        case SelectBagConstants.BAG_TYPE_USED_QTY_UPDATE:
            return {
                ...state,
                data: action.payload
            }
        case SelectBagConstants.INIT_BAGS:
            return initialState;

        case SelectBagConstants.BAG_LOADING:
            return {
                ...state,
                loading: action.payload
            }

        default:
            return state
    }
}

export { selectBag };