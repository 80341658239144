export const ProductConstants = {
    //#region Actions
    ADD_PRODUCT: 'ADD_PRODUCT',
    REMOVE_PRODUCT: 'REMOVE_PRODUCT',
    UPDATE_PRODUCT_USED_QTY: 'UPDATE_PRODUCT_USED_QTY',
    ADD_PRODUCT_WITH_QTY: 'ADD_PRODUCT_WITH_QTY',
    SELECT_PRODUCT: 'SELECT_PRODUCT',
    SUMMARY_AFTER_ADD_PRODUCT: 'SUMMARY_AFTER_ADD_PRODUCT',
    INIT_PRODUCT: 'INIT_PRODUCT',
    INIT_SELECTED_PRODUCT: 'INIT_SELECTED_PRODUCT',
    EDIT_PRODUCT_USED_QTY: 'EDIT_PRODUCT_USED_QTY',
    ADD_ORDER_POS: 'ADD_ORDER_POS',
    //#endregion
}
