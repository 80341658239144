export const RegisterProductConstants = {
    ADD_PRODUCT: 'ADD_PRODUCT',
    ADD_ITEM_GROUP: 'ADD_ITEM_GROUP',
    ADD_SUBITEM_GROUP: 'ADD_SUBITEM_GROUP',
    CALL_FOR_OPERATIONS: 'CALL_FOR_OPERATIONS',
    CANCEL_ORDER: 'CANCEL_ORDER',
    PRODUCT_CONFIRMATION: 'PRODUCT_CONFIRMATION',
    ALL_PRODUCTS_COMPONENT: 'ALL_PRODUCTS_COMPONENT',
    ADD_PRODUCT_WITH_QTY: 'ADD_PRODUCT_WITH_QTY',
    SET_RELOAD: 'SET_RELOAD',
    EDIT_PRODUCT: 'EDIT_PRODUCT',
    BARCODE_FAILURE: 'BARCODE_FAILURE',
    INIT_REGISTER_PRODUCT : 'INIT_REGISTER_PRODUCT',
    STRUCTURE_PRODUCT_POPUP : 'STRUCTURE_PRODUCT_POPUP',
    SET_REGISTER_PRODUCT_INFO : 'SET_REGISTER_PRODUCT_INFO',

    //action
    SET_REGISTER_PRODUCT: 'SET_REGISTER_PRODUCT',
    SET_REGISTER_PRODUCT_MAINGROUPS: 'SET_REGISTER_PRODUCT_MAINGROUPS',
    SET_REGISTER_PRODUCT_MAINGROUP_SELECTED: 'SET_REGISTER_PRODUCT_MAINGROUP_SELECTED',
    SET_REGISTER_PRODUCT_SUBGROUPS: 'SET_REGISTER_PRODUCT_SUBGROUPS',


    PRODUCTS_FROM_SUBGROUPS_SELECTED: 'PRODUCTS_FROM_SUBGROUPS_SELECTED',
    SUBGROUPS_PRODUCTS: 'SUBGROUPS_PRODUCTS',

    //Actions
    PRODUCTS_REQUEST: 'PRODUCTS_REQUEST',
    PRODUCTS_SUCCESS: 'PRODUCTS_SUCCESS',
    PRODUCTS_FAILURE: 'PRODUCTS_FAILURE',
    CALL_FOR_HELP: 'CALL_FOR_HELP',

    NO_ITEM_FOUND: 'Ingen objekter funnet',
    ITEM_CANNOT_BE_LESS_THAN_ONE: "Mengden kan ikke være mindre enn én",
    ITEM_ADDED_SUCESSFULLY: "Elementet ble lagt til",

    ASSORTMENT_COMPONETNT: 'ASSORTMENT_COMPONETNT',
    SET_PARENT_ASSORTMENT_SELECTED: 'SET_PARENT_ASSORTMENT_SELECTED',
    SET_REGISTER_ASSORTMENTS: 'SET_REGISTER_ASSORTMENTS',
    SET_REGISTER_PRODUCT_ASSORTMENTS: 'SET_REGISTER_PRODUCT_ASSORTMENTS',



};