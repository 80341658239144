export const VippsPaymentConstants = {
    //#region Action Types
    INITIATE_PAYMENT_REQUEST: 'INITIATE_PAYMENT_REQUEST',
    INITIATE_PAYMENT_SUCCESS: 'INITIATE_PAYMENT_SUCCESS',
    INITIATE_PAYMENT_FAILURE: 'INITIATE_PAYMENT_FAILURE',

    PAYMENT_STATUS_REQUEST: 'PAYMENT_STATUS_REQUEST',
    PAYMENT_STATUS_SUCCESS: 'PAYMENT_STATUS_SUCCESS',
    PAYMENT_STATUS_FAILURE: 'PAYMENT_STATUS_FAILURE',

    CANCEL_PAYMENT_REQUEST: 'CANCEL_PAYMENT_REQUEST',
    CANCEL_PAYMENT_SUCCESS: 'CANCEL_PAYMENT_SUCCESS',
    CANCEL_PAYMENT_FAILURE: 'CANCEL_PAYMENT_FAILURE',

    PENDING_PAYMENT_REQUEST: 'PENDING_PAYMENT_REQUEST',
    PENDING_PAYMENT_SUCCESS: 'PENDING_PAYMENT_SUCCESS',
    PENDING_PAYMENT_FAILURE: 'PENDING_PAYMENT_FAILURE',

    PAYMENT_TRANSACTION_PROGRESS: 'PAYMENT_TRANSACTION_PROGRESS',
    INIT_VIPPS : 'INIT_VIPPS',
    VIPPS : 'VIPPS',
    //#endregion 

    //#region Constants
    VippsPaymentStatus: {
        Cancel: 'cancel',
        Cancelled: 'cancelled',
        Failed: 'failed',
        Rejected: 'rejected',
        Reserve: 'reserve',
        Refund: 'refund',
        Sale: 'sale',
        Initiate: 'initiate'
    },

    TransactionCancelledMsg: 'Transaksjonen er avbrutt',
    TransactionFailedMsg: 'Betaling mislyktes på grunn av ingen dekning, utdaterte kortdetaljer eller lignende',
    TransactionRejectedMsg: 'Transaksjonen avvist på grunn av ingen brukerhandling i Vipps-appen',
    TransactionReservedMsg: 'Vipps er ikke konfigurert riktig. Kontakt administrator',
    PaymentRefundedMsg: 'Betalingen er refundert',
    TransactionSuccessfulMsg: 'Betalingen var vellykket',
    TransactionApprovalWaitMsg: 'Venter på at kunde skal godkjenne i Vipps-appen',
    TryAgainMsg: 'Noe gikk galt. Prøv igjen senere.'

    
    //#endregion
}