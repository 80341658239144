import { EmployeeConstants } from "./employee.constants";

const initialState = {
    actionType: '',
    loading: false,
    validCard: false,
    data: {},
    error: ''
};

function employeeDetail(state = initialState, action) {
    switch (action.type) {
        case EmployeeConstants.EMPLOYEE_DETAIL_REQUEST:
            return {
                ...state,
                actionType: action.type,
                loading: true
            }

        case EmployeeConstants.EMPLOYEE_DETAIL_SUCCESS:
            return {
                actionType: action.type,
                loading: false,
                data: action.payload,
                validCard: true,
                error: ''
            }

        case EmployeeConstants.EMPLOYEE_DETAIL_ERROR:
            return {
                actionType: action.type,
                loading: false,
                data: [],
                validCard: false,
                error: action.payload
            }

        case EmployeeConstants.INIT_EMPLOYEE:
            return initialState;

        default:
            return state
    }
}

export { employeeDetail };