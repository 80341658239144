import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from 'react';
import { FooterComponent } from "../../components/footer/footer.component";
import { useHistory } from 'react-router-dom';
import { getUIConfiguration, avaibleLight, occupiedLight } from '../../pos-redux/uiConfiguration/uiConfiguration.actions';
import { LoadPanel } from 'devextreme-react/load-panel';
import { Button } from "devextreme-react";
import LazyImage from 'react-lazy-blur-image';
import { ApiUrlConstants } from '../../services/api/apiUrl.constants';
import { preLoadImagePath } from '../../helpers/images';
import { setHeader } from '../../pos-redux/header/header.actions';
import { headerConstants } from '../../pos-redux/header/header.constants';
import { setStart, initStart } from '../../pos-redux/start/start.actions';
import { initEmployee } from '../../pos-redux/employee/employee.actions';
import { initProduct } from '../../pos-redux/registerProduct/product.actions';
import { initVipps } from '../../pos-redux/payment/vipps/vippsPayment.action';
import { initSelectedproduct } from '../../pos-redux/registerProduct/addProductWithQty.actions';
import { initTerminal } from '../../pos-redux/payment/terminal/terminal.actions';
import { initBags, resetBagQty } from '../../pos-redux/selectBag/selectBag.actions';
import { initRegisterProduct } from '../../pos-redux/registerProduct/registerProduct.actions';
import { AppVersion, delay } from '../../helpers/helper';
import { posMessage, lightEnum, ScreenName } from '../../helpers/pos-message';
import { getCheckAgentStatus, initAgent, logs } from '../../pos-redux/troubleshoot/troubleshoot.actions';
import { TroubleShootConstants } from "../../pos-redux/troubleshoot/troubleshoot.constants";
import { startConstants } from '../../pos-redux/start/start.constants';
import { StatusCheckPopUpComponent } from "../../components/popup/statusCheckPopup.component";
import { posAgentService } from "../../services/posAgent.service";
import { initCustomer } from '../../pos-redux/customer/customer.action';
import { initGiftCard } from '../../pos-redux/payment/giftCard/giftCard.actions';
import { showUILoader } from '../../pos-redux/commonUI/commonUI.actions';

function WelcomePage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [uiConfiguration, selectBagTypes, startTroubleShoot] = useSelector(state => [state.uiConfiguration, state.selectBag, state.troubleShootReducer]);

  const welcomeTxt = uiConfiguration.welcomeText;
  const welcomeSubTitle = uiConfiguration.welcomeSubTitle;
  //todo: remove use for hardcode logo. 
  const welcomeLogo = uiConfiguration.welcomeLogo ? uiConfiguration.welcomeLogo : `${preLoadImagePath.logo}`;
  const clickHereTxt = "Trykk her for å starte";
  const [towerLight, setTowerLight] = useState(false);
  const [statusPopup, setStatusPopup] = useState(false);

  useEffect(() => {
    const imgs = preLoadImagePath;
    cacheImages(imgs);
    getUIConfig();
  }, []);

  const getUIConfig = async () => {
    var configData = await dispatch(getUIConfiguration(AppVersion));
    if (!configData) {
      setStatusPopup(true);
      return;
    }

    renderView(configData);
  }

  const renderView = async (configData) => {
    var response = await dispatch(getCheckAgentStatus());
    if (response && response.data) {
      if (!response.data.ServiceStatusClosed) {
        if (!response.data.PaymentTerminalConnected) {
          // both values are paramter and agent are to be true then pop-up is shown
          setStatusPopup(configData.isBankTerminalConnectionExists && !response.data.PaymentTerminalConnected);
        } else {
          setStatusPopup(false);
        }
      } else {
        // both values are paramter and agent are to be true then pop-up is shown
        setStatusPopup(configData.isClosedSelfServiceSystem && response.data.ServiceStatusClosed);
      }
    }
  }

  const cacheImages = async (srcArray) => {
    var promises = [];
    for (const [key, value] of Object.entries(srcArray)) {
      var promise = new Promise(function (resolve, reject) {
        const img = new Image();
        img.src = value;
        img.onload = resolve();
        img.onerror = reject();
      }
      );
      promises.push(promise)
    }

    await Promise.all(promises);
  }

  const begin = () => {
    logs(ScreenName.Welcome, clickHereTxt, ScreenName.Memberconfirmation, uiConfiguration);
    setButtonColorAccordingTheme();
    dispatch(showUILoader(false));
    dispatch(initAgent());
    dispatch(occupiedLight(lightEnum.TurnOn));
    dispatch(setHeader(headerConstants.START, true));
    dispatch(initStart());
    dispatch(initRegisterProduct());
    dispatch(initProduct());
    dispatch(initSelectedproduct());
    dispatch(initEmployee());
    dispatch(initTerminal());
    dispatch(initVipps());
    dispatch(initCustomer());
    dispatch(initGiftCard());

    // if (selectBagTypes.data?.length > 0)
    //   dispatch(resetBagQty(selectBagTypes.data));

    history.push("/home");
  };

  const troubleShoot = () => {
    logs(ScreenName.Welcome, "troubleshoot", ScreenName.Troubleshoot, uiConfiguration);
    history.push("/troubleshoot")
  }


  function setButtonColorAccordingTheme() {
    var elementButton = document.querySelector(".dx-button-mode-contained.dx-button-success");
    if (elementButton) {
      var styleButton = getComputedStyle(elementButton, "dx-state-focused");
      if (styleButton && styleButton.backgroundColor != 'rgba(0, 0, 0, 0)') {
        document.documentElement.style.setProperty('--withOutFoucsColor', styleButton.backgroundColor);
      }
    }
  }

  function iconsSet() {
    if (startTroubleShoot.actionType === TroubleShootConstants.GET_FAILURE || startTroubleShoot.actionType === TroubleShootConstants.GET_SUCCESS) {
      if (startTroubleShoot?.data?.data) {
        return (Object.values(startTroubleShoot?.data?.data).map((value, index) => {
          if (index <= 2) { // only 3 status icons need
            if (value) {
              if (startTroubleShoot?.data?.data) {
                if (startTroubleShoot?.data?.data.LightTowerVisible && !towerLight) {
                  dispatch(avaibleLight(lightEnum.TurnOn));
                  setTowerLight(true);
                }
              }
              return <span className="dx-button-mode-contained dx-button-success"></span>
            } else if (index == 2) { // For LightTower
              return <></>
            }
            else {
              return <span className="dx-button-mode-contained dx-button-danger"></span>
            }
          }
        }))
      } else {
        return <span className="light-backGround-color-grey"></span>
      }
    }
  }

  return (
    <>
      <div id="welcomePage" className="d-flex align-items-center justify-content-center flex-column h-90-vh">
        <LoadPanel
          message={posMessage.loading}
          shadingColor="rgba(0,0,0,0.4)"
          visible={uiConfiguration.loading}
          showIndicator={true}
        />
        <div className="inner-section-height d-flex align-items-center justify-content-center">
          <div className="text-center">
            <h1>{welcomeTxt}</h1>
            <img className="pt-2 pt-lg-4 pb-2 logo" src={welcomeLogo} alt="img" />
            <p className="pb-2"> <strong> {welcomeSubTitle} </strong></p>
            <Button
              type="success"
              stylingMode="contained"
              className="btn btn-primary mx-auto cursor-hand btn-icon welcome-btn-width" onClick={begin}>
              <LazyImage
                placeholder={`${preLoadImagePath.default_image_55}`}
                uri={`${preLoadImagePath.tap_icon}`}
                render={(src, style) => <img src={src} alt="img" className="w-30 me-3 img-50" />}
              />
              {clickHereTxt}
            </Button>

          </div>
        </div>
        <div id="icon" onClick={(troubleShoot)}>
          {
            !statusPopup
              ?
              iconsSet()
              :
              <></>
          }
        </div>
        {
          statusPopup ? <StatusCheckPopUpComponent /> : <></>
        }
        <FooterComponent />
      </div>
    </>
  );
}

export { WelcomePage };
