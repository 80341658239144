import { React, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { HeaderComponent } from "../../components/header/header.component";
import { StartComponent } from "../../components/start/start.component";
import { RegisterProductComponent } from "../../components/RegisterProduct/registerProduct.component";
import { SelectBagComponent } from "../../components/SelectBag/selectBag.component";
import { PaymentComponent } from "../../components/payment/payment.component";
import { headerConstants } from "../../pos-redux/header/header.constants";
import { FooterComponent, FooterWithTextComponent } from "../../components/footer/footer.component";
import { RegisterProductConstants } from '../../pos-redux/registerProduct/registerProduct.constants';
import { isMobile } from 'react-device-detect';

function HomePage() {
  const headers = useSelector((state) => state.header);
  const RegisterProduct = useSelector(state => state.RegisterProduct);

  const footerComponent = () => {
    if ((headers.name === headerConstants.REGISTERVARER && headers.isActive)) {
      if (isMobile) {
        if (RegisterProduct.name === RegisterProductConstants.ADD_PRODUCT || RegisterProduct.name === RegisterProductConstants.ADD_ITEM_GROUP
          || RegisterProduct.name === RegisterProductConstants.ADD_SUBITEM_GROUP || RegisterProduct.name === RegisterProductConstants.ALL_PRODUCTS_COMPONENT
          || RegisterProduct.name === RegisterProductConstants.ADD_PRODUCT_WITH_QTY || RegisterProduct.name === RegisterProductConstants.CANCEL_ORDER) {
          return (<FooterWithTextComponent />);
        }
      } else if (RegisterProduct.name === RegisterProductConstants.ADD_ITEM_GROUP
        || RegisterProduct.name === RegisterProductConstants.ADD_SUBITEM_GROUP || RegisterProduct.name === RegisterProductConstants.ALL_PRODUCTS_COMPONENT
        || RegisterProduct.name === RegisterProductConstants.ADD_PRODUCT_WITH_QTY || RegisterProduct.name === RegisterProductConstants.CANCEL_ORDER || RegisterProductConstants.ASSORTMENT_COMPONETNT) {
        return (<FooterWithTextComponent />);
      }
    }
    return (<FooterComponent />);
  }

  return (
    <div>
      <HeaderComponent />
      {headers.name === headerConstants.START && headers.isActive ? (<StartComponent />) : ''}
      {(headers.name === headerConstants.REGISTERVARER && headers.isActive) ? (<RegisterProductComponent />) : ''}
      {headers.name === headerConstants.VELGPOSE && headers.isActive ? (<SelectBagComponent />) : ''}
      {headers.name === headerConstants.BETAL && headers.isActive ? (<PaymentComponent />) : ''}
      {footerComponent()}
    </div>
  );
}

export { HomePage };
