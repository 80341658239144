import { GiftCardConstants } from "./giftCard.constants";

const initialState = {
    actionType: '',
    loading: false,
    data: [],
    error: '',
    paymentTransactionModel: {}
};

function giftCardPayment(state = initialState, action) {
    switch (action.type) {
        case GiftCardConstants.GIFTCARD_INITIATE_PAYMENT_REQUEST:
            return {
                ...state,
                actionType: action.type,
                loading: true
            }

        case GiftCardConstants.GIFTCARD_INITIATE_PAYMENT_SUCCESS:
            return {
                actionType: action.type,
                loading: false,
                data: action.payload.cardDetail,
                paymentTransactionModel: action.payload.registeredLedgerPayments,
                error: ''
            }

        case GiftCardConstants.GIFTCARD_INITIATE_PAYMENT_ERROR:
            return {
                actionType: action.type,
                loading: false,
                data: [],
                error: action.payload
            }

        case GiftCardConstants.GIFTCARD_PAYMENT_STATUS_REQUEST:
            return {
                ...state,
                actionType: action.type,
                loading: true
            }

        case GiftCardConstants.GIFTCARD_PAYMENT_STATUS_SUCCESS:
            return {
                ...state,
                actionType: action.type,
                loading: false,
                data: action.payload,
                error: ''
            }

        case GiftCardConstants.GIFTCARD_PAYMENT_STATUS_ERROR:
            return {
                actionType: action.type,
                loading: false,
                data: [],
                error: action.payload
            }
        case GiftCardConstants.INIT_GIFTCARD:
            return {
                initialState
            }

        case GiftCardConstants.WITHDRAWAL_GIFTCARD_STATUS_REQUEST:
            return {
                ...state,
                actionType: action.type,
                loading: true
            }

        case GiftCardConstants.WITHDRAWAL_GIFTCARD_PAYMENT_SUCCESS:
            return {
                data: { ...state.data },
                loading: false,
                paymentTransactionModel: action.payload,
                error: ''
            }
        default:
            return state
    }
}

export { giftCardPayment };