import { PaymentConstants } from './payment.constants';

const initialState = { name: PaymentConstants.PAYMENT_METHOD, isActive: true, data: {} };

function payment(state = initialState, action) {
    switch (action.type) {
        case PaymentConstants.SET_PAYMENT:
            const { name, isActive } = action.payload;
            return {
                ...state,
                name: name,
                isActive: isActive
            };
        case PaymentConstants.POS_CONFIRM_ORDER:
            const payInfo = action.payload;
            return {                
                name: payInfo.name,
                data: payInfo.posOrder
            };
        default:
            return state
    }
}

export { payment };