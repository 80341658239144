import { TerminalConstants } from "./terminal.constants"
import { terminalPaymentService } from '../../../services/terminalPayment.service';
import { MessageConstants } from '../../message/message.constants';
import { PaymentConstants } from '../payment.constants';
import { setPayment } from '../payment.actions';
import { setError, setMessage } from '../../errorHandler/errorHandler.actions';
import { delay } from "../../../helpers/helper";
import { setTransactionProgressMessage } from '../../message/message.actions'
import { Settings } from '../../../../App/App';
import { setHeader } from '../../../pos-redux/header/header.actions';
import { setRegisterProduct } from '../../../pos-redux/registerProduct/registerProduct.actions';
import { headerConstants } from '../../../pos-redux/header/header.constants';
import { RegisterProductConstants } from '../../../pos-redux/registerProduct/registerProduct.constants';

//#region  Action
let delayTime = 5000;
let timeOutWithoutResponse = 0;
let initTime = null;
var second = 0;
let timeInMs = -1
var timerId;
var errorsBankTerminal = null;
var cancelPaymentStatus = false;

export const initTerminal = () => {
    return {
        type: TerminalConstants.INIT_TERMINAL
    }
}

export const initiatePaymentRequest = () => {
    return {
        type: TerminalConstants.TERMINAL_INITIATE_PAYMENT_REQUEST
    }
}

export const initiatePaymentSuccess = response => {
    return {
        type: TerminalConstants.TERMINAL_INITIATE_PAYMENT_SUCCESS,
        payload: response
    }
}

export const initiatePaymentFailure = error => {
    return {
        type: TerminalConstants.TERMINAL_INITIATE_PAYMENT_ERROR,
        payload: error
    }
}

export const paymentStatusRequest = () => {
    return {
        type: TerminalConstants.TERMINAL_PAYMENT_STATUS_REQUEST
    }
}

export const paymentStatusSuccess = response => {
    return {
        type: TerminalConstants.TERMINAL_PAYMENT_STATUS_SUCCESS,
        payload: response
    }
}

export const paymentStatusFailure = error => {
    return {
        type: TerminalConstants.TERMINAL_PAYMENT_STATUS_ERROR,
        payload: error
    }
}
//#endregion 
//#region  Action Handler
export const initiateTerminalPayment = (amount, uiConfiguration) => async (dispatch) => {
    dispatch(initiatePaymentRequest(TerminalConstants.TERMINAL_INITIATE_PAYMENT_REQUEST, true));
    dispatch(setTransactionProgressMessage(MessageConstants.Terminal.CompletePaymentWarningMsg, true));
    const response = await terminalPaymentService.initiatePayment(amount);
    if (response && response.data) {
        dispatch(initiatePaymentSuccess(response.data));
        dispatch(setTransactionProgressMessage(MessageConstants.Terminal.InserCardWarningMsg, true));
    }
    else {
        handleError('Noe gikk galt', initiatePaymentFailure, dispatch, uiConfiguration);
    }
    return response;
}

export const checkPaymentStatus = (uiConfiguration) => async dispatch => {

    dispatch(paymentStatusRequest());

    if (errorsBankTerminal == null) {
        errorsBankTerminal = await Settings.AppErrorHandler();
        errorsBankTerminal = errorsBankTerminal.payment.bankTerminal;
    }

    const response = await terminalPaymentService.checkPaymentDetail();
    if (response && response.data) {
        dispatch(paymentStatusSuccess(response.data));
        const status = response.data.Status;
        if (errorsBankTerminal != null) {

            if (errorsBankTerminal.find(x => x.message === response.data.ShortDsiplayMessage) && response.data.ShortDsiplayMessage) {
                handleError(response.data.ShortDsiplayMessage, paymentStatusFailure, dispatch, uiConfiguration);
                return;
            }
        }

        if (status === null && !cancelPaymentStatus) {
            await delay(delayTime);
            await dispatch(checkPaymentStatus(uiConfiguration));
        }
        else if (status !== TerminalConstants.TerminalPaymentStatus.TransaksjonOK) {
            setError('Kan ikke fullføre betalingen');
            cancelPaymentStatus = false;
            return;
        }
    }
    else {
        cancelPaymentStatus = false;
        handleError('Noe gikk galt', paymentStatusFailure, dispatch, uiConfiguration);
    }
}

function resetTimer(timerId) {
    if (timerId)
        clearTimeout(timerId);

    initTime = null;
    timeInMs = -1;
    second = 0;
}

export const waitingTimer = async (delayTimeMs) => {
    await delay(delayTimeMs);
}

function limitOnTimer(status, dispatch, apiTime, uiConfiguration) {
    second = apiTime - initTime;
    if (status === null && second >= timeOutWithoutResponse) {
        initTime = null;
        timeInMs = -1;
        handleError('Noe gikk galt', paymentStatusFailure, dispatch, uiConfiguration);
    }
}

export const cancelpayment = (uiConfiguration) => async dispatch => {
    const response = await terminalPaymentService.cancelPayment();
    cancelPaymentStatus = true;

    if (response && response.data) {
        if (response.data.ShortDsiplayMessage != null) {
            handleError(response.data.ShortDsiplayMessage, paymentStatusFailure, dispatch, uiConfiguration);
        }
    }
    else {
        handleError('Noe gikk galt', paymentStatusFailure, dispatch, uiConfiguration);
    }
}

export const redirectToScreen = (uiConfiguration) => async dispatch => {
    if (!uiConfiguration.skipBagSelector) {
        if (!uiConfiguration.vippsPaymentAvailable && !uiConfiguration.hasMemberScreen) {
            dispatch(setHeader(headerConstants.VELGPOSE, true));
        } else {
            dispatch(setPayment(PaymentConstants.PAYMENT_METHOD, true));
        }
    } else if (uiConfiguration.vippsPaymentAvailable) {
        dispatch(setPayment(PaymentConstants.PAYMENT_METHOD, true));
    } else {
        dispatch(setHeader(headerConstants.REGISTERVARER, true));
        dispatch(setRegisterProduct(RegisterProductConstants.ADD_PRODUCT, true));
    }
}

//#endregion

//#region  private functions
function handleError(error, action, dispatch, uiConfiguration) {
    let errorMsg = error;
    if (String(error).indexOf('Network Error') > -1 || String(error).indexOf('Noe gikk galt') > -1) {
        errorMsg = 'Bankterminal ikke tilkoblet';
    }

    dispatch(action(errorMsg));
    dispatch(setError(errorMsg, PaymentConstants.PAYMENT_METHOD));
    dispatch(redirectToScreen(uiConfiguration));
}
//#endregion