import { posAgentService } from '../../services/posAgent.service'
import { TroubleShootConstants } from './troubleshoot.constants';

//#region Actions
export const initAgent = () => {
    return {
        type: TroubleShootConstants.INIT_AGENT
    }
}

export const getAgentRequest = () => {
    return {
        type: TroubleShootConstants.GET_REQUEST
    }
}

export const getAgentSuccess = (response) => {
    return {
        type: TroubleShootConstants.GET_SUCCESS,
        payload: response
    }
}

export const getAgentFailure = error => {
    return {
        type: TroubleShootConstants.GET_FAILURE,
        payload: error
    }
}

export const getCheckAgentStatus = () => async dispatch => {
    dispatch(getAgentRequest());
    const response = await posAgentService.checkAgentStatus();
    if (response) {
        dispatch(getAgentSuccess(response));
    }
    else {
        dispatch(getAgentFailure());
    }

    return response;
}

export const logs = (screen, event, action, uiConfiguration) => {
    if (uiConfiguration?.isPosUILogEnabled){
        posAgentService.posUILog(screen, event, action);
    }
}