import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setHeader } from '../../pos-redux/header/header.actions';
import { headerConstants } from '../../pos-redux/header/header.constants';
import { setRegisterProduct } from '../../pos-redux/registerProduct/registerProduct.actions';
import { RegisterProductConstants } from '../../pos-redux/registerProduct/registerProduct.constants';
import "./productConfirmation.component.scss";
import { Button } from 'devextreme-react/button';
import { Popup } from 'devextreme-react/popup';
import LazyImage from 'react-lazy-blur-image';
import { preLoadImagePath } from '../../helpers/images';
import { setPayment } from '../../pos-redux/payment/payment.actions';
import { PaymentConstants } from '../../pos-redux/payment/payment.constants';
import { ButtonName, ScreenName } from '../../helpers/pos-message';
import { logs } from '../../pos-redux/troubleshoot/troubleshoot.actions';

function ProductConfirmationComponent() {
    const uiConfiguration = useSelector(state => state.uiConfiguration);

    const dispatch = useDispatch();

    const clickRegisterMoreItems = () => {
        logs(ScreenName.ProductConfirmationComponent , ButtonName.RegisterMoreItems, RegisterProductConstants.ADD_PRODUCT, uiConfiguration);
        dispatch(setRegisterProduct(RegisterProductConstants.ADD_PRODUCT, true));
    }
    const clickFinished = () => {
        logs(ScreenName.ProductConfirmationComponent , ButtonName.Finished, "Set Header and SetPayment", uiConfiguration);
        if (uiConfiguration.skipBagSelector) {
            dispatch(setHeader(headerConstants.BETAL, true));
            dispatch(setPayment(PaymentConstants.PAYMENT_METHOD, true));
        } else {
            dispatch(setHeader(headerConstants.VELGPOSE, true));
        }
    }

    return (
        <Popup
            width="95%"
            height={640}
            showTitle={false}
            dragEnabled={false}
            container="#pos"
            visible={true}
        >
            <div id="product-confirm-popUp" className="text-center inner-section-height tab-content d-flex align-items-center justify-content-lg-center flex-column">
                <LazyImage
                    transitionTime={50}
                    placeholder={`${preLoadImagePath.default_image_150}`}
                    uri={`${preLoadImagePath.black_cart}`}
                    render={(src, style) => <img className="img-150" src={src} alt="img" />}
                />
                <h2 className="popUptext mt-1">Sjekk at du har registrert alle varene dine</h2>

                <div className="d-flex py-4 flex-column flex-lg-row place-Center">
                    <Button
                        stylingMode="contained"
                        className="btn btn-primary me-lg-5 mb-4 mb-lg-0 cursor-hand btn-icon btn-font-xs" onClick={clickRegisterMoreItems}>
                        <LazyImage
                            placeholder={`${preLoadImagePath.default_image_55}`}
                            uri={`${preLoadImagePath.cart_black}`}
                            render={(src, style) => <img className="w-30 me-3 img-50" src={src} alt="img" />}
                        /> {ButtonName.RegisterMoreItems}
                    </Button>
                    <Button
                        type="success"
                        stylingMode="contained"
                        className="btn btn-primary cursor-hand btn-icon btn-font-xs" onClick={clickFinished}>
                        <LazyImage
                            placeholder={`${preLoadImagePath.default_image_55}`}
                            uri={`${preLoadImagePath.check}`}
                            render={(src, style) => <img src={src} alt="img" className="w-30 me-3 img-45" />}
                        />{ButtonName.Finished}
                    </Button>
                </div>
            </div>
        </Popup>
    );
}

export { ProductConfirmationComponent }