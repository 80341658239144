import React from "react";
import { GreetComponent } from "../../components/greet/greet.component";

function GreetPage() {
    return (
        <div>
            <GreetComponent />
        </div>
    );
}

export { GreetPage };
