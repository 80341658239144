
import { useDispatch, useSelector } from 'react-redux';
import './callForOperations.component.scss';
import { setRegisterProduct } from '../../pos-redux/registerProduct/registerProduct.actions';
import { RegisterProductConstants } from '../../pos-redux/registerProduct/registerProduct.constants';
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import { useState } from 'react';
import { ProductConstants } from '../../pos-redux/registerProduct/product.constants';
import { useEffect } from 'react';
import { getEmployeeDetailByNumber } from '../../pos-redux/employee/employee.actions';
import { Button, LoadPanel } from 'devextreme-react';
import BarcodeReader from 'react-barcode-reader'
import { preLoadImagePath } from '../../helpers/images';
import LazyImage from 'react-lazy-blur-image';
import { ButtonName, posMessage, ScreenName } from '../../helpers/pos-message';
import { logs } from '../../pos-redux/troubleshoot/troubleshoot.actions';

function CallForOperationsComponent() {
    const [visiblePopup, setVisiblePopup] = useState(true);
    const dispatch = useDispatch();

    const [uiConfiguration, selectedProduct, employeeDetail, stateOrder] = useSelector(state => [state.uiConfiguration
        , state.productWithQty
        , state.employeeDetail
        , state.product]);
    const exceedLimitMessageTxt = uiConfiguration.maximumProductAmountLimitMessage;
    const exceedLimitProduct = uiConfiguration.maximumProductCountLimitMessage;

    useEffect(() => {
        //handleBarcodeScan(13018);
    }, []);

    const clickCallForOpertions = () => {
        logs(ScreenName.CallForOperationsComponent, ButtonName.CallForService, RegisterProductConstants.ADD_PRODUCT, uiConfiguration);
        dispatch(setRegisterProduct(RegisterProductConstants.ADD_PRODUCT, true));
    }

    const showAmountLimit = () => {
        if (stateOrder.OrderPos && stateOrder.OrderPos.order.orderTotal >= uiConfiguration.maximumProductAmountLimit) {
            return (<h2 className="new-line my-4 my-lg-4">{exceedLimitMessageTxt.split('\n').map((txt) => <span><br />{txt}</span>)}</h2>);
        }
    }

    const showProductCountLimit = () => {
        if (stateOrder.OrderPos && stateOrder.OrderPos.totalQuantityPOSOrderLines >= uiConfiguration.maximumProductCountLimit && stateOrder.OrderPos.order.orderTotal < uiConfiguration.maximumProductAmountLimit) {
            return (<h2 className="line-break my-4 my-lg-4">{exceedLimitProduct.split('\n').map((txt) => <span><br />{txt}</span>)}</h2>);
        }
    }

    const closePopup = () => {
        setVisiblePopup(false);
        dispatch(setRegisterProduct(ProductConstants.ADD_PRODUCT, true));
    }

    const handleBarcodeScan = async (val) => {
        if (!employeeDetail.validCard) {
            logs(ScreenName.CallForOperationsComponent, "Barcode is Scan", val, uiConfiguration);
            dispatch(getEmployeeDetailByNumber(val));
        }
    }

    const handleBarcodeError = (err) => {
        dispatch(setRegisterProduct(RegisterProductConstants.BARCODE_FAILURE, true));
    }

    return (
        <Popup
            visible={visiblePopup}
            onHiding={closePopup}
            height='auto'
            showCloseButton={false}
            showTitle={false}
            container="#pos"
        >
            <LoadPanel
                message={posMessage.loading}
                shadingColor="rgba(0,0,0,0.4)"
                visible={employeeDetail.loading}
                showIndicator={true}
            />
            <div id="orderLimitPopup" className="text-center inner-section-height tab-content d-flex align-items-center justify-content-lg-center flex-column">
                <div class="text-center">
                    {showAmountLimit()}
                    <br />
                    {uiConfiguration.maximumProductCountLimit === 0 ? '' : showProductCountLimit()}
                </div>
                <div className="d-flex flex-lg-row text-center">
                    <Button
                        stylingMode="contained"
                        className="btn btn-primary cursor-hand btn-icon btn-font-xs"
                        onClick={clickCallForOpertions}>
                        <LazyImage
                            placeholder={`${preLoadImagePath.default_image_55}`}
                            uri={`${preLoadImagePath.speaker}`}
                            render={(src, style) => <img className="w-30 me-4 img-46" src={src} alt="img" />}
                        />
                        {ButtonName.CallForService}
                    </Button>
                </div>
            </div>
            <BarcodeReader
                onError={handleBarcodeError}
                onScan={handleBarcodeScan}
                minLength={1}
            />
        </Popup >

    );
}

export { CallForOperationsComponent }