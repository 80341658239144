import './addProductWithQty.component.scss';
import { Button } from 'devextreme-react/button';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { decreaseSelectedProductUsedQty, increaseSelectedProductUsedQty } from '../../pos-redux/registerProduct/addProductWithQty.actions';
import { addProductToCart, maxLimitExceeded, customerClubMemberCheck, customerRfidCheck } from '../../pos-redux/registerProduct/product.actions';
import { setRegisterProduct, setReload, setRegisterProductWithInformation } from '../../pos-redux/registerProduct/registerProduct.actions';
import { ProductConstants } from '../../pos-redux/registerProduct/product.constants';
import { RegisterProductConstants } from '../../pos-redux/registerProduct/registerProduct.constants';
import { currencyOptions } from '../../helpers/helper';
import { isMobile } from 'react-device-detect';
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import { useState } from 'react';
import { productGroupService } from '../../services/product-group.service';
import LazyImage from 'react-lazy-blur-image';
import { LoadIndicator, ScrollView } from 'devextreme-react';
import { ButtonName, posMessage, ScreenName } from '../../helpers/pos-message';
import { setError, setWarning } from '../../pos-redux/errorHandler/errorHandler.actions';
import { preLoadImagePath } from '../../helpers/images';
import { LoadPanel } from 'devextreme-react';
import { showUILoader } from '../../pos-redux/commonUI/commonUI.actions';
import { logs } from '../../pos-redux/troubleshoot/troubleshoot.actions';
import { CustomerAccountType } from '../../pos-redux/customer/customer.constant';
import { productWeightScaling } from '../../pos-redux/troubleshoot/weightScaling/weightScaling.actions';
import { isSpecialProduct, priceFormat, ellipsisText } from '../../helpers/helper';

const AddProductWithQty = () => {
    const [selectedProduct, stateOrder, uiConfiguration, employeeDetail, commonUI, memberStartState, customerDetail] = useSelector(state =>
        [state.productWithQty
            , state.product
            , state.uiConfiguration
            , state.employeeDetail
            , state.commonUIReducer
            , state.start
            , state.customerDetail]);

    const [productImg, setProductImg] = useState(null);
    const [visiblePopup, setVisiblePopup] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        fetchImage();
    }, []);

    const fetchImage = async () => {
        const imgResponse = await productGroupService.getProductImage(selectedProduct.data.product.id);
        if (imgResponse.length > 0) {
            setProductImg(imgResponse[0]);
        } else {
            setProductImg(false);
        }
    }

    const addProduct = async () => {
        logs(ScreenName.AddProductWithQty, ButtonName.Accept, "Add product DetailID: " + selectedProduct.data.product.productDetailId + "with Quantity: " + selectedProduct.data.product.usedQuantity, uiConfiguration);
        dispatch(showUILoader(true));
        if (uiConfiguration.allowCustomerCredit === CustomerAccountType.Activated)
            var customer = customerRfidCheck(customerDetail, stateOrder);
        else
            var ccMember = customerClubMemberCheck(memberStartState, stateOrder);

         
        if (isSpecialProduct(selectedProduct.data.product)) {
            var productResponse = await dispatch(productWeightScaling());
            if (productResponse) {
                selectedProduct.data.product.usedQuantity = productResponse.Amount;
            } else {
                dispatch(showUILoader(false));
                setError('Vennligst legg varen din på vekten.');
                return;
            }
        }

        var response = await dispatch(addProductToCart(selectedProduct.data.product, uiConfiguration, employeeDetail, stateOrder, ccMember, customer));

        if (response === false) {
            dispatch(showUILoader(false));
            setError(RegisterProductConstants.NO_ITEM_FOUND);
            return;
        }
        if (response === RegisterProductConstants.CALL_FOR_OPERATIONS) {
            dispatch(showUILoader(false));
            dispatch(setRegisterProduct(RegisterProductConstants.CALL_FOR_OPERATIONS, true));
            return;
        }
        if (response === RegisterProductConstants.STRUCTURE_PRODUCT_POPUP) {
            var type = { isStructure: true };
            dispatch(showUILoader(false));
            dispatch(setRegisterProductWithInformation(RegisterProductConstants.STRUCTURE_PRODUCT_POPUP, true, type));
            return;
        }
        dispatch(showUILoader(false));

        if (uiConfiguration.stayInProductSelector) {
            setVisiblePopup(false);
            dispatch(setReload(false));
            dispatch(setRegisterProduct(RegisterProductConstants.ALL_PRODUCTS_COMPONENT, true));
        }
        else {
            dispatch(setRegisterProduct(ProductConstants.ADD_PRODUCT, true));
        }
    }

    const bindAntallUpdateButtons = () => {
        if (isSpecialProduct(selectedProduct.data.product)) {
            return <></>;
        } else {
            return (
                <div id="editProductQtyContainer" className="d-flex justify-content-between bagBoxes">
                    <div className="dx-button-mode-contained btn-primary dx-theme-border-color bagBox addSubtractSection p-0 d-flex justify-content-between mt-3">
                        <div className="cursor-hand ms-1" onClick={() => dispatch(decreaseSelectedProductUsedQty(selectedProduct.data.product))}>
                            <i className="icon dx-icon-minus"></i>
                        </div>
                        <div id="productStk" className="dx-theme-background-color addSubtractSection-value">
                            {selectedProduct.data.product.usedQuantity} {posMessage.stk}
                        </div>
                        <div className="cursor-hand" onClick={() => dispatch(increaseSelectedProductUsedQty(selectedProduct.data.product))}>
                            <i className="icon dx-icon-add"></i>
                        </div>
                    </div>
                </div>
            );
        }
    }

    const hidePopup = () => {
        setVisiblePopup(false);
        dispatch(setReload(false));
        if (!uiConfiguration.isProductButtonVisible) { // product button hide 
            dispatch(setRegisterProduct(RegisterProductConstants.ADD_PRODUCT, true));
            logs(ScreenName.AddProductWithQty, ButtonName.Cancel, "Back to " + RegisterProductConstants.ADD_PRODUCT, uiConfiguration);

        } else {
            dispatch(setRegisterProduct(RegisterProductConstants.ALL_PRODUCTS_COMPONENT, true));
            logs(ScreenName.AddProductWithQty, ButtonName.Cancel, "Back to " + RegisterProductConstants.ALL_PRODUCTS_COMPONENT, uiConfiguration);
        }
    }

    const headerText = () => {
        return isSpecialProduct(selectedProduct.data.product) ? 'Legg varen på vekten' : 'Velg antall';
    }

    const getUnitText = () => {
        if (isSpecialProduct(selectedProduct.data.product) && selectedProduct.data.product.unitText) {
            return '/' + ellipsisText(selectedProduct.data.product.unitText);
        }
    }

    return (
        <div id="productWithQtyContainer">
            <Popup
                visible={visiblePopup}
                onHiding={hidePopup}
                height='auto'
                width='1100px'
                showCloseButton={false}
                showTitle={false}
                container="#pos"
            >
                <LoadPanel
                    message={posMessage.loading}
                    shadingColor="rgba(0,0,0,0.4)"
                    visible={commonUI.loading}
                    showIndicator={true}
                />
                <ScrollView
                    disabled={!isMobile}
                    width='100%'
                    height='100%'
                >
                    <div className="tab-pane fade" id="addProductWithQty" role="tabpanel" aria-labelledby="nav-profile-tab">
                        <div className="container">
                            <div className="row">
                                <div className="d-flex h-100 justify-content-center align-items-center m-3">
                                    <h2>{headerText()}</h2>
                                </div>
                            </div>


                            <div className={isMobile ? 'row-fluid' : 'row'}>
                                {/* <div className="col-1"></div> */}

                                <div className={productImg == null || productImg == false ? "hide-content" : "col-6"}>
                                    <div id="productImg" style={{ height: '100%' }} className="d-flex justify-content-center align-items-center">
                                        {
                                            productImg == null
                                                ?
                                                <div className="justify-content-center align-items-center">
                                                    <LoadIndicator id="medium-indicator" height={40} width={40} visible={productImg == null ? true : false} />
                                                </div>
                                                :
                                                <LazyImage
                                                    transitionTime={50}
                                                    placeholder={`${preLoadImagePath.default_image_400}`}
                                                    uri={productImg}
                                                    render={(src, style) => <img src={src} style={style} />}
                                                />
                                        }
                                    </div>
                                </div>
                                <div className={productImg == null || productImg == false ? "col-12" : "col-6"}>
                                    <div id="nameContainer" className="d-flex h-100 justify-content-center align-items-center">
                                        <label id="productName">{selectedProduct.data.product.name}
                                            <br />
                                            <span className="dx-button-mode-outlined dx-button-success" id="productPrice">
                                                {priceFormat(selectedProduct.data.product.salesPrice) + ' ' + posMessage.kr}
                                            </span>
                                            <span className="dx-button-mode-outlined dx-button-success" id="productUnit">{getUnitText()} </span>
                                        </label>
                                    </div>

                                    <div id="productDetails" className="d-flex h-100 justify-content-center align-items-center">
                                        {bindAntallUpdateButtons()}
                                    </div>

                                    <div id="productDetails" className="my-3 d-flex h-100 justify-content-center align-items-center">
                                        <div className="d-flex flex-column flex-lg-row">
                                            <Button
                                                stylingMode="contained"
                                                className={isMobile ? "dx-datagrid-search-text btn btn-cancel-color cursor-hand btn-font-xs mb-2 mb-lg-0" : "dx-datagrid-search-text btn btn-cancel-color cursor-hand btn-icon btn-font-xs me-lg-5 mb-4 mb-lg-0"} onClick={hidePopup} >{ButtonName.Cancel}</Button>

                                            <Button
                                                type="success"
                                                className={isMobile ? "btn cursor-hand btn-font-xs" : "btn cursor-hand btn-icon btn-font-xs"} onClick={addProduct}>{ButtonName.Accept}</Button>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="col-1"></div> */}
                            </div>

                        </div>
                    </div>
                </ScrollView>
            </Popup>
        </div>
    );
}

export { AddProductWithQty }