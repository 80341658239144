function FooterComponent() {
    const footerTxt = "Kassasystem fra Touch Soft";

    return (
        <div className="footerbox">
            <p className="text-light">{footerTxt}</p>
        </div>
    );
}

function FooterWithTextComponent() {
    return (
        <div className="footerbox">
        </div>
    );
}

export { FooterComponent, FooterWithTextComponent };