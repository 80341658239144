import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setHeader } from '../../pos-redux/header/header.actions';
import { headerConstants } from '../../pos-redux/header/header.constants';
import { Button } from 'devextreme-react/button';
import { RegisterProductConstants } from '../../pos-redux/registerProduct/registerProduct.constants';
import { setRegisterProduct } from '../../pos-redux/registerProduct/registerProduct.actions';
import { Popup } from 'devextreme-react/popup';
import LazyImage from 'react-lazy-blur-image';
import { preLoadImagePath } from '../../helpers/images';
import './SelfServiceConfirmation.component.scss';
import { ButtonName, ScreenName } from '../../helpers/pos-message';
import { logs } from '../../pos-redux/troubleshoot/troubleshoot.actions';

function SelfServiceConfirmationComponent() {
    const dispatch = useDispatch();
    const uiConfiguration = useSelector(state => state.uiConfiguration);
    const selfServiceConfirmationTxt = uiConfiguration.selfServiceConfirmationHeader;

    useEffect(() => {
        if (uiConfiguration.skipConfirmGodtaPopup) {
            clickHandle();
        }
    }, []);


    const clickHandle = () => {
        logs(ScreenName.SelfServiceConfirmationComponent, ButtonName.Accept, "setRegisterProduct()", uiConfiguration);
        if (uiConfiguration.skipOrderViewFromConfirmationPopUp) {
            dispatch(setHeader(headerConstants.REGISTERVARER, true));
            if (uiConfiguration.rootAssortmentId > 0) {
                dispatch(setRegisterProduct(RegisterProductConstants.ASSORTMENT_COMPONETNT, true));
            } else {
                dispatch(setRegisterProduct(RegisterProductConstants.ADD_ITEM_GROUP, true));
            }
        } else {
            dispatch(setHeader(headerConstants.REGISTERVARER, true));
            dispatch(setRegisterProduct(RegisterProductConstants.ADD_PRODUCT, true));
        }
    }

    return (
        <Popup
            visible={true}
            width="95%"
            height={640}
            showTitle={false}
            dragEnabled={false}
            container="#pos"
        >
            <div id="self-service-confirm" className="text-center inner-section-height tab-content d-flex align-items-center justify-content-lg-center flex-column">
                <LazyImage
                    placeholder={`${preLoadImagePath.default_image_150}`}
                    uri={`${preLoadImagePath.basket}`}
                    className="width-150px"
                    render={(src, style) => <img className="img-150" src={src} alt="img" />}
                />
                <br />
                <h2 className="popUptext">{selfServiceConfirmationTxt}</h2>
                <br />
                <div className="text-center">
                    <div className="d-flex h-100 justify-content-center align-items-center mt-3">
                        <Button
                            type="success"
                            stylingMode="contained"
                            className="btn me-lg-5 mb-4 mb-lg-0 cursor-hand btn-icon" onClick={clickHandle}>
                            <LazyImage
                                placeholder={`${preLoadImagePath.default_image_55}`}
                                uri={`${preLoadImagePath.check}`}
                                render={(src, style) => <img src={src} alt="img" className="w-30 me-4 img-45" />}
                            />
                            {ButtonName.Accept}
                        </Button>
                    </div>
                </div>
            </div>

        </Popup>
    );
}

export { SelfServiceConfirmationComponent }