export const PaymentConstants = {
    PAYMENT_METHOD: 'PAYMENT_METHOD',
    BANK_TERMINAL: 'BANK_TERMINAL',
    BANK_TERMINAL_WAITING: 'BANK_TERMINAL_WAITING',
    PAYMENT_INVOICE: 'PAYMENT_INVOICE',
    REGISTER_VIPS: 'REGISTER_VIPS',
    VIPS_CONFIRMATION: 'VIPS_CONFIRMATION',
    SET_PAYMENT: 'SET_PAYMENT',
    PAYMENT_STATUS: 'PAYMENT_STATUS',
    INITIATE_PAYMENT: 'INITIATE_PAYMENT',
    INITIATE_VIPPS_PAYMENT: 'INITIATE_VIPPS_PAYMENT',
    COMPLETE_VIPPS_PAYMENT: 'COMPLETE_VIPPS_PAYMENT',
    INVOICE: 'INVOICE',
    VIPPS_MEMBER_POPUP: 'VIPPS_MEMBER_POPUP',
    POS_CONFIRM_ORDER: 'POS_CONFIRM_ORDER',
    GIFT_CARD_SCAN: 'GIFT_CARD_SCAN',
    POPUP_FAILURE: 'POPUP_FAILURE',
    GIFT_CARD_PAYMENT: 'GIFT_CARD_PAYMENT',
    GIFT_CARD : 'GIFT_CARD',
};