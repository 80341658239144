import { useDispatch, useSelector } from 'react-redux';
import { setNumber } from '../../pos-redux/base/numberPad.action';
import './numberPad.component.scss';
import { preLoadImagePath } from '../../helpers/images';

function NumberPad(props) {
    const numberpad = useSelector(state => state.numberPad);
    const dispatch = useDispatch();
    const maximumDigits = 2;
 
    function countCharacter(data) {
        return (data.number.match(/,/g) || []).length;
    }

    function clickNumber(number) {
        if (countCharacter(numberpad) >= 1 && number === ',') {
            return;
        }
        if (countCharacter(numberpad) > 0) {
            var result = numberpad.number.split(','); //111,251
            var value = result[1]; //251

            if (value) {
                if (maximumDigits <= value.length) {
                    return;
                }
            }
        }

        numberpad.number += number;
        dispatchValue();

        // if (numberpad.number.length == 8) {
        //     dispatch(setStart(startConstants.SELF_SERVICE_CONFIRMATION, true));
        // }
    }

    function removeLast() {
        if (numberpad.number.length > 0) {
            numberpad.number = numberpad.number.substring(0, numberpad.number.length - 1);
        }
        dispatchValue();
    }

    function dispatchValue() {
        dispatch(setNumber(numberpad.number));
    }

    return (
        <div className="keyPad dx-theme-background-color dx-theme-border-color">
            <div className="d-flex justify-content-around mb-3">
                <div className="numberBox dx-theme-background-color-as-text-color dx-theme-text-color-as-background-color" onClick={() => clickNumber(1)}>1</div>
                <div className="numberBox dx-theme-background-color-as-text-color dx-theme-text-color-as-background-color" onClick={() => clickNumber(2)}>2</div>
                <div className="numberBox dx-theme-background-color-as-text-color dx-theme-text-color-as-background-color" onClick={() => clickNumber(3)}>3</div>
            </div>
            <div className="d-flex justify-content-around mb-3">
                <div className="numberBox dx-theme-background-color-as-text-color dx-theme-text-color-as-background-color" onClick={() => clickNumber(4)}>4</div>
                <div className="numberBox dx-theme-background-color-as-text-color dx-theme-text-color-as-background-color" onClick={() => clickNumber(5)}>5</div>
                <div className="numberBox dx-theme-background-color-as-text-color dx-theme-text-color-as-background-color" onClick={() => clickNumber(6)}>6</div>
            </div>
            <div className="d-flex justify-content-around mb-3">
                <div className="numberBox dx-theme-background-color-as-text-color dx-theme-text-color-as-background-color" onClick={() => clickNumber(7)}>7</div>
                <div className="numberBox dx-theme-background-color-as-text-color dx-theme-text-color-as-background-color" onClick={() => clickNumber(8)}>8</div>
                <div className="numberBox dx-theme-background-color-as-text-color dx-theme-text-color-as-background-color" onClick={() => clickNumber(9)}>9</div>
            </div>
            <div className="d-flex justify-content-around align-items-center">
                {
                    props.screen === "gift-card"
                        ?
                        <div className="numberBox dx-theme-background-color-as-text-color dx-theme-text-color-as-background-color" onClick={() => clickNumber(',')}>,</div>
                        :
                        <div className="numberBox"> &nbsp; </div>
                }
                <div className="numberBox dx-theme-background-color-as-text-color dx-theme-text-color-as-background-color m-r-10" onClick={() => clickNumber(0)}>0</div>
                <div className="arrowCross dx-theme-background-color-as-text-color dx-theme-text-color-as-background-color" onClick={() => removeLast()}>
                    <img className="" src={`${preLoadImagePath.white_cross_top}`} alt="img" />
                </div>
            </div>
        </div>
    );
}

export { NumberPad }