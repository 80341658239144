import { apiProvider } from './api/utilities/provider';
import { ApiUrlConstants } from './api/apiUrl.constants';

export const productGroupService = {
    getProductMainGroup,
    getProductSubGroup,
    getProducts,
    getProductImages,
    getProductImage,
    getProductByBarcode,
    getAllSubGroups
};

function getProductMainGroup() {
    return apiProvider.fetch.getAll(ApiUrlConstants.RetailLink.ProductMainGroupsEndPoint);
}

function getProductSubGroup(mainGroupId) {
    return apiProvider.fetch.getSingle(ApiUrlConstants.RetailLink.ProductSubGroupsEndPoint, mainGroupId);
}

function getProducts(subGroupId, dataOptions) {
    dataOptions = apiProvider.objectToQueryString(dataOptions);
    return apiProvider.fetch.getSingle(ApiUrlConstants.RetailLink.ProductsEndPoint, "?productSubGroupId=" + subGroupId + "&" + dataOptions, true);
}

function getProductImages(productIds) {
    return apiProvider.fetch.getSingle(ApiUrlConstants.RetailLink.ProductImagesEndPoint, "?productIds=" + productIds, true);
}

function getProductImage(productId) {
    return apiProvider.fetch.getSingle(ApiUrlConstants.RetailLink.ProductImagesEndPoint, productId);
}

function getProductByBarcode(barcode) {
    return apiProvider.fetch.getSingle(ApiUrlConstants.RetailLink.ProductFromBarcodeEndPoint, "?barcode=" + barcode, true);
}

function getAllSubGroups() {
    return apiProvider.fetch.getAll(ApiUrlConstants.RetailLink.ProductAllSubGropupsEndPoint);
}