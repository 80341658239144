import { ApiUrlConstants } from '../services/api/apiUrl.constants'
import { apiProvider } from './api/utilities/provider';

export const posAgentService = {
    checkAgentStatus,
    posUILog,
    troublshootStausCheck,
    productWeightScaling
}

async function checkAgentStatus() {
    return await apiProvider.axios.getSingle(ApiUrlConstants.POSAgent.ServiceStatusEndPoint, {})
}

async function troublshootStausCheck() {
    return await apiProvider.axios.getSingle(ApiUrlConstants.POSAgent.TroubleShootCheckEndPoint, {});
}

async function posUILog(screen, event, action) {
    var logs = { 'Screen': screen, 'Event': "Clicked on " + event, 'Action': action };
    return await apiProvider.axios.post(ApiUrlConstants.POSAgent.PosUILog, logs)
}

async function productWeightScaling(requestDate) {
    return apiProvider.fetch.getSingle(ApiUrlConstants.POSAgent.ProductScaling, "?datetime=" + requestDate, true);
}