import { CustomerConstants } from "./customer.constant";

const initialState = {
    name: CustomerConstants.CUSTOMER_COMFIRMATION,
    actionType: '',
    loading: false,
    isActive: true,
    data: {},
    error: ''
};

function customerDetail(state = initialState, action) {
    switch (action.type) {
        case CustomerConstants.SET_START:
            const { name, isActive } = action.payload;
            return {
                ...state,
                name: name,
                isActive: isActive
            };
        case CustomerConstants.CUSTOMER_DETAIL_REQUEST:
            return {
                ...state,
                actionType: action.type,
                loading: true
            }

        case CustomerConstants.CUSTOMER_DETAIL_SUCCESS:
            return {
                actionType: action.type,
                loading: false,
                data: action.payload,
                error: ''
            }

        case CustomerConstants.CUSTOMER_DETAIL_ERROR:
            return {
                actionType: action.type,
                loading: false,
                data: [],
                error: action.payload
            }

        case CustomerConstants.INIT_CUSTOMER:
            return initialState;

        default:
            return state
    }
}

export { customerDetail };