import { ApiUrlConstants } from '../services/api/apiUrl.constants'
import { apiProvider } from './api/utilities/provider';
import { authentication } from './api/utilities/auth-header';
import { handleResponse } from './api/utilities/response';

var vippConfigDetail = null;

export const vippsPaymentService = {
    checkPendingTransaction,
    initiatePayment,
    checkPaymentDetail,
    cancelPayment,
    vippsConfig
};

async function setVippsConfig(request) {

    if (vippConfigDetail == null) {
        vippConfigDetail = await vippsConfig();
    }

    request.vippConfigDetail = vippConfigDetail;
    return request;

}

async function checkPendingTransaction(amount, mobileNumber) {
    let request =
    {
        amount: amount,
        mobileNumber: mobileNumber
    };

    request = await setVippsConfig(request);
    return await apiProvider.axios.postVipps(ApiUrlConstants.Vipps.ExistingTransactionEndPoint, request);
}

async function initiatePayment(amount, mobileNumber, transactionText, posNo, transactionModuleId) {
    let request =
    {
        amount: amount,
        mobileNumber: mobileNumber,
        transactionText: transactionText,
        posNo: posNo,
        transactionModuleId: transactionModuleId
    };

    request = await setVippsConfig(request);

    return await apiProvider.axios.postVipps(ApiUrlConstants.Vipps.PaymentInitiateEndPoint, request);
}

async function checkPaymentDetail(orderId) {
    let request = {}
    request = await setVippsConfig(request);

    return await apiProvider.axios.postVipps(ApiUrlConstants.Vipps.PaymentDetailEndPoint + orderId, request)
}

async function cancelPayment(orderId, transactionText) {
    let request = {
        transactionText: transactionText
    }

    request = await setVippsConfig(request);

    return await apiProvider.axios.postVipps(ApiUrlConstants.Vipps.PaymentCancelEndPoint + orderId, request);
}


async function vippsConfig() {
    var authHeader = await authentication.authenticationHeader();

    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader, 'Content-Type': 'application/json' }
    };

    return fetch(ApiUrlConstants.RetailLink.VIPPSConfigurationEndPoint, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user;
        });
}

