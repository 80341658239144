
import { useDispatch } from 'react-redux';
import { PaymentConstants } from '../../pos-redux/payment/payment.constants';
import { setPayment } from '../../pos-redux/payment/payment.actions';
import './paymentInvoice.component.scss';
import { useHistory } from 'react-router-dom';
import { Button } from 'devextreme-react/button';
import { initVirtualKeyboard } from '../../../common/virtualkeyboard';
import { isMobile } from 'react-device-detect';
import { useEffect } from 'react';
import { preLoadImagePath } from '../../helpers/images';
import { useSelector } from 'react-redux';
import { orderPosService } from '../../services/orderPos.service';
import { receiptService } from '../../services/receipt.service';
import SignatureCanvas from 'react-signature-canvas'
import { ButtonName, ScreenName } from '../../helpers/pos-message';
import { logs } from '../../pos-redux/troubleshoot/troubleshoot.actions';
import { setWarning, toastCenterPosition, setError } from '../../pos-redux/errorHandler/errorHandler.actions';
import { posMessage } from '../../helpers/pos-message';
import { showUILoader } from '../../pos-redux/commonUI/commonUI.actions';
import { waitingTimer } from '../../pos-redux/payment/terminal/terminal.actions';
import { setPOSOrder } from '../../pos-redux/payment/payment.actions';
import { LoadPanel } from 'devextreme-react';
import { withDrawalGiftCardStatusSuccess } from '../../pos-redux/payment/giftCard/giftCard.actions';

function PaymentInvoice() {
    const [stateMembers, stateOrder, employeeDetail, customerDetail, uiConfiguration, commonUI, stateGiftCard] = useSelector(state => [state.start, state.product, state.employeeDetail, state.customerDetail, state.uiConfiguration, state.commonUIReducer, state.giftCardPayment]);
    const dispatch = useDispatch();
    const history = useHistory();
    const activeInputs = [];
    const defaultImage = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC";
    var keybaordObj;
    var sigPad = {}
    var invoiceOrderInfo = {};


    useEffect(() => {
        if (customerDetail.data.id) {
            orderPosAndReceipt();
        }
    }, [])


    const orderPosAndReceipt = async () => {
        dispatch(showUILoader(true));
        await waitingTimer(2000);

        if (customerDetail.data && customerDetail.data.id) {
            logs(ScreenName.PaymentInvoiceComponent, ButtonName.Ansattkonto, "CustomerInvoice", uiConfiguration);
            createOrder(stateOrder.OrderPos);
        }
    }

    const clickOk = () => {
        logs(ScreenName.PaymentInvoiceComponent, ButtonName.Ok, "", uiConfiguration);
        //TODO: customer is mandory for invoice.
        // if (Object.keys(stateMembers.data).length > 0) {
        //     stateOrder.OrderPos.order.customerNo = stateMembers.data.customerNo;
        //     var data = sigPad.getTrimmedCanvas().toDataURL('image/png');
        //     if (invoiceOrderInfo != null) {
        //         if (defaultImage !== data) { // There is draw in signature pad, then it include
        //             invoiceOrderInfo = {
        //                 ...invoiceOrderInfo,
        //                 userSignature: data
        //             }
        //         }
        //         stateOrder.OrderPos.order.jsonData = JSON.stringify(invoiceOrderInfo);
        //         createOrder(stateOrder.OrderPos);
        //     }
        // } else {
        //     history.push("/greet");
        // }
        //downloadBase64File(data, new Date());       

    }

    function downloadBase64File(base64Data, fileName) {
        const linkSource = base64Data;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }

    // const createOrder = async (order) => {
    //     var responseOrder = await orderPosService.orderPOS(order, null, PaymentConstants.INVOICE, employeeDetail);
    //     if (responseOrder) {
    //         dispatch(showUILoader(false));
    //         var type = { posOrder: responseOrder };
    //         dispatch(setPOSOrder(PaymentConstants.POS_CONFIRM_ORDER, true, type));
    //         history.push("/greet");
    //     }
    // }

    const createOrder = async (order) => {
        var ledgerPayments = stateGiftCard?.paymentTransactionModel?.registeredLedgerPayments || [];

        var responseOrder = await orderPosService.orderPOS(order, null, PaymentConstants.INVOICE, employeeDetail, ledgerPayments);
        if (responseOrder) {
            dispatch(showUILoader(false));            
            if (responseOrder.paymentTransactionModel != null) {
                let errorMessage = responseOrder.paymentTransactionModel.errorMessage;
                if (responseOrder.paymentTransactionModel.remainingAmount > 0 || errorMessage) {
                    setWarning(errorMessage, toastCenterPosition);
                    dispatch(withDrawalGiftCardStatusSuccess(responseOrder.paymentTransactionModel));
                    dispatch(setPayment(PaymentConstants.PAYMENT_METHOD, true));
                    return;
                }
            }
            var type = { posOrder: responseOrder };
            dispatch(setPOSOrder(PaymentConstants.POS_CONFIRM_ORDER, true, type));
            history.push("/greet");
        }
    }

    const clickCancel = () => {
        logs(ScreenName.PaymentInvoiceComponent, ButtonName.Cancel, "setPayment", uiConfiguration);
        dispatch(setPayment(PaymentConstants.PAYMENT_METHOD, true));
    }

    const clickClear = () => {
        logs(ScreenName.PaymentInvoiceComponent, ButtonName.Empty, "sigPad.clear", uiConfiguration);
        sigPad.clear();
        if (invoiceOrderInfo) {
            delete invoiceOrderInfo['userSignature'];
        }
    }

    const getInputValue = (event) => {
        var values = event.target.value;
        var keyName = event.target.name;
        if (keyName === 'userName') {
            invoiceOrderInfo = {
                ...invoiceOrderInfo,
                userName: values
            };
        }

        if (keyName === 'referenceNo') {
            invoiceOrderInfo = {
                ...invoiceOrderInfo,
                referenceNo: values
            };
        }
    };

    const renderCustomer = () => {
        return (
            <div className="text-center">
                <h2>Vennligst vent... Vi behandler forespørselen</h2>
                <LoadPanel
                    message={posMessage.loading}
                    shadingColor="rgba(0,0,0,0.4)"
                    visible={commonUI.loading}
                    showIndicator={true}
                />
            </div>
        )
    }

    const renderSignPad = () => {
        return (<div id="innerSectionInvoice" className="text-center  dx-theme-generic-typography">
            <h2 className="dx-font-m dx-font-xs">Trykk i aktuelle felt og skriv inn din informasjon.</h2>
            <div className="my-2 pt-4 keyPadWrapper">
                <div className="inputGroupWrapper d-flex justify-content-center me-0">
                    <div className="inputGroup">
                        <img className="" src={`${preLoadImagePath.user_black}`} alt="img" />
                        <input id="dittNavn" name="userName" placeholder="Ditt navn" className="dx-theme-background-color inputText dx-theme-border-color" type="text" onChange={getInputValue} />
                    </div>
                </div>

                <div className="inputGroupWrapper d-flex justify-content-center me-0 mt-2">
                    <div className="inputGroup">
                        <img className="" src={`${preLoadImagePath.avdeling}`} alt="img" />
                        <input id="referanse" name="referenceNo" placeholder="Referanse" className="dx-theme-background-color inputText dx-theme-border-color" type="text" onChange={getInputValue} />
                    </div>
                </div>

                {/* <textarea placeholder="Skriv signatur med figeren (Obligatorisk)" className="mt-2 dx-theme-background-color inputText textArea 
                    dx-theme-border-color" name="" id="txtArea"></textarea> */}

                {!isMobile && !customerDetail.data.id ?
                    <div className="canvas-background mt-2">
                        <h2 className="dx-font-m dx-font-xs textarea">Skriv signatur med fingeren</h2>
                        <SignatureCanvas penColor='green'
                            ref={(ref) => { sigPad = ref }}
                            canvasProps={{ width: (window.innerWidth - 100), height: 280, className: 'sigCanvas' }} />

                        <span className="d-flex py-2 flex-lg-row place-End">
                            <Button
                                stylingMode="contained"
                                className="btn btn-primary cursor-hand btn-icon justify-content-center mx-3" onClick={clickClear}> {ButtonName.Empty}</Button>
                        </span>
                    </div>
                    : <></>
                }
                <div className="d-flex py-4 flex-column flex-lg-row place-Center">
                    <Button
                        stylingMode="contained"
                        className="dx-datagrid-search-text btn btn-cancel-color btn cursor-hand btn-icon me-lg-5 mb-4 mb-lg-0" onClick={clickCancel}> {ButtonName.Cancel}</Button>

                    <Button
                        type="success"
                        width={'9%'}
                        stylingMode="contained"
                        className="btn cursor-hand btn-icon justify-content-center" onClick={clickOk}> {ButtonName.Ok}</Button>

                </div>
            </div>
        </div>);
    }

    return (
        <div id="mainSectioInvoice" className="d-flex align-items-center justify-content-center flex-column">
            <div className="inner-section-height d-flex align-items-center justify-content-center">
                {!customerDetail.data.id ? renderSignPad() : renderCustomer()}
            </div>
        </div>
    );
}

export { PaymentInvoice }