import { useDispatch, useSelector } from 'react-redux';
import { startConstants } from '../../pos-redux/start/start.constants';
import { setStart } from '../../pos-redux/start/start.actions';
import './addCustomerNumber.component.scss';
import { Button } from 'devextreme-react/button';
import { NumberPad } from '../base/numberPad.component';
import { setNumber } from '../../pos-redux/base/numberPad.action';
import { useEffect } from 'react';
import { setWarning } from '../../pos-redux/errorHandler/errorHandler.actions';
import { ButtonName, posMessage, ScreenName } from '../../helpers/pos-message';
import { preLoadImagePath } from '../../helpers/images';
import { getCorporateCustomerMember, getCorporateCustomerMemberByScan } from '../../pos-redux/start/start.actions';
import BarcodeReader from 'react-barcode-reader'
import { logs } from '../../pos-redux/troubleshoot/troubleshoot.actions';

function CorporateCustomerComponent() {
    const dispatch = useDispatch();
    const numberpad = useSelector(state => state.numberPad);
    const uiConfiguration = useSelector(state => state.uiConfiguration);
    const addCustomerNumberTxt = uiConfiguration.addCustomerNumberHeader;

    useEffect(() => {
        dispatch(setNumber(''));
        //handleBarcodeScan("365");
    }, []);

    const clickOk = () => {
        logs(ScreenName.CorporateCustomerComponent, ButtonName.Ok, "getCorporateCustomerMember function with " + numberpad.number, uiConfiguration);
        if (numberpad.number.length == 8) {
            dispatch(getCorporateCustomerMember(numberpad.number));
        }
        else {
            setWarning(posMessage.corporateCustomerMessage);
        }
    }

    const clickNo = () => {
        logs(ScreenName.CorporateCustomerComponent, ButtonName.Cancel, startConstants.MEMBER_COMFIRMATION, uiConfiguration)
        dispatch(setStart(startConstants.MEMBER_COMFIRMATION, true));
    }

    const numberChange = (val) => {
        numberpad.number = val;
        dispatch(setNumber(numberpad.number));

        if (numberpad.number.length == 8) {
           dispatch(getCorporateCustomerMember(numberpad.number));
        }
    }

    const handleBarcodeError = (err) => {

    }

    const handleBarcodeScan = async (val) => {
        if (val != null) {
            dispatch(getCorporateCustomerMemberByScan(val));
        }
    }

    return (

        <div id="corporateCustomerComponent" class="inner-section-height d-flex align-items-center justify-content-center">
            <div class="text-center max-50 dx-theme-generic-typography">
                <h2 className="dx-font-m dx-font-xs new-line">{addCustomerNumberTxt}</h2>
                <div className="my-4 pt-4 d-flex keyPadWrapper">
                    <div className="inputGroupWrapper">
                        <div className="inputGroup">
                            <img className="" src={`${preLoadImagePath.user_black_customer}`} alt="img" />
                            <input ref={(input) => { input && input.focus() }} placeholder="Kundenummer" className="dx-theme-background-color inputText dx-theme-border-color" type="text" value={numberpad.number} onChange={e => numberChange(e.target.value)} />
                        </div>
                        <div className="keyPadLeftSidebutton flex-column ps-lg-3 ps-lg-4">

                            <Button
                                type="success"
                                stylingMode="contained"
                                className="btn btn-primary mx-auto px-5 cursor-hand justify-content-center btn-icon" onClick={clickOk}> {ButtonName.Ok}</Button>

                            <Button
                                className="dx-datagrid-search-text btn btn-cancel-color mx-auto px-5 mt-3 cursor-hand justify-content-center btn-icon"
                                onClick={clickNo}> {ButtonName.Cancel}</Button>
                        </div>
                    </div>
                    <NumberPad screen="customerNumber"></NumberPad>
                </div>
                <BarcodeReader
                    onError={handleBarcodeError}
                    onScan={handleBarcodeScan}
                    minLength={1}
                />
            </div>
        </div>
    );
}

export { CorporateCustomerComponent }