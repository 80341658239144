export const TerminalConstants = {
    //#region  Action
    TERMINAL_INITIATE_PAYMENT_REQUEST: 'TERMINAL_INITIATE_PAYMENT_REQUEST',
    TERMINAL_INITIATE_PAYMENT_SUCCESS: 'TERMINAL_INITIATE_PAYMENT_SUCCESS',
    TERMINAL_INITIATE_PAYMENT_ERROR: 'TERMINAL_INITIATE_PAYMENT_ERROR',
    TERMINAL_PAYMENT_STATUS_REQUEST:'TERMINAL_PAYMENT_STATUS_REQUEST',
    TERMINAL_PAYMENT_STATUS_SUCCESS:'TERMINAL_PAYMENT_STATUS_SUCCESS',
    TERMINAL_PAYMENT_STATUS_ERROR:'TERMINAL_PAYMENT_STATUS_ERROR',
    INIT_TERMINAL : 'INIT_TERMINAL',
    //#endregion

    TerminalPaymentStatus: {
        TransaksjonOK : 0
    },
}