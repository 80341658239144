import { ApiUrlConstants } from './api/apiUrl.constants'
import { apiProvider } from './api/utilities/provider';

export const giftCardService = {
    getGiftCardNumber,
    withdrawal
};

function getGiftCardNumber(cardNo) {
    return apiProvider.fetch.getSingle(ApiUrlConstants.RetailLink.GiftCardEndpoint, cardNo);
}

function withdrawal(giftWithdrwal) {
    return apiProvider.fetch.post(ApiUrlConstants.RetailLink.GiftCardWithDrawalEndpoint, giftWithdrwal);
}