import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setHeader } from '../../pos-redux/header/header.actions';
import { headerConstants } from '../../pos-redux/header/header.constants';
import { Button } from 'devextreme-react/button';
import { RegisterProductConstants } from '../../pos-redux/registerProduct/registerProduct.constants';
import { setRegisterProduct } from '../../pos-redux/registerProduct/registerProduct.actions';
import { Popup } from 'devextreme-react/popup';
import LazyImage from 'react-lazy-blur-image';
import { preLoadImagePath } from '../../helpers/images';
import './memberRegisterPopup.component.scss';
import { startConstants } from '../../pos-redux/start/start.constants';
import { setStart } from '../../pos-redux/start/start.actions';
import { isMobile } from 'react-device-detect';
import { ButtonName, ScreenName } from '../../helpers/pos-message';
import { logs } from '../../pos-redux/troubleshoot/troubleshoot.actions';

function MemberCommonPopUpComponent() {
    const dispatch = useDispatch();
    const start = useSelector(state => state.start);
    const numberpad = useSelector(state => state.numberPad);

    const headerCustomerClubScanTxt = 'Bankkortet ditt er ikke registrert i kundeklubben. Du kan registrere det i kassen.';
    const hederCustomerClubScanConfirmTxt = 'Ønsker du å skrive inn ditt mobilnummer for å registrere kjøpet?';

    const headerCorporateCustomerTxt = 'Kundenummer {custNo} er ikke registrert.';
    const hederCorporateCustomerConfirmTxt = 'Ønsker du å skrive inn kundenummeret på nytt? '

    const headerCorporateScanTxt = 'Skanning av kundekort feilet.';
    const headerCorporateScanConfirmTxt = 'Ønsker du å skrive inn ditt kundenummer for å registrere kjøpet?';

    const clickOK = () => {
        logs(ScreenName.MemberCommonPopUpComponent , ButtonName.Ok, startConstants.ADD_PHONE_NUMBER);
        if (start.actionType === startConstants.CUSTOMERCLUB_MEMBER) {
            dispatch(setStart(startConstants.ADD_PHONE_NUMBER, true));
        }

        if (start.actionType === startConstants.CORPORATE_CUSTOMER_MEMBER) {
            dispatch(setStart(startConstants.ADD_CUSTOMER_NUMBER, true));
        }
    }

    const clickNo = () => {
        logs(ScreenName.MemberCommonPopUpComponent , ButtonName.No, startConstants.SELF_SERVICE_CONFIRMATION);
        dispatch(setStart(startConstants.SELF_SERVICE_CONFIRMATION, true));
    }

    const clickCallForOpertions = () => {
        logs(ScreenName.MemberCommonPopUpComponent , ButtonName.CallForService, RegisterProductConstants.CALL_FOR_HELP);
        dispatch(setHeader(headerConstants.REGISTERVARER, true));
        dispatch(setRegisterProduct(RegisterProductConstants.CALL_FOR_HELP, true));
    }

    const renderCustomerClubScanPopUp = () => {
        return (
            <div id="member-popup" className="text-center inner-section-height tab-content d-flex align-items-center justify-content-lg-center flex-column">
                <h2 className="popUptext" >{headerCustomerClubScanTxt}</h2>
                <br />
                <h2 className="popUptext">{hederCustomerClubScanConfirmTxt}</h2>
                <br />
                {renderButtons()}
            </div>);
    }

    const renderCorporatePopUp = () => {
        return (
            <div id="member-popup" className="text-center inner-section-height tab-content d-flex align-items-center justify-content-lg-center flex-column">
                <h2 className="popUptext">{headerCorporateCustomerTxt.replace('{custNo}', numberpad.number).toString()}</h2>
                <br />
                <h2 className="popUptext">{hederCorporateCustomerConfirmTxt}</h2>
                <br />
                {renderButtons()}
            </div>);
    }

    const renderCorporateScanPopUp = () => {
        return (
            <div id="member-popup" className="text-center inner-section-height tab-content d-flex align-items-center justify-content-lg-center flex-column">
                <h2 className="popUptext">{headerCorporateScanTxt}</h2>
                <br />
                <h2 className="popUptext">{headerCorporateScanConfirmTxt}</h2>
                <br />
                {renderButtons()}
            </div>);
    }

    const renderButtons = () => {
        return (<div className={isMobile ? "flex-column ps-lg-3 ps-lg-4" : "d-flex flex-lg-row place-Center justify-content-center mt-3 pt-4"}>
            <Button
                type="success"
                stylingMode="contained"
                className="mt-2 me-lg-5 mb-3 btn btn-primary cursor-hand btn-font-xs" onClick={clickNo}>
                {ButtonName.No}
        </Button>
            <Button
                stylingMode="contained"
                className="me-lg-5 mb-3 btn btn-primary cursor-hand btn-icon btn-font-xs"
                onClick={clickCallForOpertions}>
                <LazyImage
                    placeholder={`${preLoadImagePath.default_image_55}`}
                    uri={`${preLoadImagePath.speaker}`}
                    render={(src, style) => <img className="w-50 me-2 me-xl-4 img-50" src={src} alt="img" />}
                />
                {ButtonName.CallForService}
            </Button>
            <Button
                type="success"
                stylingMode="contained"
                className="me-lg-5 mb-3 btn btn-primary cursor-hand btn-font-xs" onClick={clickOK} >
                {ButtonName.Yes}
            </Button>
        </div>);
    }

    return (
        <Popup
            visible={true}
            width="95%"
            height={640}
            showTitle={false}
            dragEnabled={false}
            container="#pos"
        >
            {
                start.actionType === startConstants.CUSTOMERCLUB_MEMBER && (start.data && start.data.customerScan)
                    ?
                    renderCustomerClubScanPopUp()
                    :
                    (start.actionType === startConstants.CORPORATE_CUSTOMER_MEMBER) && (start.data && start.data.corporateCustomerNumber)
                        ?
                        renderCorporatePopUp()
                        :
                        (start.actionType === startConstants.CORPORATE_CUSTOMER_MEMBER) && (start.data && start.data.customerScan)
                            ?
                            renderCorporateScanPopUp()
                            :
                            ''
            }
        </Popup>
    );
}

export { MemberCommonPopUpComponent }