import React from "react";
import { TroubleShootComponent } from "../../components/troubleshoot/troubleshoot.component";

function TroubleShootPage() {
    return (
        <div>
            <TroubleShootComponent />
        </div>
    );
}

export { TroubleShootPage };

