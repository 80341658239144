export const localStorageService = {
    getAuthToken,
    getEmail,
    setAuthToken,
    setEmail,
    setProductBags,
    getProductBags,
    clearStorage,
    getMainGroups,
    getSubGroups,
    setMainGroups,
    setSubGroups,
    setThemeProperty,
    getThemeProperty,
    getPOSConfiguration,
    setPOSConfiguration,
    getApiErrorCount,
    setApiErrorCount
};

function clearStorage() {
    localStorage.removeItem('authToken');
    localStorage.removeItem('email');
    localStorage.removeItem('maingroups');
    localStorage.removeItem('subgroups');
    localStorage.removeItem('themeProperties');
    localStorage.removeItem('posConfiguration');
    localStorage.removeItem('apiErrorCount');
    localStorage.clear();
}

function getAuthToken() {
    return localStorage.getItem('authToken');
}

function getEmail() {
    return localStorage.getItem('email');
}

function setAuthToken(authToken) {
    return localStorage.setItem('authToken', authToken);
}

function setEmail(email) {
    return localStorage.setItem('email', email);
}

function setProductBags(bags) {
    return localStorage.setItem('bags', JSON.stringify(bags));
}

function getProductBags() {
    return localStorage.getItem('bags');
}

function setMainGroups(items) {
    return localStorage.setItem('maingroups', JSON.stringify(items));
}

function getMainGroups() {
    var data = localStorage.getItem('maingroups');
    data = ValidateJSON(data);

    if (data === 'error') {
        localStorage.removeItem('maingroups');
        return undefined;
    } else {
        return data;
    }
}

function setSubGroups(items) {
    return localStorage.setItem('subgroups', JSON.stringify(items));
}

function getSubGroups() {
    var data = localStorage.getItem('subgroups');
    data = ValidateJSON(data);

    if (data === 'error') {
        localStorage.removeItem('subgroups');
        return undefined;
    } else {
        return data;
    }
}

function getThemeProperty() {
    return JSON.parse(localStorage.getItem('themeProperties'));
}

function setThemeProperty(items) {
    return localStorage.setItem('themeProperties', JSON.stringify(items));
}

function getPOSConfiguration() {
    var data = localStorage.getItem('posConfiguration');

    data = ValidateJSON(data);

    if (data === 'undefined') {
        localStorage.removeItem('posConfiguration');
        return undefined;
    } else {
        return data;
    }
}

function setPOSConfiguration(items) {
    return localStorage.setItem('posConfiguration', JSON.stringify(items));
}

function getApiErrorCount() {
    return localStorage.getItem('apiErrorCount');
}

function setApiErrorCount(items) {
    return localStorage.setItem('apiErrorCount', items);
}

function isNullOrEmpty(value) {
    return value === undefined || value === null || value === '';
}

function ValidateJSON(data) {
    if (isNullOrEmpty(data)) {
        return undefined;
    }

    try {
        if (typeof JSON.parse(data) === "object") {
            return JSON.parse(data);
        }
    } catch (e) {
        return 'error';
    }
}