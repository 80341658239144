import DataGrid, { Column, Selection } from 'devextreme-react/data-grid';
import './productEdit.component.scss';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import { currencyOptions } from '../../helpers/helper';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { deleteProduct, editProductQty, customerClubMemberCheck, customerRfidCheck } from '../../pos-redux/registerProduct/product.actions';
import { RegisterProductConstants } from '../../pos-redux/registerProduct/registerProduct.constants';
import { setRegisterProduct } from '../../pos-redux/registerProduct/registerProduct.actions';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { setWarning } from '../../pos-redux/errorHandler/errorHandler.actions';
import { Button } from 'devextreme-react';
import { ButtonName, posMessage, ScreenName, CartQtyEnum } from '../../helpers/pos-message';
import { preLoadImagePath } from '../../helpers/images';
import { LoadPanel } from 'devextreme-react';
import { showUILoader } from '../../pos-redux/commonUI/commonUI.actions';
import { logs } from '../../pos-redux/troubleshoot/troubleshoot.actions';
import { CustomerAccountType } from '../../pos-redux/customer/customer.constant';
import { isSpecialProduct, priceFormat } from '../../helpers/helper';

function ProductEditComponent() {
    const MaxStock = 10;
    const dispatch = useDispatch();
    const [quantity, setQuantity] = useState(0);
    const [visiblePopup, setVisiblePopup] = useState(true);
    const [productEditType, setProductEditType] = useState('');

    const [uiConfiguration, productSelector, stateOrder, bagsType, commonUI, employeeDetail, memberStartState, customerDetail] = useSelector(state => [state.uiConfiguration
        , state.RegisterProduct
        , state.product
        , state.selectBag
        , state.commonUIReducer
        , state.employeeDetail
        , state.start
        , state.customerDetail]);
    var product = [];

    if (productSelector.editProduct) {
        product.push(productSelector.editProduct);
    }

    useEffect(() => {
        setQuantity(productSelector.editProduct.amount);
    }, []);

    const onCellPrepared = (e) => {
        if (e.rowType === 'header') {
            e.cellElement.classList.add('dx-theme-accent-as-background-color');
        }
        if (e.rowType === 'totalFooter') {
            e.cellElement.classList.add('dx-theme-accent-as-background-color');
        }
    }

    const decreaseUsedQuantity = (product) => {
        logs(ScreenName.ProductEditComponent, "decreaseUsedQuantity: ", " - " + product.amount, uiConfiguration);
        if (product.amount - 1 > 0) {
            product.amount -= 1;
            setQuantity(product.amount);
            setProductEditType(CartQtyEnum.Minus);
        }
        else {
            setWarning(RegisterProductConstants.ITEM_CANNOT_BE_LESS_THAN_ONE);
        }
    };

    const increaseUsedQuantity = (product) => {
        logs(ScreenName.ProductEditComponent, "increaseUsedQuantity: ", " + " + product.amount, uiConfiguration);
        if (MaxStock >= product.amount + 1) {
            product.amount += 1;
            setQuantity(product.amount);
            setProductEditType(CartQtyEnum.Plus);
        }
        else {
            setWarning(`Sett inn mengde fra 1 og ${MaxStock}`);
        }
    }; 

    const isProductBarcode = (barCode) => {
        return barCode != null && barCode.length > 0;
    }

    const bindAntallUpdateButtons = (productRow) => {
        if (isSpecialProduct(productRow)) {
            return <div className='display-flex'>
                <span>  {priceFormat(quantity)}  </span>
                <span className="ellipsis-text-flow"> &nbsp; {(productRow.unitText)} </span>
            </div>;
        } else {
            return (
                <div id="updateProductQtyContainer" disabled={isProductBarcode(productRow.barcode)} className="d-flex justify-content-between bagBoxes">
                    <div className="dx-button-mode-contained btn-primary dx-theme-border-color bagBox addSubtractSection p-0 d-flex justify-content-between mt-3">
                        <div className="cursor-hand" onClick={() => decreaseUsedQuantity(productRow)}>
                            <i className="icon dx-icon-minus"></i>
                        </div>
                        <div id="productStk" className="dx-theme-background-color addSubtractSection-value">
                            {quantity} {'' + posMessage.stk}
                        </div>
                        <div className="cursor-hand" onClick={() => increaseUsedQuantity(productRow)}>
                            <i className="icon dx-icon-add"></i>
                        </div>
                    </div>
                </div>
            );
        }
    }

    const calculateBeløpCellValue = (data) => {
        return priceFormat(data.amount * data.unitPrice);
    };

    const deleteProductLine = async () => {
        logs(ScreenName.ProductEditComponent, ButtonName.RemoveItemLine, "Delete Product DetailID: " + productSelector.editProduct.productDetailID, uiConfiguration);
        if (bagsType.data) {
            var bagIndex = bagsType.data.findIndex(x => x.id === productSelector.editProduct.productDetailID);
            if (bagIndex > -1) {
                //reset bag with zero quantity
                bagsType.data[bagIndex].usedQuantity = 0;
            }
        }
        dispatch(showUILoader(true));
        if (uiConfiguration.allowCustomerCredit === CustomerAccountType.Activated)
            customerRfidCheck(customerDetail, stateOrder);
        else
            var ccMember = customerClubMemberCheck(memberStartState, stateOrder);
        await dispatch(deleteProduct(productSelector.editProduct, uiConfiguration, stateOrder, employeeDetail));
        dispatch(showUILoader(false));
        hidePopup();
    };

    const onClickOkay = async () => {
        logs(ScreenName.ProductEditComponent, ButtonName.Ok, "editProductQty(), customerClubMemberCheck()", uiConfiguration);
        dispatch(showUILoader(true));
        if (uiConfiguration.allowCustomerCredit === CustomerAccountType.Activated)
            customerRfidCheck(customerDetail, stateOrder);
        else
            var ccMember = customerClubMemberCheck(memberStartState, stateOrder);
        var res = await dispatch(editProductQty(productSelector.editProduct, uiConfiguration, stateOrder, employeeDetail, productEditType));
        dispatch(showUILoader(false));
        hidePopup();
    }

    const hidePopup = () => {
        logs(ScreenName.ProductEditComponent, ButtonName.Cancel, "Back to " + RegisterProductConstants.ADD_PRODUCT, uiConfiguration);
        setVisiblePopup(false);
        dispatch(setRegisterProduct(RegisterProductConstants.ADD_PRODUCT, true));
    }

    const dataRow = (rowInfo) => {
        return (
            <tbody className="dx-row">
                <tr className="main-row dx-theme-text-color">
                    <td width="55%">{rowInfo.data.text}</td>
                    <td width="30%">{bindAntallUpdateButtons(rowInfo.data)}</td>
                    <td width="15%">{calculateBeløpCellValue(rowInfo.data)}</td>
                </tr>
            </tbody>
        );
    }

    return (
        <div id="productEditPopup">
            <Popup
                visible={visiblePopup}
                onHiding={hidePopup}
                width="100%"
                height="75vh"
                showTitle={false}
                dragEnabled={false}
                closeOnOutsideClick={true}
                showCloseButton={false}
                container="#productEditPopup"
            >
                <LoadPanel
                    message={posMessage.loading}
                    shadingColor="rgba(0,0,0,0.4)"
                    visible={commonUI.loading}
                    showIndicator={true}
                />
                <div className="background-color inner-section-height d-flex align-items-center justify-content-center">
                    <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile">
                        <div className="align-items-center justify-content-center">
                            <div className="header-txt">
                                <h2 className="headerPopUp" style={{ marginBottom: 50 }}>Endre valgt varelinje!</h2>
                                <div id="productEditGridContainer" className="tablebox" >
                                    <DataGrid
                                        id="gridContainers"
                                        dataSource={product}
                                        keyExpr="productDetailID"
                                        noDataText={"Handlekurven er tom"}
                                        rowRender={dataRow}
                                        onCellPrepared={onCellPrepared}
                                        showBorders={false}
                                        columnAutoWidth={false}
                                        rowAlternationEnabled={false}
                                        showColumnLines={false}
                                        showRowLines={false}
                                    >
                                        <Column width={"55%"} className="dx-theme-accent-as-background-color" dataField="Name" caption="Varer" />
                                        <Column
                                            width={"30%"}
                                            caption="Antall"
                                        />
                                        <Column width={"15%"}
                                            caption="Beløp" />
                                    </DataGrid>
                                </div>
                                <div className="d-flex py-4 flex-column flex-lg-row place-Center">
                                    <Button
                                        stylingMode="contained"
                                        className="btn me-lg-5 mb-4 mb-lg-0 cursor-hand btn-icon remove-item-line-btn btn-font-xs"
                                        onClick={deleteProductLine}> <img className="w-30 me-3" src={`${preLoadImagePath.deleteblack}`} alt="img" />{ButtonName.RemoveItemLine}</Button>

                                    <Button
                                        className="dx-datagrid-search-text btn btn-cancel-color cursor-hand btn-icon mx-3 btn-font-xs" onClick={hidePopup} >{ButtonName.Cancel}</Button>

                                    <Button
                                        type="success"
                                        stylingMode="contained"
                                        className="btn cursor-hand btn-icon btn-font-xs"
                                        disabled={isProductBarcode(productSelector.editProduct.barcode)}
                                        onClick={onClickOkay}> {ButtonName.Ok}</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Popup>
        </div>

    );
}
export { ProductEditComponent }