import { localStorageService } from '../utilities/localStorageService';
import { handleResponse } from './response';

const config = {
    apiUrl: 'http://localhost:4040'
}


function loginBestlingPortal() {
    var resource = '/authenticateuser';
    var env = process.env.REACT_APP_ENV;
    if (env === undefined) {
        env = 'development'
    }

    return fetch(config.apiUrl + resource + "?environment=" + env)
        .then(handleResponse)
        .then(user => {
            return user;
        }).catch(err => {
            console.log(err)
        });
}

async function authenticationHeader() {
    // return authorization header with jwt token
    if (config.apiUrl === 'http://localhost:4040') {
        let user = {};
        if (!localStorageService.getAuthToken()) {
            user = await loginBestlingPortal();
            if (user) {
                user = JSON.parse(user);
                localStorageService.setAuthToken(user.token);
                localStorageService.setEmail(user.user.email);
            }
            if (user && user.token) {
                return { 'Authorization': 'Bearer ' + user.token };
            } else {
                return {};
            }
        } else {
            return { 'Authorization': 'Bearer ' + localStorageService.getAuthToken() };
        }
    } else {
        return {};
    }
}

function vippsAuthenticationHeader(userName, password, msn) {
    const token = `${userName}:${password}:${msn}`;
    const encodedToken = Buffer.from(token).toString('base64');
    return { 'Authorization': 'Basic ' + encodedToken };
}

function apiAuthHeader() {
    if (localStorageService.getAuthToken()) {
        return { 'Authorization': 'Bearer ' + localStorageService.getAuthToken() };
    }
    return null;
}

export const authentication = {
    authenticationHeader,
    vippsAuthenticationHeader,
    apiAuthHeader
}