import { headerConstants } from './header.constants';

const initialState = { name: headerConstants.START, isActive: true, selectedIndex: 0 };

function header(state = initialState, action) {
    switch (action.type) {
        case headerConstants.SET_HEADER:
            const { name, isActive } = action.payload;
            return {
                ...state,
                name: name,
                isActive: isActive
            };
        case headerConstants.SET_SELECTED_HEADER:
            const { selectedIndex } = action.payload;
            return {
                ...state,
                selectedIndex: selectedIndex
            };
        default:
            return state
    }
}

export { header };