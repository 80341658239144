import { startConstants } from './start.constants';
import { customerClubService } from '../../services/customer-club.service';
import { setError } from '../errorHandler/errorHandler.actions';
import { posMessage } from '../../helpers/pos-message';

function setStart(name, isActive) {
    return dispatch => {
        dispatch(
            {
                type: startConstants.SET_START,
                payload: {
                    name,
                    isActive
                }
            })
    };
}

//#region Actions

export const initStart = () => {
    return {
        type: startConstants.INIT_MEMBER
    }
}

export const getRequest = (requestType) => {
    return {
        type: requestType
    }
}

export const getSuccess = (response, requestType) => {
    return {
        type: requestType,
        payload: response
    }
}

export const getFailure = (error, requestType) => {
    return {
        type: requestType,
        payload: error
    }
}

export const getCustomerClubMember = (mobileNo) => async dispatch => {
    dispatch(getRequest(startConstants.LOGIN_REQUEST));
    const response = await customerClubService.getCustomerClubMemmber(mobileNo);
    if (response) {
        response.customerClubNumber = mobileNo;
        var payload = {
            data: response
        }
        dispatch(getSuccess(payload, startConstants.CUSTOMERCLUB_MEMBER));
        dispatch(setStart(startConstants.MEMBER_REGISTER_POPUP, true));
    }
    else {
        dispatch(getFailure(response, startConstants.LOGIN_FAILURE));
    }
}

export const getCustomerClubMemberByScan = (scanValue) => async dispatch => {
    dispatch(getRequest(startConstants.LOGIN_REQUEST));
    const response = { 'details': scanValue };
    if (response) {
        response.customerScan = scanValue;
        var payload = {
            data: response
        }
        dispatch(getSuccess(payload, startConstants.CUSTOMERCLUB_MEMBER));
        if (response) {
            dispatch(setStart(startConstants.MEMBER_COMMON_MESSAGE_POPUP, true));
        } else {
            dispatch(setStart(startConstants.SELF_SERVICE_CONFIRMATION, true));
        }
    }
    else {
        dispatch(getFailure(response, startConstants.LOGIN_FAILURE));
    }
}

export const getCorporateCustomerMember = (mobileNo) => async dispatch => {
    dispatch(getRequest(startConstants.LOGIN_REQUEST));
    const response = { 'corporateCC': mobileNo };
    if (response) {
        response.corporateCustomerNumber = mobileNo;
        var payload = {
            data: response
        }
        dispatch(getSuccess(payload, startConstants.CORPORATE_CUSTOMER_MEMBER));
        if (response) {
            dispatch(setStart(startConstants.MEMBER_COMMON_MESSAGE_POPUP, true));
        } else {
            dispatch(setStart(startConstants.SELF_SERVICE_CONFIRMATION, true));
        }
    }
    else {
        dispatch(getFailure(response, startConstants.LOGIN_FAILURE));
    }
}

export const getCorporateCustomerMemberByScan = (scanValue) => async dispatch => {
    dispatch(getRequest(startConstants.LOGIN_REQUEST));
    const response = { 'details': scanValue };
    if (response) {
        response.customerScan = scanValue;
        var payload = {
            data: response
        }
        dispatch(getSuccess(payload, startConstants.CORPORATE_CUSTOMER_MEMBER));
        if (response) {
            dispatch(setStart(startConstants.MEMBER_COMMON_MESSAGE_POPUP, true));
        } else {
            dispatch(setStart(startConstants.SELF_SERVICE_CONFIRMATION, true));
        }
    }
    else {
        dispatch(getFailure(response, startConstants.LOGIN_FAILURE));
    }
}

export { setStart };