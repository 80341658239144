import { MessageConstants } from './message.constants';

function message(state = {}, action) {
    switch (action.type) {
        case MessageConstants.PAYMENT_TRANSACTION_PROGRESS:
            return {
                name: action.payload.name,
                data: action.payload.data
            };

        default:
            return state
    }
}

export { message };