export const GiftCardConstants = {
    //#region  Action
    GIFTCARD_INITIATE_PAYMENT_REQUEST: 'GIFTCARD_INITIATE_PAYMENT_REQUEST',
    GIFTCARD_INITIATE_PAYMENT_SUCCESS: 'GIFTCARD_INITIATE_PAYMENT_SUCCESS',
    GIFTCARD_INITIATE_PAYMENT_ERROR: 'GIFTCARD_INITIATE_PAYMENT_ERROR',
    GIFTCARD_PAYMENT_STATUS_REQUEST: 'GIFTCARD_PAYMENT_STATUS_REQUEST',
    GIFTCARD_PAYMENT_STATUS_SUCCESS: 'GIFTCARD_PAYMENT_STATUS_SUCCESS',
    GIFTCARD_PAYMENT_STATUS_ERROR: 'GIFTCARD_PAYMENT_STATUS_ERROR',
    INIT_GIFTCARD: 'INIT_GIFTCARD',
    WITHDRAWAL_GIFTCARD_STATUS_REQUEST: 'WITHDRAWAL_GIFTCARD_STATUS_REQUEST',
    WITHDRAWAL_GIFTCARD_PAYMENT_SUCCESS: 'WITHDRAWAL_GIFTCARD_PAYMENT_SUCCESS',
    //#endregion

}