import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'devextreme-react/button';
import { Popup } from 'devextreme-react/popup';
import LazyImage from 'react-lazy-blur-image';
import { preLoadImagePath } from '../../helpers/images';
import { setStart } from '../../pos-redux/customer/customer.action';
import './SelfServiceConfirmation.component.scss';
import { startConstants } from '../../pos-redux/start/start.constants';
import { logs } from '../../pos-redux/troubleshoot/troubleshoot.actions';
import { ButtonName, ScreenName } from '../../helpers/pos-message';
import { initCustomer } from '../../pos-redux/customer/customer.action';

function CustomerAccountConfirmationComponent() {
    const dispatch = useDispatch();
    const [customerDetail, uiConfiguration] = useSelector(state => [state.customerDetail, state.uiConfiguration]);

    const clickHandle = () => {
        logs(ScreenName.CustomerAccountConfirmationComponent, ButtonName.ClickConfirm, "click Bekreft", uiConfiguration);
        dispatch(setStart(startConstants.SELF_SERVICE_CONFIRMATION, true));
    }
    const clickContinueWithoutHandle = () => {
        logs(ScreenName.CustomerAccountConfirmationComponent, ButtonName.ContinueWithout, "click Continue Without", uiConfiguration);
        dispatch(initCustomer());
        dispatch(setStart(startConstants.SELF_SERVICE_CONFIRMATION, true));
    }

    const renderEmployeeAccountFoundPopUp = () => {
        return (
            <div id="self-service-confirm" className="text-center inner-section-height tab-content d-flex align-items-center justify-content-lg-center flex-column">
                <br />
                <h2 className="popUptext">Bekreft din ansattkonto</h2>
                <br />
                <h3>
                    <div className='text-center'>
                        <div className='me-lg-5 mb-4 mb-lg-0 '><span><b>E-post: </b></span>
                            <span><b>{customerDetail.data.email}</b></span>
                        </div>
                        <div className='me-lg-5 mb-4 mb-lg-0 '><span><b>Telefon: </b></span><span><b>{customerDetail.data.mobile}</b></span>  </div>
                    </div>
                </h3>
                <div className="d-flex py-4 flex-column flex-lg-row">
                    <Button
                        stylingMode="contained"
                        className="btn me-lg-5 mb-4 mb-lg-0 cursor-hand btn-icon" onClick={clickContinueWithoutHandle}>
                        Fortsett uten
                    </Button>
                    <Button
                        type="success"
                        stylingMode="contained"
                        className="btn cursor-hand btn-icon" onClick={clickHandle}>
                        <LazyImage
                            placeholder={`${preLoadImagePath.default_image_55}`}
                            uri={`${preLoadImagePath.check}`}
                            render={(src, style) => <img src={src} alt="img" className="w-30 me-4 img-45" />}
                        />
                        Bekreft
                    </Button>
                </div>
            </div>);
    }
    const renderEmployeeAccountNotFoundPopUp = () => {
        return (
            <div id="self-service-confirm" className="text-center inner-section-height tab-content d-flex align-items-center justify-content-lg-center flex-column">
                <br />
                <h2 className="popUptext">Ansattkonto ikke funnet!</h2>
                <br />
                <h3 className="text-center"><b> Ønsker du å opprette ansattkonto? Ta kontakt med betjeningen.</b></h3>
                <br /> <br /> <br /> <br />
                <div className="text-center">
                    <div className="d-flex h-100 justify-content-center align-items-center mt-3">
                        <Button
                            stylingMode="contained"
                            className="btn btn-primary mx-auto w-100-mobile cursor-hand btn-icon" onClick={clickContinueWithoutHandle}>
                            Fortsett uten
                        </Button>
                    </div>
                </div>
            </div>);
    }
    const renderEmployeeAccountNotOpenPopUp = () => {
        return (
            <div id="self-service-confirm" className="text-center inner-section-height tab-content d-flex align-items-center justify-content-lg-center flex-column">
                <br />
                <h2 className="popUptext">Ansattkonto ikke åpen!</h2>
                <br />
                <h3 className="text-center"><b> Ønsker du å åpne din ansattkonto? Ta kontakt med betjeningen.</b></h3>
                <br /> <br /> <br /> <br />
                <div className="text-center">
                    <div className="d-flex h-100 justify-content-center align-items-center mt-3">
                        <Button
                            stylingMode="contained"
                            className="btn btn-primary mx-auto w-100-mobile cursor-hand btn-icon" onClick={clickContinueWithoutHandle}>
                            Fortsett uten
                        </Button>
                    </div>
                </div>
            </div>);
    }

    return (
        <Popup
            visible={true}
            width="95%"
            height={440}
            showTitle={false}
            dragEnabled={false}
            container="#pos"
        >
            {
                customerDetail.data.id && (customerDetail.data.email || customerDetail.data.invoiceEmail) && (customerDetail.data.mobile || customerDetail.data.phone)
                    ? renderEmployeeAccountFoundPopUp() : customerDetail.data.id == undefined ? renderEmployeeAccountNotFoundPopUp() : renderEmployeeAccountNotOpenPopUp()
            }

        </Popup>
    );
}

export { CustomerAccountConfirmationComponent }