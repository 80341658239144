import { ApiUrlConstants } from './api/apiUrl.constants'
import { apiProvider } from './api/utilities/provider';
import { VippsPaymentConstants } from '../pos-redux/payment/vipps/vippsPayment.constant';
import { PaymentConstants } from '../pos-redux/payment/payment.constants';
import { paymentMethodEnum } from '../helpers/pos-message';
import { AppVersion } from '../helpers/helper';

export const orderPosService = {
    orderTransaction,
    orderPOS,
};

function orderTransaction(posRequest) {
    return apiProvider.fetch.post(ApiUrlConstants.RetailLink.OrderPosEndPoint, posRequest);
}

function orderPOS(orderPOS, paymentResponse, paymentName, employeeDetail, giftCards) {
    orderPOS = {
        ...orderPOS,
        latestPayment: paymentPOS(paymentResponse, paymentName, orderPOS.order.orderTotal),
        registeredLedgerPayments: giftCards || []
    }   

    orderPOS.posOrderLines = orderPOS.order.orderLines.length === 0 ? orderPOS.initPosOrderLines : orderPOS.order.orderLines;
    orderPOS.employeeID = Object.entries(employeeDetail.data).length > 0 ? employeeDetail.data.employeeNumber : 0;
    orderPOS.appVersion = AppVersion;
    orderPOS.order.orderLines = [];
    return apiProvider.fetch.post(ApiUrlConstants.RetailLink.OrderEndPoint, orderPOS);
}

function paymentPOS(paymentProvider, paymentName, amount) {
    var payment = {};
    if (paymentName === VippsPaymentConstants.VIPPS) {
        var vippsTransactionText = "Vipps: " + paymentProvider.transactionDate +
            " Mob: " + paymentProvider.mobileNumber + "\n" +
            "Ref: " + paymentProvider.transactionId +
            " Beløp: " + paymentProvider.amount;

        payment = {            
            amount: paymentProvider.amount,
            transactionDate: paymentProvider.transactionDate,
            transactionText: vippsTransactionText,
            paymentMethod: paymentMethodEnum.Vipps,
        }
    } else if (paymentName === PaymentConstants.BANK_TERMINAL) {
        payment = {
            amount: paymentProvider.amount,
            transactionText: paymentProvider.LongTextMessage,         
            issuerId: paymentProvider.IssuerID,
            paymentMethod: paymentMethodEnum.BankTerminal,
        }
    } else if (paymentName === PaymentConstants.INVOICE) {
        payment = {
            amount: amount,
            paymentMethod: paymentMethodEnum.Invoice,
        }
    }
    return payment;
}