import React, { useState } from 'react';
import DataGrid, { Column, Selection, Summary, TotalItem, Editing, Scrolling } from 'devextreme-react/data-grid';
import './addProduct.component.scss';
import { setRegisterProduct, updateProduct, setRegisterProductWithInformation } from '../../pos-redux/registerProduct/registerProduct.actions';
import { RegisterProductConstants } from '../../pos-redux/registerProduct/registerProduct.constants';
import { useDispatch, useSelector } from 'react-redux';
import { ProductEditButton } from '../../pos-redux/registerProduct/productEditButton.constants';
import { isMobile } from 'react-device-detect';
import { Button } from 'devextreme-react/button';
import { barcodeProduct, addProduct, addProductToCart, decreaseProductUsedQty, increaseProductUsedQty, deleteProduct, customerClubMemberCheck, customerRfidCheck } from '../../pos-redux/registerProduct/product.actions';
import BarcodeReader from 'react-barcode-reader'
import { setError, setWarning } from '../../pos-redux/errorHandler/errorHandler.actions';
import { currencyOptions, delay } from '../../helpers/helper';
import { useEffect } from 'react';
import LazyImage from 'react-lazy-blur-image';
import { ScrollView } from 'devextreme-react';
import { ButtonName, posMessage, ScreenName } from '../../helpers/pos-message';
import { preLoadImagePath } from '../../helpers/images';
import { showUILoader } from '../../pos-redux/commonUI/commonUI.actions';
import { LoadPanel } from 'devextreme-react';
import { assistanceLight } from '../../pos-redux/uiConfiguration/uiConfiguration.actions';
import { lightEnum, CartQtyEnum } from '../../helpers/pos-message';
import { setPayment } from '../../pos-redux/payment/payment.actions';
import { PaymentConstants } from '../../pos-redux/payment/payment.constants';
import { setHeader } from '../../pos-redux/header/header.actions';
import { headerConstants } from '../../pos-redux/header/header.constants';
import { logs } from '../../pos-redux/troubleshoot/troubleshoot.actions';
import { CustomerAccountType } from '../../pos-redux/customer/customer.constant';
import { selectProduct } from '../../pos-redux/registerProduct/addProductWithQty.actions';
import { productWeightScaling } from '../../pos-redux/troubleshoot/weightScaling/weightScaling.actions';
import { isSpecialProduct } from '../../helpers/helper';

function AddProductComponent() {
    const dispatch = useDispatch();

    const [uiConfiguration, stateOrder, employeeDetail, selectedProduct, commonUI, bagsType, memberStartState, startTroubleShoot, customerDetail] = useSelector(state => [state.uiConfiguration
        , state.product
        , state.employeeDetail
        , state.productWithQty
        , state.commonUIReducer
        , state.selectBag
        , state.start
        , state.troubleShootReducer
        , state.customerDetail
    ]);

    const productEditButtonVisible = uiConfiguration.productEditButtonVisible;
    const logo = uiConfiguration.welcomeLogo ? uiConfiguration.welcomeLogo : `${preLoadImagePath.logo}`;
    var time = 60000; //1 min
    var idleInterval;
    var cellIndex = 0;

    useEffect(() => {
        //handleBarcodeScan('4005808849864');        
        //handleBarcodeScan('X021655519904');
        //handleBarcodeScan('7072141002420');
        if (uiConfiguration.idealTimerAtCartWindowInMinute > 0) {
            time = uiConfiguration.idealTimerAtCartWindowInMinute * 60000; // this is minute
            if (idleInterval)
                clearInterval(idleInterval);

            activeTimeSetup();
        }

    }, []);

    function timerIncrement() {
        //console.log('refresh after ' + time + ' milliseconds ' + new Date());
        clearInterval(idleInterval);
        orderCancelPopUp();
        idleInterval = setInterval(timerIncrement, time);
    }

    function activeTimeSetup() {
        window.onload = resetTimer;
        // DOM Events
        var events = ['keypress', 'click', 'touchmove'];//'mousedown', 'mousemove','touchstart'
        events.forEach(function (name) {
            document.addEventListener(name, resetTimer, true);
        });
        function resetTimer() {
            clearInterval(idleInterval);
            idleInterval = setInterval(timerIncrement, time);
        }
    };

    const orderCancelPopUp = () => {
        var type = { isStructure: false };
        dispatch(setRegisterProductWithInformation(RegisterProductConstants.STRUCTURE_PRODUCT_POPUP, true, type));
    }

    const bindAntallUpdateButtons = (productRow) => {
        return (
            <div id="updateProductQtyContainerGrid" className="d-flex justify-content-between bagBoxes">
                <div className="dx-theme-background-color dx-theme-border-color bagBox addSubtractSection p-0 d-flex justify-content-between mt-3">
                    <div className="cursor-hand" onClick={() => changeUsedQty(productRow, CartQtyEnum.Minus)}>
                        <i className="icon dx-icon-minus"></i>
                    </div>
                    <div className="dx-theme-border-color addSubtractSection-value">
                        {productRow.amount} {posMessage.stk}
                    </div>
                    <div className="cursor-hand" onClick={() => changeUsedQty(productRow, CartQtyEnum.Plus)}>
                        <i className="icon dx-icon-add"></i>
                    </div>
                </div></div>
        );
    }

    const changeUsedQty = async (productRow, buttonType) => {
        if (uiConfiguration.allowCustomerCredit === CustomerAccountType.Activated)
            customerRfidCheck(customerDetail, stateOrder);
        else
            var ccMember = customerClubMemberCheck(memberStartState, stateOrder);

        dispatch(showUILoader(true));

        let lineProducts = JSON.parse(JSON.stringify(stateOrder.OrderPos.order.orderLines)); //Deep copy of object

        try {
            if (buttonType === CartQtyEnum.Minus) {
                await dispatch(decreaseProductUsedQty(lineProducts, productRow.productDetailID, uiConfiguration, stateOrder, employeeDetail))
            }
            if (buttonType === CartQtyEnum.Plus) {
                await dispatch(increaseProductUsedQty(lineProducts, productRow.productDetailID, uiConfiguration, stateOrder, employeeDetail))
            }
        } catch (error) {
            console.log(error);
        }

        dispatch(showUILoader(false));
    }

    const clickWithoutBarcode = () => {
        logs(ScreenName.ProductComponent, "Without Barcode", "setRegisterProduct", uiConfiguration);
        if (uiConfiguration.rootAssortmentId > 0) {
            dispatch(setRegisterProduct(RegisterProductConstants.ASSORTMENT_COMPONETNT, true));
        } else {
            dispatch(setRegisterProduct(RegisterProductConstants.ADD_ITEM_GROUP, true));
        }
    }

    const clickCallForOpertions = () => {
        logs(ScreenName.ProductComponent, ButtonName.CallForService, "Called for help: LightTowerVisible", uiConfiguration);
        if (startTroubleShoot?.data?.data) {
            if (startTroubleShoot?.data?.data.LightTowerVisible) {
                dispatch(assistanceLight(lightEnum.TurnOn));
            }
        }

        dispatch(setRegisterProduct(RegisterProductConstants.CALL_FOR_HELP, true));
    }

    const clickPay = () => {
        logs(ScreenName.ProductComponent, ButtonName.Continue, "setRegisterProduct", uiConfiguration);
        if (uiConfiguration.maximumProductCountLimit === 0 && uiConfiguration.maximumProductAmountLimit > stateOrder.OrderPos.order.orderTotal) {
            // ignore the limit check here.
        } else if ((stateOrder.OrderPos && stateOrder.OrderPos.order.orderLines
            && (stateOrder.OrderPos.order.orderTotal >= uiConfiguration.maximumProductAmountLimit
                || stateOrder.OrderPos.totalQuantityPOSOrderLines >= uiConfiguration.maximumProductCountLimit))
            && (!employeeDetail.validCard)) {
            dispatch(setRegisterProduct(RegisterProductConstants.CALL_FOR_OPERATIONS, true));
            return;
        }


        if (stateOrder.OrderPos && (stateOrder.OrderPos.order.orderTotal > 0) && stateOrder.OrderPos.order?.orderLines.length > 0) {
            if (uiConfiguration.skipBagSelector) {
                dispatch(setRegisterProduct(RegisterProductConstants.SET_REGISTER_PRODUCT, false));
                dispatch(setHeader(headerConstants.BETAL, true));
                dispatch(setPayment(PaymentConstants.PAYMENT_METHOD, true));
            } else {
                dispatch(setRegisterProduct(RegisterProductConstants.PRODUCT_CONFIRMATION, true));
            }

        }
        else {
            setWarning(posMessage.orderSumIsZero);
        }
    }

    const clickEndre = (item) => {
        logs(ScreenName.ProductComponent, ButtonName.Edit, "Product is editing : " + item.productDetailID, uiConfiguration);
        dispatch(updateProduct(item), true);
        dispatch(setRegisterProduct(RegisterProductConstants.EDIT_PRODUCT, true));
    };

    const deleteProductLine = async (editProduct) => {
        logs(ScreenName.ProductComponent, "Delete", "Product is Deleting : " + editProduct.productDetailID, uiConfiguration);
        if (editProduct) {
            if (bagsType.data) {
                var bagIndex = bagsType.data.findIndex(x => x.id === editProduct.productDetailID);
                if (bagIndex > -1) {
                    //reset bag with zero quantity
                    bagsType.data[bagIndex].usedQuantity = 0;
                }
            }

            dispatch(showUILoader(true));
            await dispatch(deleteProduct(editProduct, uiConfiguration, stateOrder, employeeDetail));
            dispatch(showUILoader(false));
        }
    };

    const calculateCustomSummary = (options, posOrder) => {
        if (options.name === "TotalAmount") {
            if (options.summaryProcess === "start") {
                options.totalValue = 0;
            }
            if (options.summaryProcess === "calculate" && posOrder) {
                options.totalValue = currencyFormat(posOrder.order.orderTotal);
            }
        }

        if (options.name === "TotalStock") {
            if (options.summaryProcess === "start") {
                options.totalValue = 0;
            }
            if (options.summaryProcess === "calculate" && posOrder) {
                options.totalValue = posOrder.totalQuantityPOSOrderLines;
            }
        }

        if (options.name === "OrderDiscountText") {
            if (options.summaryProcess === "start") {
                options.totalValue = 0;
            }
            if (options.summaryProcess === "finalize" && posOrder) {
                options.totalValue = posOrder.order.orderDiscountName;
            }
        }

        if (options.name === "OrderDiscount") {
            if (options.summaryProcess === "start") {
                options.totalValue = 0;
            }
            if (options.summaryProcess === "finalize" && posOrder) {
                options.totalValue = "-" + currencyFormat(posOrder.order.orderDiscount);
            }
        }
    }

    const currencyFormat = (value) => {
        return value.toLocaleString('nb-NB', {
            ...currencyOptions,
            style: 'decimal',
        })
    }

    const rowButtonControl = (rowInfo) => {
        if (rowInfo.structurSub === false && rowInfo.linkProductID === 0) {
            if (productEditButtonVisible === ProductEditButton.ShowEditButton) {
                return (<Button
                    text={ButtonName.Edit}
                    stylingMode="contained"
                    className="btn-primary btnEndre cursor-hand"
                    onClick={() => clickEndre(rowInfo)}></Button>);
            }

            if (productEditButtonVisible === ProductEditButton.ShowDeleteButton) {
                return (<Button
                    stylingMode="contained"
                    className="btn-primary btnEndre cursor-hand"
                    onClick={() => deleteProductLine(rowInfo)}>
                    <img className="w-30" src={`${preLoadImagePath.deleteblack}`} alt="img" />&nbsp;</Button>);
            }

            if (productEditButtonVisible === ProductEditButton.ShowPlusMinusButton) {
                return (isSpecialProduct(rowInfo) > 0 ? '' : bindAntallUpdateButtons(rowInfo)
                )
            }
        }

        return <> </>;
    }


    const dataRow = (rowInfo, order) => {
        if (rowInfo.data.structurSub && !rowInfo.data.isStrucutreVisibleOnUI) {
            cellIndex = 1;
            return <></>
        }

        return (
            <tbody className={`dx-row ${(rowInfo.rowIndex - cellIndex) % 2 ? 'dx-row-alt' : null}`}>
                <tr className="main-row dx-theme-text-color">
                    <td width="50%">
                        {rowInfo.data.structurSub
                            ?
                            ` —   ` + rowInfo.data.text
                            :
                            rowInfo.data.text
                        }
                    </td>
                    {
                        productEditButtonVisible === ProductEditButton.ShowPlusMinusButton && !isMobile ?

                            <td id="antallValue" className="textalign-center">
                                {
                                    // bindAntallUpdateButtons(rowInfo.data)                                    
                                }
                                {rowInfo.data.spesial > 0 ? currencyFormat(rowInfo.data.amount) : rowInfo.data.amount} {posMessage.stk}
                            </td> :
                            <td className="textalign-center"
                                width={productEditButtonVisible === ProductEditButton.ShowEditButton && !isMobile ? "10%" : "20%"}>

                                {rowInfo.data.spesial > 0 ?
                                    <div className='display-flex'>
                                        <span> {currencyFormat(rowInfo.data.amount)}  </span>
                                        <span className="ellipsis-text-flow"> &nbsp; {rowInfo.data.unitText} </span>
                                    </div>
                                    : rowInfo.data.amount + ' ' + posMessage.stk} </td>
                    }
                    <td className={rowInfo.data.discount ? "notes-row dx-validationsummary-item text-align-right-price" : "text-align-right-price"} width={productEditButtonVisible === ProductEditButton.ShowEditButton && !isMobile ? "10%" : "20%"}>
                        {currencyFormat(rowInfo.data.sum)}
                    </td>
                    {
                        isMobile
                            ?
                            <td></td>
                            :
                            <td width="22%" className="textalign-center">
                                {
                                    rowButtonControl(rowInfo.data)
                                }
                            </td>
                    }
                </tr>
                {
                    rowInfo.data.structurSub
                        ?
                        null
                        :
                        rowInfo.data && (rowInfo.data.discount || rowInfo.data.discountName) ? <tr className="notes-row dx-validationsummary-item">
                            <td colSpan="2"> &nbsp; &nbsp; &nbsp; {rowInfo.data.discountName}</td>
                            <td></td>
                            <td></td>
                        </tr> : null
                }

            </tbody >
        );

    }

    const handleBarcodeScan = async (val) => {
        logs(ScreenName.ProductComponent, "Barcode scan", "Barcode is Scaned", uiConfiguration);
        if (uiConfiguration.allowCustomerCredit === CustomerAccountType.Activated)
            var customer = customerRfidCheck(customerDetail, stateOrder);
        else
            var ccMember = customerClubMemberCheck(memberStartState, stateOrder);

        var barcodeProductModel = {
            usedQuantity: 1,
            barCode: val
        }

        dispatch(showUILoader(true));
        if (!uiConfiguration.isProductButtonVisible) { // product button hide 
            var bcProduct = await barcodeProduct(barcodeProductModel.barCode);
            if (bcProduct) {
                if (!uiConfiguration.skipProductAmountSelection) { // show pop-up
                    dispatch(selectProduct(bcProduct));
                    dispatch(setRegisterProduct(RegisterProductConstants.ADD_PRODUCT_WITH_QTY, true));
                } else {
                    uiConfiguration.isProductButtonVisible = true;
                    if (isSpecialProduct(bcProduct)) {
                        var productResponse = await dispatch(productWeightScaling());
                        if (productResponse) {
                            barcodeProductModel.usedQuantity = productResponse.Amount;
                        } else {
                            dispatch(showUILoader(false));
                            dispatch(setRegisterProduct(RegisterProductConstants.BARCODE_FAILURE, true));
                            return;
                        }
                    }
                }
            } else {
                dispatch(setRegisterProduct(RegisterProductConstants.BARCODE_FAILURE, true));
            }
        }

        if (uiConfiguration.isProductButtonVisible) { // product button show
            var res = await dispatch(addProductToCart(barcodeProductModel, uiConfiguration, employeeDetail, stateOrder, ccMember, customer));
            dispatch(showUILoader(false));
            if (res === false) {
                dispatch(setRegisterProduct(RegisterProductConstants.BARCODE_FAILURE, true));
                return;
            } else if (res === RegisterProductConstants.CALL_FOR_OPERATIONS) {
                dispatch(setRegisterProduct(RegisterProductConstants.CALL_FOR_OPERATIONS, true));
                return;
            } else if (res === RegisterProductConstants.STRUCTURE_PRODUCT_POPUP) {
                var type = { isStructure: true };
                dispatch(setRegisterProductWithInformation(RegisterProductConstants.STRUCTURE_PRODUCT_POPUP, true, type));
                return;
            }
        }

        dispatch(showUILoader(false));
    }

    const handleBarcodeError = (err) => {
        dispatch(setRegisterProduct(RegisterProductConstants.BARCODE_FAILURE, true));
    }

    const onCellPrepared = (e) => {
        if (e.rowType === 'header') {
            e.cellElement.classList.add('dx-theme-accent-as-background-color');
        }
        if (e.rowType === 'totalFooter') {
            if (e.totalItem.summaryCells) {
                for (let index = 0; index < e.totalItem.summaryCells.length; index++) {
                    const element = e.totalItem.summaryCells[index];
                    if (element) {
                        var firstfooter = element.find(x => x.cssClass == "dx-button-mode-outlined dx-button-default");
                        if (firstfooter) {
                            var summaryCellIndex = element.indexOf(firstfooter);
                            var itemElement = e.cellElement.children[summaryCellIndex];
                            if (itemElement) {
                                var text = itemElement.innerHTML;
                                e.cellElement.children[summaryCellIndex].classList.remove("dx-datagrid-summary-item");
                                e.cellElement.children[summaryCellIndex].classList.add("dx-datagrid-summary-item-orderDiscount");
                                e.cellElement.children[summaryCellIndex].innerHTML = '';
                                // comment &nbsp due to left side margin none for discount text
                                //e.cellElement.children[summaryCellIndex].innerHTML = '&nbsp';
                                e.cellElement.children[summaryCellIndex].innerHTML += text;
                            }
                        }
                    }
                }
            }
            e.cellElement.classList.add('dx-theme-accent-as-background-color');
        }
    }

    const bottomButtons = () => {
        let productButtonClass = "btn btn-primary mb-lg-3 cursor-hand justify-content-left btn-icon textalign-left";
        //productButtonClass = !uiConfiguration.isProductButtonVisible ? "btn-hidden content-visibility-hide" : productButtonClass;

        return (<div className="flex-column ps-lg-4 justify-content-between">
            <img id="cart-img-logo" hidden={isMobile} className="pb-2 logo-mobiles" src={`${logo}`} alt="img" />
            <div id="cart-btn-groups" className="product-bottom-btns">
                {
                    isMobile
                        ?
                        <></>
                        :
                        <div className={uiConfiguration.orderCancelButtonVisible ? "" : "btn-hidden"}>
                            <Button
                                type="danger"
                                stylingMode="contained"
                                className="d-lg-flex d-none mb-3 btn btn-primary cursor-hand justify-content-left btn-icon textalign-left"
                                onClick={() => dispatch(setRegisterProduct(RegisterProductConstants.CANCEL_ORDER, true))}>
                                <LazyImage
                                    placeholder={`${preLoadImagePath.default_image_55}`}
                                    uri={`${preLoadImagePath.cancel}`}
                                    render={(src, style) => <img className="w-50 me-2 me-xl-4 img-43" src={src} alt="img" />}
                                />
                                <span>{ButtonName.Cancel} <br /> ordre </span>
                            </Button>
                        </div>
                }
                <div hidden={isMobile ? isMobile : !uiConfiguration.isCallAssistanceButtonVisible}>
                    <Button
                        stylingMode="contained"
                        className="d-lg-flex d-none mb-3 btn btn-primary cursor-hand justify-content-left btn-icon textalign-left"
                        onClick={clickCallForOpertions}>
                        <LazyImage
                            placeholder={`${preLoadImagePath.default_image_55}`}
                            uri={`${preLoadImagePath.speaker}`}
                            render={(src, style) => <img className="w-50 me-2 me-xl-4 img-50" src={src} alt="img" />}
                        />
                        <span>Tilkall <br /> betjening</span>
                    </Button>
                </div>

                <div id="mobile-view-btn" className="d-flex flex-row flex-lg-column">
                    <Button
                        type="success"
                        stylingMode="contained"
                        className={productButtonClass}
                        onClick={clickWithoutBarcode}>
                        <LazyImage
                            placeholder={`${preLoadImagePath.default_image_55}`}
                            uri={`${preLoadImagePath.no_barcode}`}
                            render={(src, style) => <img className="w-50 me-2 me-xl-4 img-50" src={src} alt="img" />}
                        />
                        <span className='new-line'> {uiConfiguration.productButtonName} </span>
                    </Button>

                    <Button
                        type="success"
                        stylingMode="contained"
                        className="btn btn-primary ms-1 cursor-hand justify-content-left btn-icon"
                        onClick={clickPay}>
                        <LazyImage
                            placeholder={`${preLoadImagePath.default_image_55}`}
                            uri={`${preLoadImagePath.check}`}
                            render={(src, style) => <img className="w-50 me-2 me-xl-4 img-45" src={src} alt="img" />}
                        />
                        {ButtonName.Continue}
                    </Button>
                </div>
            </div>
        </div>);
    }

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    return (
        <>
            <div className="tab-pane fade" id="addProductComponent" role="tabpanel" aria-labelledby="nav-profile-tab">
                <LoadPanel
                    message={posMessage.loading}
                    shadingColor="rgba(0,0,0,0.4)"
                    visible={commonUI.loading}
                    showIndicator={true}
                />
                <div className="tablebox">
                    <DataGrid
                        id="gridContainer"
                        dataSource={stateOrder.OrderPos == null ? [] : stateOrder.OrderPos.order.orderLines}
                        keyExpr="productDetailID"
                        noDataText={"Handlekurven er tom"}
                        showBorders={true}
                        columnAutoWidth={true}
                        rowRender={(e) => dataRow(e, stateOrder.OrderPos.order)}
                        rowAlternationEnabled={true}
                        onCellPrepared={onCellPrepared}
                        showColumnLines={false}
                    >
                        <Editing
                            allowDeleting={employeeDetail.data.employeeNumber > 0 && stateOrder.OrderPos && stateOrder.OrderPos.order.orderTotal > 0 && productEditButtonVisible === ProductEditButton.ShowEditButton && !isMobile ? true : false}
                            allowUpdating={employeeDetail.data.employeeNumber > 0 && stateOrder.OrderPos && stateOrder.OrderPos.order.orderTotal > 0 && productEditButtonVisible === ProductEditButton.ShowEditButton && !isMobile ? true : false} />
                        <Selection mode="single" />
                        <Column width={isMobile ? '40%' : '50%'} className="dx-theme-accent-as-background-color" dataField="Name" caption="Varer" />

                        <Column
                            width={isMobile ? '25%' : '16%'}
                            alignment='center'
                            caption="Antall"
                        />
                        <Column
                            width={productEditButtonVisible === ProductEditButton.ShowEditButton && !isMobile ? "18%" : isMobile ? "35%" : "20%"}
                            alignment='center'
                            calculateCellValue={(e) => e.amount * e.unitPrice}
                            caption="Beløp" />
                        <Column
                            width={isMobile ? '25%' : '10%'}
                            caption=""
                        />

                        <Scrolling mode="virtual" />
                        <Summary
                            calculateCustomSummary={(e) => calculateCustomSummary(e, stateOrder.OrderPos)}>
                            {
                                stateOrder.OrderPos != null && stateOrder.OrderPos.order.orderDiscount > 0
                                    ?
                                    <TotalItem
                                        cssClass="dx-button-mode-outlined dx-button-default"
                                        name="OrderDiscountText"
                                        column="Varer"
                                        summaryType="custom"
                                        showInColumn="Varer"
                                    />
                                    :
                                    ""
                            }

                            <TotalItem
                                name="Description"
                                displayFormat="Å betale"
                                summaryType="custom"
                                showInColumn="Varer" />

                            {
                                stateOrder.OrderPos != null && stateOrder.OrderPos.order.orderDiscount > 0
                                    ?
                                    <TotalItem
                                        cssClass="dx-button-mode-outlined dx-button-default"
                                        name="OrderDiscountEmpty"
                                        summaryType="custom"
                                        showInColumn="Antall"
                                    />
                                    :
                                    ""
                            }

                            <TotalItem
                                cssClass="text-align-footer"
                                name="TotalStock"
                                summaryType="custom"
                                displayFormat={"{0} " + posMessage.stkDot}
                                showInColumn="Antall" />

                            {
                                stateOrder.OrderPos != null && stateOrder.OrderPos.order.orderDiscount > 0
                                    ?
                                    <TotalItem
                                        cssClass="dx-button-mode-outlined dx-button-default gridview-footer-orderdiscount"
                                        name="OrderDiscount"
                                        summaryType="custom"
                                        showInColumn="Beløp"
                                    />
                                    :
                                    ""
                            }

                            <TotalItem
                                cssClass="text-align-right-price"
                                name="TotalAmount"
                                summaryType="custom"
                                displayFormat={capitalizeFirstLetter(posMessage.krDot) + " {0} "}
                                showInColumn="Beløp"
                            />

                        </Summary>
                    </DataGrid>


                    {
                        isMobile
                            ?
                            bottomButtons()
                            :
                            <ScrollView
                                scrollByContent={true}
                                showScrollbar={'onScroll'}
                            >
                                {bottomButtons()}

                            </ScrollView>
                    }

                </div>
                <BarcodeReader
                    onError={handleBarcodeError}
                    onScan={handleBarcodeScan}
                    minLength={1}
                />
            </div>
        </>
    );
}

export { AddProductComponent };