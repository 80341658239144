import { ApiUrlConstants } from '../services/api/apiUrl.constants'
import { apiProvider } from './api/utilities/provider';

export const terminalPaymentService = {
    initiatePayment,
    checkPaymentDetail,
    cancelPayment
};

async function initiatePayment(amount) {
    return await apiProvider.axios.getSingle(ApiUrlConstants.POSAgent.TerminalInitiatePaymentEndPoint + '?amount=' + amount);
}

async function checkPaymentDetail() {
    return await apiProvider.axios.getSingle(ApiUrlConstants.POSAgent.TerminalPaymentStatusEndPoint, {})
}

async function cancelPayment() {
    return await apiProvider.axios.getSingle(ApiUrlConstants.POSAgent.TerminalCancelPayment, {})
}


