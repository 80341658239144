import { MessageConstants } from './message.constants';

export const setTransactionProgressMessage = (message, isSuccessfull) => dispatch => {
    dispatch({
        type: MessageConstants.PAYMENT_TRANSACTION_PROGRESS,
        payload: {
            name: MessageConstants.PAYMENT_TRANSACTION_PROGRESS,
            data: {
                message,
                isSuccessfull
            }
        }
    });
}