export const CustomerConstants = {
    CUSTOMER_DETAIL_REQUEST: 'CUSTOMER_DETAIL_REQUEST',
    CUSTOMER_DETAIL_SUCCESS: 'CUSTOMER_DETAIL_SUCCESS',
    CUSTOMER_DETAIL_ERROR: 'CUSTOMER_DETAIL_ERROR',
    INIT_CUSTOMER: 'INIT_CUSTOMER',
    SET_CUSTOMER: 'SET_CUSTOMER',
    CUSTOMER_COMFIRMATION: 'CUSTOMER_COMFIRMATION',
    CUSTOMER_REGISTER_PURCHASE: 'CUSTOMER_REGISTER_PURCHASE',
    CUSTOMER_ACCOUNT_CONFIRMATION: 'CUSTOMER_ACCOUNT_CONFIRMATION',
    SET_START: 'SET_START',
}
export const CustomerAccountType = {
    NotActivated: 0,
    Activated: 1
}
