
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import './vippsPaymentStatus.component.scss';
import { MessageConstants } from '../../../pos-redux/message/message.constants';
import { cancelPayment, checkPaymentDetail, initVipps } from '../../../pos-redux/payment/vipps/vippsPayment.action';
import { VippsPaymentConstants } from '../../../pos-redux/payment/vipps/vippsPayment.constant';
import { Button } from 'devextreme-react/button';
import LazyImage from 'react-lazy-blur-image';
import { setTransactionProgressMessage } from '../../../pos-redux/message/message.actions';
import { orderPosService } from '../../../services/orderPos.service';
import { preLoadImagePath } from '../../../helpers/images';
import { PaymentConstants } from '../../../pos-redux/payment/payment.constants';
import { setPayment, setPOSOrder } from '../../../pos-redux/payment/payment.actions';
import { ButtonName, ScreenName } from '../../../helpers/pos-message';
import { logs } from '../../../pos-redux/troubleshoot/troubleshoot.actions';
import { setWarning, toastCenterPosition, setError } from '../../../pos-redux/errorHandler/errorHandler.actions';
import { withDrawalGiftCardStatusSuccess } from '../../../pos-redux/payment/giftCard/giftCard.actions';

function VippsPaymentStatus() {
    const [payment, message, vippsPayment, stateOrder, stateMembers, employeeDetail, stateGiftCard] = useSelector(state => [state.payment, state.message, state.vippsPayment, state.product, state.start, state.employeeDetail, state.giftCardPayment]);
    const [isCancelClicked, setCancelClicked] = useState(true);
    const dispatch = useDispatch();
    const history = useHistory();
    const [uiConfiguration] = useSelector(state => [state.uiConfiguration]);

    useEffect(() => {
        setTimeout(function () {
            setCancelClicked(false);
        }, 5000);

        if (vippsPayment.actionType === VippsPaymentConstants.INITIATE_PAYMENT_SUCCESS) {
            dispatch(checkPaymentDetail(vippsPayment.data.orderId));
        }

        checkVippsStatus();
    })


    const checkVippsStatus = async () => {
        if (vippsPayment.actionType === VippsPaymentConstants.PAYMENT_STATUS_SUCCESS
            && String(vippsPayment.data.transactionStatus).toLowerCase() === VippsPaymentConstants.VippsPaymentStatus.Sale) {
            // if (stateMembers.data) {
            //     stateOrder.OrderPos.order.customerNo = stateMembers.data.customerNo;
            // }
            await createOrder(stateOrder.OrderPos, vippsPayment);
        }
    }

    const redirectToPaymentOptions = () => {
        logs(ScreenName.VippsPaymentComponent, ButtonName.Cancel, "cancelPayment, setTransactionProgressMessage", uiConfiguration);
        if (vippsPayment.data.orderId) {
            setCancelClicked(true);
            dispatch(setTransactionProgressMessage(MessageConstants.Vipps.TransactionCancellationStartMsg, false));
            dispatch(cancelPayment(vippsPayment.data.orderId, MessageConstants.Vipps.TranscationSystemName));
        } else {
            dispatch(setPayment(PaymentConstants.PAYMENT_METHOD, true));
        }
    }

    const showPaymentStatusMessage = () => {
        if (message.name === MessageConstants.PAYMENT_TRANSACTION_PROGRESS) {
            return (<p style={{ width: '85%' }} className="text-size">{message.data.message}</p>);
        }
    }

    const createOrder = async (order, vippsPayment) => {
        var ledgerPayments = stateGiftCard?.paymentTransactionModel?.registeredLedgerPayments || [];

        var responseOrder = await orderPosService.orderPOS(order, vippsPayment.data, VippsPaymentConstants.VIPPS, employeeDetail, ledgerPayments);
        if (responseOrder) {
            if (responseOrder.paymentTransactionModel != null) {
                let errorMessage = responseOrder.paymentTransactionModel.errorMessage;
                if (responseOrder.paymentTransactionModel.remainingAmount > 0 || errorMessage) {
                    setWarning(errorMessage, toastCenterPosition);
                    dispatch(initVipps());
                    dispatch(withDrawalGiftCardStatusSuccess(responseOrder.paymentTransactionModel));
                    dispatch(setPayment(PaymentConstants.PAYMENT_METHOD, true));
                    return;
                }
            }

            var type = { posOrder: responseOrder };
            dispatch(setPOSOrder(PaymentConstants.POS_CONFIRM_ORDER, true, type));
            history.push("/greet");
        }
    }

    const showOtherPaymentOptionsLink = () => {
        return (
            <div id="vipps-btn-another" className="welcome-btn-width pt-1 pt-lg-4 py-xl-4">
                <Button
                    disabled={isCancelClicked}
                    stylingMode="contained"
                    className="dx-datagrid-search-text btn btn-cancel-color btn mx-auto w-100-mobile cursor-hand btn-lineHeight" onClick={redirectToPaymentOptions}>
                    {ButtonName.Cancel}
                </Button>
            </div>
        )
    }

    return (
        <div id="vippsPaymentStatus" className="d-flex align-items-center justify-content-center flex-column">
            <div className="betalMobileView d-flex align-items-center justify-content-center">
                <div className="row">
                    <div className="d-flex col-6 m-4">
                        <LazyImage
                            placeholder={`${preLoadImagePath.default_imge_200}`}
                            uri={`${preLoadImagePath.vipps_icon_phone}`}
                            render={(src, style) => <img className="vippsIconPhone right img-200" src={src} alt="img" />}
                        />
                    </div>
                </div>
                <div id="vippsStatusMsg" className="col-6">
                    <div className="row">
                        {showPaymentStatusMessage()}
                    </div>
                    <div className="row">
                        {showOtherPaymentOptionsLink()}
                    </div>
                </div>
            </div>
        </div>
    );
}

export { VippsPaymentStatus }