import { NumberPadConstants } from './numberPad.constants';

const initialState = {
    number: ''
};

function numberPad(state = initialState, action) {
    switch (action.type) {
        case NumberPadConstants.SET_PHONENO:
            return {
                number: action.payload.number
            };

        default:
            return state
    }
}

export { numberPad };