import { CommonUIConstants } from "./commonUI.constant";

const initialState = {
    actionType: '',
    loading: false
};

function commonUIReducer(state = initialState, action) {
    switch (action.type) {        
        case CommonUIConstants.LOADING:
            return {                                
                loading: action.payload
            }

        default:
            return state
    }
}

export { commonUIReducer };