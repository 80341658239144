
import { useDispatch, useSelector } from 'react-redux';
import './barcodeScanFailurePopup.component.scss';
import { setRegisterProduct } from '../../pos-redux/registerProduct/registerProduct.actions';
import { RegisterProductConstants } from '../../pos-redux/registerProduct/registerProduct.constants';
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import { useEffect, useState } from 'react';
import { Button } from 'devextreme-react/button';
import { preLoadImagePath } from '../../helpers/images';
import LazyImage from 'react-lazy-blur-image';
import { initProduct } from '../../pos-redux/registerProduct/product.actions';
import { initSelectedproduct } from '../../pos-redux/registerProduct/addProductWithQty.actions';
import { useHistory } from 'react-router-dom';
import { initEmployee } from '../../pos-redux/employee/employee.actions';
import { setHeader } from '../../pos-redux/header/header.actions';
import { setStart, initStart } from '../../pos-redux/start/start.actions';
import { startConstants } from '../../pos-redux/start/start.constants';
import { headerConstants } from '../../pos-redux/header/header.constants';
import { initRegisterProduct } from '../../pos-redux/registerProduct/registerProduct.actions';
import { ButtonName, ScreenName } from '../../helpers/pos-message';
import { logs } from '../../pos-redux/troubleshoot/troubleshoot.actions';

function ProductStructureComponentPopup() {
    const dispatch = useDispatch();
    const history = useHistory();
    const structureFailureMessage = 'Dette er en sammensatt vare. \n\n Vennligst gjennomfør kjøpet av denne varen i en betjent kasse.';
    const [productSelector, uiConfiguration] = useSelector(state => [state.RegisterProduct, state.uiConfiguration]);
    const confirmTimerTxt = "Her har det ikke skjedd noe på en stund, er du her fortsatt?";
    const [visiblePopup, setVisiblePopup] = useState(true);
    var timeinMS = 120000; //2 minute
    const [timerIntervalId, setTimerIntervalId] = useState(0);

    useEffect(() => {
        if (!productSelector.infoDetail.isStructure) {
            setTimerIntervalId(setTimeout(cancelOrder, timeinMS));
        }
    }, [])

    const hidePopup = () => {
        logs(ScreenName.ProductStructureComponentPopup , "", "Back to "+ RegisterProductConstants.ADD_PRODUCT, uiConfiguration);
        clearTimeout(timerIntervalId);
        setVisiblePopup(false);
        dispatch(setRegisterProduct(RegisterProductConstants.ADD_PRODUCT, true));
    }

    const cancelOrder = () => {        
        logs(ScreenName.ProductStructureComponentPopup , "", "Back to "+ RegisterProductConstants.ADD_PRODUCT, uiConfiguration);
        dispatch(initProduct());
        dispatch(initRegisterProduct());
        dispatch(initSelectedproduct());
        dispatch(initEmployee());
        dispatch(initStart());
        setVisiblePopup(false);
        dispatch(setHeader(headerConstants.START, true));
        dispatch(setStart(startConstants.MEMBER_COMFIRMATION, true));
        history.push("/welcome");
    }

    const structurePopUp = () => {
        return (
            <>
                <h2 className="popUptext">{structureFailureMessage.split('\n').map((txt) => <span><br />{txt}</span>)}</h2>
                <br />
                <div className="text-center">
                    <div className="d-flex h-100 justify-content-center align-items-center">
                        <Button
                            type="success"
                            stylingMode="contained"
                            className="btn btn-primary m-2 px-5 cursor-hand btn-icon btn-font-xs" onClick={hidePopup}>
                            {ButtonName.Ok}
                        </Button>
                    </div>
                </div>
            </>
        )
    }


    const timeOutPopUp = () => {
        return (
            <>
                <LazyImage
                    placeholder={`${preLoadImagePath.default_image_150}`}
                    uri={`${preLoadImagePath.clock_black}`}
                    className="width-150px"
                    render={(src, style) => <img className="img-width-15" src={src} alt="img" />}
                />
                <br />
                <h2 className="popUptext">{confirmTimerTxt}</h2>
                <br />
                <div className="text-center">
                    <div className="d-flex h-100 justify-content-center align-items-center">
                        <Button
                            type="success"
                            stylingMode="contained"
                            className="btn btn-primary m-2 px-5 cursor-hand btn-icon btn-font-xs" onClick={hidePopup}>
                            <LazyImage
                                placeholder={`${preLoadImagePath.default_image_55}`}
                                uri={`${preLoadImagePath.check}`}
                                render={(src, style) => <img src={src} alt="img" className="w-30 me-4 img-45" />}
                            />
                            {ButtonName.Yes}, {ButtonName.Continue}
                        </Button>

                    </div>
                </div>
            </>
        )
    }

    return (
        <Popup
            visible={visiblePopup}
            onHiding={hidePopup}
            height='auto'
            showCloseButton={false}
            showTitle={false}
            container="#pos"
        >
            <div id="barcodeFailurePopup" className="text-center inner-section-height tab-content d-flex align-items-center justify-content-lg-center flex-column">

                {
                    productSelector.infoDetail.isStructure
                        ?
                        structurePopUp()
                        :
                        timeOutPopUp()
                }
            </div>
        </Popup>

    );
}

export { ProductStructureComponentPopup }