import { setWarning } from "../errorHandler/errorHandler.actions"
import { summaryAfterAddProduct } from "./addProductWithQty.actions";
import { ProductConstants } from "./product.constants"
import { setRegisterProduct } from "./registerProduct.actions";
import { RegisterProductConstants } from "./registerProduct.constants";
import { startConstants } from "../start/start.constants";
import { orderPosService } from "../../services/orderPos.service";
import { productGroupService } from "../../services/product-group.service";
import { CustomerConstants } from "../customer/customer.constant";
import { CartQtyEnum } from '../../helpers/pos-message'
import { ArticleScanTypeEnum } from '../../helpers/pos-message';
import { isSpecialProduct } from '../../helpers/helper'

const MaxStock = 10;

//#region Actions
export const addOrderPos = (OrderPos) => {
    let productLines = [];
    if (OrderPos.order.orderLines.length === 0 && OrderPos.initPosOrderLines?.length > 0) {
        productLines = JSON.parse(JSON.stringify(OrderPos.initPosOrderLines)); //Deep copy of object
    } else {
        productLines = JSON.parse(JSON.stringify(OrderPos.order.orderLines)); //Deep copy of object
    }

    //creating init object for handling of order api calling more than once due to gift-card. 
    OrderPos.initPosOrderLines = productLines;

    return {
        type: ProductConstants.ADD_ORDER_POS,
        payload: { OrderPos }
    }
}

export const addProduct = (product, addNewRowExistingProduct) => {
    return {
        type: ProductConstants.ADD_PRODUCT,
        payload: { "product": { ...product }, addNewRowExistingProduct }
    }
}

export const removeProduct = (product) => {
    return {
        type: ProductConstants.REMOVE_PRODUCT,
        payload: product
    }
}

export const updateProductUsedQty = (products) => {
    return {
        type: ProductConstants.UPDATE_PRODUCT_USED_QTY,
        payload: products
    }
}

export const editProductUsedQuantity = (product) => {
    return {
        type: ProductConstants.EDIT_PRODUCT_USED_QTY,
        payload: product
    }
}


export const initProduct = () => {
    return {
        type: ProductConstants.INIT_PRODUCT
    }
}
//#endregion

const productPOSOrder = async (request, productDetailId, usedQuantity, barCode, posOrderLines, customerClub, customer, articleScanType) => {
    var posRequest = null;
    var ccNo = null;
    var cNo = 0;
    var isCCMember = false;

    if (customerClub != null) {
        ccNo = customerClub.clubId;
        isCCMember = customerClub.isCCMember;
    }

    if (customer != null) {
        cNo = customer.customerNo
    }

    if (request) {
        posRequest =
        {
            order: request.order,
            barcode: barCode,
            orderSettingsParameter: null,
            productDetailId: productDetailId,
            quantity: usedQuantity,
            posOrderLines: posOrderLines,
            isProductDeleted: request.isProductDeleted,
            employeeID: request.employeeID,
            lineNo: request.lineNo,
            customerClubNo: ccNo,
            customerNo: cNo,
            isCustomerClubMember: isCCMember,
            lineNoChanged: request.lineNoChanged,
            lineAmountChanged: request.lineAmountChanged,
            isOrderCancel: request.isOrderCancel,
            articleScanTypeID: articleScanType
        };
    } else {
        posRequest =
        {
            order: request,
            barcode: barCode,
            orderSettingsParameter: null,
            productDetailId: productDetailId,
            quantity: usedQuantity,
            posOrderLines: posOrderLines,
            employeeID: request != null ? request.employeeID : 0,
            lineNo: 0,
            customerClubNo: ccNo,
            customerNo: cNo,
            isCustomerClubMember: isCCMember,
            lineNoChanged: request != null ? request.lineNoChanged : 0,
            lineAmountChanged: request != null ? request.lineAmountChanged : 0,
            isOrderCancel: request != null ? request.isOrderCancel : false,            
            articleScanTypeID: articleScanType
        };
    }
    var orderPos = await orderPosService.orderTransaction(posRequest);
    return orderPos;
}

export const posOrderCancel = (posRequest, uiConfig, employeeDetail, productState, customerClub, customer) => async dispatch => {
    posRequest.posOrderLines = posRequest.order.orderLines;
    posRequest.order.orderLines = [];
    posRequest = {
        ...posRequest,
        isOrderCancel: true,
        employeeID: Object.entries(employeeDetail.data).length > 0 ? employeeDetail.data.employeeNumber : 0
    };

    var orderPos = await productPOSOrder(posRequest, 0, 0, "", posRequest.posOrderLines, customerClub, customer);
    return orderPos;
}

export const barcodeProduct = async (barcode) => {
    var product = await productGroupService.getProductByBarcode(barcode);
    return product;
}

const orderDispatch = (orderPos) => async dispatch => {
    if (orderPos) {
        if (orderPos.orderPosStatus != null && orderPos.orderPosStatus.isError) {
            if (orderPos.orderPosStatus.type === 1) {
                dispatch(addOrderPos(orderPos));
                return RegisterProductConstants.CALL_FOR_OPERATIONS;
            }
            else if (orderPos.orderPosStatus.type === 0) {
                return false;
            }
        }
        dispatch(addOrderPos(orderPos));
    }
}

export const customerClubMemberCheck = (stateStart, stateOrder) => {
    var ccMember = null;
    if (stateStart) {
        if (stateStart.actionType === startConstants.CUSTOMERCLUB_MEMBER) {
            if (stateStart.data) {
                ccMember = {
                    clubId: stateStart.data.customerClubID,
                    isCCMember: stateStart.data.isCCMember,
                }
            }
        }
    }

    if (stateOrder.OrderPos && stateOrder.OrderPos.order) {
        if (ccMember) {
            stateOrder.OrderPos.order.checkedCustomerClub = ccMember.isCCMember;
            stateOrder.OrderPos.order.customerClubNo = ccMember.clubId;
        }
    }

    return ccMember;
}

export const customerRfidCheck = (stateStart, stateOrder) => {
    var customer = null;
    if (stateStart) {
        if (stateStart.actionType === CustomerConstants.CUSTOMER_DETAIL_SUCCESS) {
            if (stateStart.data) {
                customer = {
                    customerNo: stateStart.data.customerNo,
                    name: stateStart.data.name,
                }
            }
        }
    }

    if (stateOrder.OrderPos && stateOrder.OrderPos.order) {
        if (customer) {
            stateOrder.OrderPos.order.customerNo = customer.customerNo;
            stateOrder.OrderPos.order.customerName = customer.name;
        }
    }

    return customer;
}

//#region Action Handlers
export const addProductToCart = (product, uiConfig, employeeDetail, productState, customerClub, customer) => async dispatch => {
    var orderStatePos = null;
    var productModel = null;
    var posRequest = null;
    if (productState.OrderPos) {
        posRequest = productState.OrderPos;
        posRequest.employeeID = Object.entries(employeeDetail.data).length > 0 ? employeeDetail.data.employeeNumber : 0;
        orderStatePos = posRequest.order;
        posRequest.posOrderLines = orderStatePos.orderLines;
        posRequest.orderPosStatus = null;
        orderStatePos.orderLines = []; // interface of IPOSLine 
        posRequest.isProductDeleted = false;
        posRequest.lineNo = 0;
        posRequest.lineNoChanged = 0;
        posRequest.lineAmountChanged = 0;
        var prouductIndex = -1;
        if (product.productDetailId) {
            if (uiConfig.addNewRowExistingProduct || isSpecialProduct(product)) { // same product but different lines
                prouductIndex = -1;
            } else {
                prouductIndex = posRequest.posOrderLines.findIndex(x => x.productDetailID === product.productDetailId);
            }
        } else {
            prouductIndex = posRequest.posOrderLines.findIndex(x => x.barcode === product.barCode);
            if (prouductIndex === -1) {
                // get product using barcode , let say multiple barcode with same product, so update quantity of product on basis of productDetailId insetad of barcode.
                var bcProduct = await barcodeProduct(product.barCode);
                if (bcProduct != null) {
                    if (uiConfig.addNewRowExistingProduct || isSpecialProduct(product)) { // same product but different barcode
                        prouductIndex = -1;
                    } else {
                        prouductIndex = posRequest.posOrderLines.findIndex(x => x.productDetailID === bcProduct.productDetailId);
                    }
                }
            }
        }

        if (prouductIndex > -1) {
            productModel = posRequest.posOrderLines[prouductIndex];
            posRequest.isProductDeleted = false;
            posRequest.lineNo = 0;
            posRequest.lineNoChanged = 0;
            posRequest.lineAmountChanged = 0;
            productModel.amount = productModel.amount + product.usedQuantity;
        }
    } else {
        posRequest = productState.OrderPos;
    }

    var articleScanType = !uiConfig.isProductButtonVisible || !product.barCode ? ArticleScanTypeEnum.Scanner : ArticleScanTypeEnum.Submeny;
    var orderPos = null;
    if (productModel) {
        orderPos = await productPOSOrder(posRequest, product.productDetailId, 0, product.barCode, posRequest.posOrderLines, customerClub, customer, articleScanType);
    } else {
        orderPos = await productPOSOrder(posRequest, product.productDetailId, product.usedQuantity, product.barCode, posRequest == null ? [] : posRequest.posOrderLines, customerClub, customer, articleScanType);
    }

    if (orderPos) {
        if (orderPos.orderPosStatus != null && orderPos.orderPosStatus.isError) {
            if (orderPos.orderPosStatus.type === 1) {
                dispatch(addOrderPos(orderPos));
                if (!employeeDetail.validCard) {
                    return RegisterProductConstants.CALL_FOR_OPERATIONS;
                }
            }
            else if (orderPos.orderPosStatus.type === 0) {
                dispatch(addOrderPos(orderPos));
                return false;
            }
            else if (orderPos.orderPosStatus.type === 2) {
                dispatch(addOrderPos(orderPos));
                return RegisterProductConstants.STRUCTURE_PRODUCT_POPUP;
            }
        }
        dispatch(addOrderPos(orderPos));
    }
}

export const increaseProductUsedQty = (products, id, uiConfig, productState, employeeDetail) => async (dispatch) => {
    const selectedProduct = products.find(x => x.productDetailID === id);

    if (MaxStock >= selectedProduct.amount + 1) {
        selectedProduct.amount += 1;
        await dispatch(editProductQty(selectedProduct, uiConfig, productState, employeeDetail, CartQtyEnum.Plus));
    }
    else {
        setWarning(`Sett inn mengde fra 1 og ${MaxStock}`);
    }
}

export const decreaseProductUsedQty = (products, id, uiConfig, productState, employeeDetail) => async (dispatch) => {

    const selectedProduct = products.find(x => x.productDetailID === id);

    if (selectedProduct && (selectedProduct.amount - 1 > 0)) {
        selectedProduct.amount -= 1;
        await dispatch(editProductQty(selectedProduct, uiConfig, productState, employeeDetail, CartQtyEnum.Minus));
    } else {
        await dispatch(deleteProduct(selectedProduct, uiConfig, productState, employeeDetail));
    }
}

export const editProductQty = (product, uiConfig, productState, employeeDetail, editType) => async dispatch => {
    var orderStatePos = null;
    var posRequest = null;
    if (productState.OrderPos) {
        posRequest = productState.OrderPos;
        posRequest.employeeID = Object.entries(employeeDetail.data).length > 0 ? employeeDetail.data.employeeNumber : 0;
        orderStatePos = posRequest.order;
        posRequest.posOrderLines = orderStatePos.orderLines;
        posRequest.orderPosStatus = null;
        orderStatePos.orderLines = []; // interface of IPOSLine         
        var prouductIndex = posRequest.posOrderLines.findIndex(x => x.productDetailID === product.productDetailID && x.lineNo === product.lineNo);
        if (prouductIndex > -1) {
            posRequest.isProductDeleted = false;
            posRequest.lineNo = product.lineNo;
            if (editType === CartQtyEnum.Minus) {
                posRequest.lineNoChanged = posRequest.lineNo;
                posRequest.lineAmountChanged = posRequest.posOrderLines[prouductIndex].amount - product.amount;
            } else {
                posRequest.lineNoChanged = 0;
                posRequest.lineAmountChanged = 0;
            }
            posRequest.posOrderLines[prouductIndex] = product;
        }
    } else {
        posRequest = productState.OrderPos;
    }

    var orderPos = await productPOSOrder(posRequest, product.productDetailID, 0, null, posRequest.posOrderLines);
    dispatch(orderDispatch(orderPos));

};
export const deleteProduct = (product, uiConfig, productState, employeeDetail) => async dispatch => {
    var orderStatePos = null;
    var posRequest = null;
    if (productState.OrderPos) {
        posRequest = productState.OrderPos;
        posRequest.employeeID = Object.entries(employeeDetail.data).length > 0 ? employeeDetail.data.employeeNumber : 0;
        orderStatePos = posRequest.order;
        posRequest.posOrderLines = orderStatePos.orderLines;
        posRequest.orderPosStatus = null;
        orderStatePos.orderLines = []; // interface of IPOSLine  
        var prouductIndex = posRequest.posOrderLines.findIndex(x => x.productDetailID === product.productDetailID && x.lineNo === product.lineNo);
        if (prouductIndex > -1) {
            posRequest.isProductDeleted = true;
            posRequest.lineNo = product.lineNo;
            posRequest.lineNoChanged = 0;
            posRequest.lineAmountChanged = 0;
            posRequest.posOrderLines[prouductIndex] = product;
        }
    } else {
        posRequest = productState.OrderPos;
    }
    var orderPos = await productPOSOrder(posRequest, product.productDetailID, 0, null, posRequest.posOrderLines);
    dispatch(orderDispatch(orderPos));

};
//#endregion