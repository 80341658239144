export const startConstants = {
    MEMBER_COMFIRMATION: 'MEMBER_COMFIRMATION',
    ADD_PHONE_NUMBER: 'ADD_PHONE_NUMBER',
    ADD_CUSTOMER_NUMBER: 'ADD_CUSTOMER_NUMBER',
    SELF_SERVICE_CONFIRMATION: 'SELF_SERVICE_CONFIRMATION',
    ADD_NOT_MEMBER_NUMBER: 'ADD_NOT_MEMBER_NUMBER',
    MEMBER_REGISTER_POPUP: 'MEMBER_REGISTER_POPUP',
    MEMBER_COMMON_MESSAGE_POPUP : 'MEMBER_COMMON_MESSAGE_POPUP',
    //action
    SET_START: 'SET_START',

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',


    CUSTOMERCLUB_MEMBER: 'CUSTOMERCLUB_MEMBER',
    INIT_MEMBER: 'INIT_MEMBER',
    CORPORATE_CUSTOMER_MEMBER: 'CORPORATE_CUSTOMER_MEMBER',
};