import { startConstants } from './start.constants';
import { userConstants } from '../users/user.constants';

const initialState = {
    name: startConstants.MEMBER_COMFIRMATION,
    isActive: true,
    actionType: '',
    loading: false,
    data: {},
    error: ''
};

function start(state = initialState, action) {
    switch (action.type) {
        case startConstants.SET_START:
            const { name, isActive } = action.payload;
            return {
                ...state,
                name: name,
                isActive: isActive
            };
        case startConstants.LOGIN_REQUEST:
            return {
                ...state,
                actionType: action.type,
                loading: true
            }

        case startConstants.LOGIN_SUCCESS:           
            return {
                ...state,
                actionType: action.type,
                loading: false,
                data: action.payload.data,
                error: ''
            }

        case startConstants.LOGIN_FAILURE:
            return {
                ...state,
                actionType: action.type,
                loading: false,
                data: {},
                error: action.payload.data
            }

            case startConstants.CUSTOMERCLUB_MEMBER:             
            return {
                ...state,
                actionType: action.type,
                loading: false,
                data: action.payload.data,
                error: ''
            }
            case startConstants.INIT_MEMBER:
                return initialState;

            case startConstants.CORPORATE_CUSTOMER_MEMBER:           
            return {
                ...state,
                actionType: action.type,
                loading: false,
                data: action.payload.data,
                error: ''
            }
        default:
            return state
    }
}

export { start };