import { vippsPaymentService } from '../../../services/vippsPayment.service';
import { delay } from '../../../helpers/helper'
import { setTransactionProgressMessage } from '../../message/message.actions'
import { VippsPaymentConstants } from './vippsPayment.constant';
import { MessageConstants } from '../../message/message.constants';
import { PaymentConstants } from '../payment.constants';
import { setPayment } from '../payment.actions';
import { setNumber } from '../../base/numberPad.action';
import { setError } from '../../errorHandler/errorHandler.actions';

//#region Actions
export const initVipps = () => {
    return {
        type: VippsPaymentConstants.INIT_VIPPS
    }
}

export const initiatePaymentRequest = () => {
    return {
        type: VippsPaymentConstants.INITIATE_PAYMENT_REQUEST
    }
}

export const initiatePaymentSuccess = response => {
    return {
        type: VippsPaymentConstants.INITIATE_PAYMENT_SUCCESS,
        payload: response
    }
}

export const initiatePaymentFailure = error => {
    return {
        type: VippsPaymentConstants.INITIATE_PAYMENT_FAILURE,
        payload: error
    }
}

export const paymentStatusRequest = () => {
    return {
        type: VippsPaymentConstants.PAYMENT_STATUS_REQUEST
    }
}

export const paymentStatusSuccess = response => {
    return {
        type: VippsPaymentConstants.PAYMENT_STATUS_SUCCESS,
        payload: response
    }
}

export const paymentStatusFailure = error => {
    return {
        type: VippsPaymentConstants.PAYMENT_STATUS_FAILURE,
        payload: error
    }
}

export const cancelPaymentRequest = () => {
    return {
        type: VippsPaymentConstants.CANCEL_PAYMENT_REQUEST
    }
}

export const cancelPaymentSuccess = response => {
    return {
        type: VippsPaymentConstants.CANCEL_PAYMENT_SUCCESS,
        payload: response
    }
}

export const cancelPaymentFailure = error => {
    return {
        type: VippsPaymentConstants.CANCEL_PAYMENT_FAILURE,
        payload: error
    }
}

export const PendingPaymentRequest = () => {
    return {
        type: VippsPaymentConstants.PENDING_PAYMENT_REQUEST
    }
}

export const PendingPaymentSuccess = response => {
    return {
        type: VippsPaymentConstants.PENDING_PAYMENT_SUCCESS,
        payload: response
    }
}

export const PendingPaymentFailure = error => {
    return {
        type: VippsPaymentConstants.PENDING_PAYMENT_FAILURE,
        payload: error
    }
}

//#endregion

export const getVippsConfig = () => async dispatch => {
    const response = await vippsPaymentService.vippsConfig();
    return response;
}

//#region  Action Handlers
export const initiatePayment = (amount, mobileNumber, transactionText, posNo, transactionModuleId) => async (dispatch) => {
    dispatch(setPayment(PaymentConstants.INITIATE_VIPPS_PAYMENT, true));
    dispatch(setTransactionProgressMessage(MessageConstants.Vipps.TransactionApprovalWaitMsg, true));

    const isDuplicateTransaction = await checkDuplicateTransaction(amount, mobileNumber, dispatch)
    if (isDuplicateTransaction) return;

    dispatch(initiatePaymentRequest());
    const response = await vippsPaymentService.initiatePayment(amount, mobileNumber, transactionText, posNo, transactionModuleId);

    if (response && response.data) {
        dispatch(initiatePaymentSuccess(response.data));
    }
    else {
        handleError(response, initiatePaymentFailure, dispatch);
    }
}

export const checkPaymentDetail = orderId => async dispatch => {
    dispatch(paymentStatusRequest());
    const response = await vippsPaymentService.checkPaymentDetail(orderId);

    if (response && response.data.operationSuccess) {
        const status = String(response.data.transactionStatus).toLowerCase();
        if (initPolling(status, dispatch)) {
            await delay(2000);
            dispatch(checkPaymentDetail(orderId));
        }

        dispatch(paymentStatusSuccess(response.data));
    }
    else {
        dispatch(setTransactionProgressMessage(MessageConstants.Vipps.TryAgainMsg, false));
        handleError(response, paymentStatusFailure, dispatch);
    }
}

export const cancelPayment = (orderId, transactionText) => async dispatch => {
    const response = await vippsPaymentService.cancelPayment(orderId, transactionText);

    if (response && response.data && response.data.transactionInfo) {
        const status = String(response.data.transactionInfo.Status).toLowerCase();

        if (status === VippsPaymentConstants.VippsPaymentStatus.Cancel
            || status === VippsPaymentConstants.VippsPaymentStatus.Cancelled) {
            dispatch(setTransactionProgressMessage(MessageConstants.Vipps.TransactionCancelledMsg, false));
            dispatch(setPayment(PaymentConstants.COMPLETE_VIPPS_PAYMENT, true));
            dispatch(setPayment(PaymentConstants.PAYMENT_METHOD, true));
            dispatch(setNumber(''));
        }
        else {
            dispatch(checkPaymentDetail(orderId));
        }
    }
    else {
        dispatch(checkPaymentDetail(orderId));
    }
}
//#endregion

//#region  Private Functions
function initPolling(status, dispatch) {
    switch (status) {
        case VippsPaymentConstants.VippsPaymentStatus.Cancelled: {
            dispatch(setTransactionProgressMessage(MessageConstants.Vipps.TransactionCancelledMsg, false));
            dispatch(setPayment(PaymentConstants.PAYMENT_METHOD, true));
            dispatch(setNumber(''));
            return;
        }

        case VippsPaymentConstants.VippsPaymentStatus.Cancel: {
            dispatch(setTransactionProgressMessage(MessageConstants.Vipps.TransactionCancelledMsg, false));
            dispatch(setPayment(PaymentConstants.PAYMENT_METHOD, true));
            dispatch(setNumber(''));
            return;
        }

        case VippsPaymentConstants.VippsPaymentStatus.Failed: {
            dispatch(setTransactionProgressMessage(MessageConstants.Vipps.TransactionFailedMsg, false));
            break;
        }

        case VippsPaymentConstants.VippsPaymentStatus.Rejected: {
            dispatch(setTransactionProgressMessage(MessageConstants.Vipps.TransactionRejectedMsg, false));
            break;
        }

        case VippsPaymentConstants.VippsPaymentStatus.Reserve: {
            dispatch(setTransactionProgressMessage(MessageConstants.Vipps.TransactionReservedMsg, false));
            break;
        }

        case VippsPaymentConstants.VippsPaymentStatus.Refund: {
            dispatch(setTransactionProgressMessage(MessageConstants.Vipps.PaymentRefundedMsg, true));
        }

        case VippsPaymentConstants.VippsPaymentStatus.Sale: {
            dispatch(setTransactionProgressMessage(MessageConstants.Vipps.TransactionSuccessfulMsg, true));
        }

        case VippsPaymentConstants.VippsPaymentStatus.Initiate: {
            dispatch(setTransactionProgressMessage(MessageConstants.Vipps.TransactionApprovalWaitMsg, false));
        }
    }

    if (status === VippsPaymentConstants.VippsPaymentStatus.Initiate) {
        return true;
    }
    else {
        dispatch(setPayment(PaymentConstants.COMPLETE_VIPPS_PAYMENT, true));
        dispatch(setNumber(''));
        return false;
    }
}

async function checkDuplicateTransaction(amount, mobileNumber, dispatch) {
    dispatch(PendingPaymentRequest());
    const pendingTransaction = await vippsPaymentService.checkPendingTransaction(amount, mobileNumber);

    if (pendingTransaction && pendingTransaction.data) {
        dispatch(PendingPaymentSuccess(pendingTransaction.data));
        const pendingTransactionStatus = String(pendingTransaction.data.transactionStatus).toLowerCase();

        if (pendingTransactionStatus === VippsPaymentConstants.VippsPaymentStatus.Initiate) {
            dispatch(checkPaymentDetail(pendingTransaction.orderId));
            return true;
        }

        return false;
    }
    else {
        dispatch(PendingPaymentFailure(pendingTransaction));
    }

    return false;
}

function handleError(error, action, dispatch) {
    dispatch(action(error));

    if (error === "User is not registered with VIPPS") {
        dispatch(setPayment(PaymentConstants.VIPPS_MEMBER_POPUP, true));
    }
    else {
        dispatch(setError(error, PaymentConstants.PAYMENT_METHOD));
        dispatch(setPayment(PaymentConstants.PAYMENT_METHOD, true));
    }
}
//#endregion
