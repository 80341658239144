import { TerminalConstants } from "./terminal.constants";

const initialState = {
    actionType: '',
    loading: false,
    data: [],
    error: ''
};

function terminalPayment(state = initialState, action) {
    switch (action.type) {
        case TerminalConstants.TERMINAL_INITIATE_PAYMENT_REQUEST:
            return {
                ...state,
                actionType: action.type,
                loading: true
            }

        case TerminalConstants.TERMINAL_INITIATE_PAYMENT_SUCCESS:
            return {
                actionType: action.type,
                loading: false,
                data: action.payload,
                error: ''
            }

        case TerminalConstants.TERMINAL_INITIATE_PAYMENT_ERROR:
            return {
                actionType: action.type,
                loading: false,
                data: [],
                error: action.payload
            }

        case TerminalConstants.TERMINAL_PAYMENT_STATUS_REQUEST:
            return {
                ...state,
                actionType: action.type,
                loading: true
            }

        case TerminalConstants.TERMINAL_PAYMENT_STATUS_SUCCESS:
            return {
                actionType: action.type,
                loading: false,
                data: action.payload,
                error: ''
            }

        case TerminalConstants.TERMINAL_PAYMENT_STATUS_ERROR:
            return {
                actionType: action.type,
                loading: false,
                data: [],
                error: action.payload
            }
        case TerminalConstants.INIT_TERMINAL:
            return {
                initialState
            }
        default:
            return state
    }
}

export { terminalPayment };