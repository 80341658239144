export const MessageConstants = {
    PAYMENT_TRANSACTION_PROGRESS: 'PAYMENT_TRANSACTION_PROGRESS',

    Vipps: {
        TransactionCancelledMsg: 'Transaksjonen er avbrutt',
        TransactionFailedMsg: 'Betaling mislyktes på grunn av ingen dekning, utdaterte kortdetaljer eller lignende',
        TransactionRejectedMsg: 'Transaksjonen avvist på grunn av ingen brukerhandling i Vipps-appen',
        TransactionReservedMsg: 'Vipps er ikke konfigurert riktig. Kontakt administrator',
        PaymentRefundedMsg: 'Betalingen er refundert',
        TransactionSuccessfulMsg: 'Betalingen var vellykket',
        TransactionApprovalWaitMsg: 'Godkjenn betalingen i Vipps-appen på din mobil',
        TransactionCancellationStartMsg: 'Avbrytelse av transaksjonen startet',
        TryAgainMsg: 'Noe gikk galt. Prøv igjen senere.',
        TranscationSystemName : 'Betaling i selvbetjent kasse',
        VippsConfigurationNotExists : 'Vipps autentiseringskonfigurasjon ble ikke funnet.'
    },

    Terminal: {
        CompletePaymentWarningMsg: 'Fullfør betalingen på bankterminalen...',
        InserCardWarningMsg: 'Venter fortsatt på betaling... \n Sett inn eller tæpp kortet og tast koden'        
    }
}