import { WeightScalingConstants } from '../../troubleshoot/weightScaling/weightScaling.constants';

const initialState = { actionType: '', loading: false, data: [], error: '' }

function weightScalingReducer(state = initialState, action) {
    switch (action.type) {
        case WeightScalingConstants.GET_REQUEST:
            return {
                ...state,
                actionType: action.type,
                loading: true
            };
        case WeightScalingConstants.GET_SUCCESS:
            return {
                actionType: action.type,
                loading: false,
                data: action.payload,
                error: ''
            }
        case WeightScalingConstants.GET_FAILURE:
            return {
                actionType: action.type,
                loading: false,
                data: [],
                error: action.payload
            }
        case WeightScalingConstants.INIT_WEIGHTSCALING:
            return initialState;
        default:
            return state;
    }
}

export { weightScalingReducer };