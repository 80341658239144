import { ErrorHandlerConstants } from "./errorHandler.constants";

const initState = {
    error: null,
    isOpen: false
};

export function errorHandler(state = initState, action) {
    const { error } = action;

    if (error) {
        return {
            error: error,
            isOpen: true
        }
    }
    else if (action.type === ErrorHandlerConstants.HIDE_ERROR) {
        return {
            error: null,
            isOpen: false
        }
    }

    return state;
}