import { UIConfigurationConstants } from '../../pos-redux/uiConfiguration/uiConfiguration.constants';
import { ProductEditButton } from '../../pos-redux/registerProduct/productEditButton.constants';
import { ProductGroupConfiguration } from '../../pos-redux/registerProduct/productGroupConfiguration.constants';
import { localStorageService } from '../../services/api/utilities/localStorageService';
import { createStore } from "redux";

const maximumProductAmountLimit = 5000;
const maximumProductLimit = 15;

const getPosItems = () => {
    return localStorageService.getPOSConfiguration();
}

const initialState = {
    welcomeText: 'Velkommen til',
    welcomeLogo: '',
    themeColor: '',
    isStartTabVisible: true,
    selfServiceConfirmationHeader: 'Vi har jevnlig kontroll av \n handel i selvbetjent kasse!',
    memberConfirmationHeader: "Er du medlem hos oss?",
    addPhoneNumberHeader: "Registerer kjøp i kundeklubben ved å \n skrive inn ditt mobilnummer eller tappe/sette inn ditt kort",
    addCustomerNumberHeader: "Skriv inn ditt kundenummer eller skann kundekortet.",
    addFreeCustomerNumberHeader: "Skriv inn ditt mobilnummer for å bli medlem i vår gratis kundeklubb",
    productEditButtonVisible: ProductEditButton.ShowEditButton,
    productGroupColumn: 4,
    productGroupRows: 3,
    productGroupConfiguration: ProductGroupConfiguration.ShowAll,
    maximumProductCountLimit: maximumProductLimit,
    maximumProductAmountLimit: maximumProductAmountLimit,
    maximumProductCountLimitMessage: "Maks antall produkter i \n selvbetjent kasse er " + maximumProductLimit + " stk. \n\n Tilkall butikkmedarbeider for å få godkjent kjøpet.",
    maximumProductAmountLimitMessage: "Maksbeløpet i \n selvbetjent kasse er " + maximumProductAmountLimit + " kr. \n\n Tilkall betjening for å få godkjent handelen.",
    greetHeader: "Takk for handelen \n og velkommen igjen!",
    greetSubHeader: "Skann kvitteringen for å åpne porten",
    loading: false,
    error: "",
    addNewRowExistingProduct: false,
    barcodeFailureMessage: 'Vi finner ikke strekkoden du skannet. \n\n Betjeningen er varslet og vil hjelpe deg så snart som mulig',
    hasMemberScreen: false,
    greetScreenTimerInSecond: 5,
    paymentGatewayTimerInSecond: 30,
    // Assortment realted changes 
    skipBagSelector: false,
    vippsPaymentAvailable: false,
    addPaymentButtonInProducts: false,
    skipProductAmountSelection: true,
    stayInProductSelector: false,
    showCartButtonAtTop: false,
    rootAssortmentId: 0,
    assortmentGridColumns: 5,
    includePreviousChildAssortment: false,
    orderCancelButtonVisible: true,
    skipOrderViewFromConfirmationPopUp: false,
    isBankTerminalConnectionExists: false,
    isClosedSelfServiceSystem: false,
    idealTimerAtCartWindowInMinute: 0,
    messageOnClosedService: "Velkommen tilbake ved en annen anledning \n\n Telefon: 55 50 88 00",
    allowCustomerCredit: 0,
    customerConfirmationHeader: "Har du ansattkonto hos oss?",
    isPosUILogEnabled: false,
    skipConfirmGodtaPopup: false,
    welcomeSubTitle: 'Vakttelefon og kjøpshjelp: 404 83 440',
    isCallAssistanceButtonVisible: false,
    productButtonName: '',
    isProductButtonVisible: true,
    isGiftCardButtonVisible: false,
    isLinkedProductEditable : false
};

function uiConfiguration(state = initialState, action) {
    var posConfig = getPosItems();
    if (posConfig != null) {
        state = posConfig;
    }
    switch (action.type) {
        case UIConfigurationConstants.GET_REQUEST:
            return {
                ...state,
                loading: true
            };
        case UIConfigurationConstants.GET_SUCCESS:
            state = action.payload;
            return {
                ...state,
                loading: false,
            }
        case UIConfigurationConstants.GET_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return {
                ...state
            }

    }
}



const store = createStore(uiConfiguration);

store.subscribe(() => {
});


export { uiConfiguration };