
import { apiProvider } from './api/utilities/provider';
import { ApiUrlConstants } from './api/apiUrl.constants';

export const assortmentService = {
    getParentRootAssortment,
    getProductByAssortment,
    getAssortment
};

function getParentRootAssortment(parentId) {
    return apiProvider.fetch.getSingle(ApiUrlConstants.RetailLink.AssortmentWithParentIdEndPoint, parentId);
}

function getProductByAssortment(assortmentID) {
    return apiProvider.fetch.getSingle(ApiUrlConstants.RetailLink.SubAssortmentsAndProductsEndPoint, assortmentID);
}

function getAssortment(assortmentID) {
    return apiProvider.fetch.getSingle(ApiUrlConstants.RetailLink.AssortmentEndPoint, assortmentID);
}