import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { startConstants } from '../../../pos-redux/start/start.constants';
import { Button } from 'devextreme-react/button';
import LazyImage from 'react-lazy-blur-image';
import { preLoadImagePath } from '../../../helpers/images';
import { setStart, getCustomerDetailByInfo } from '../../../pos-redux/customer/customer.action';
import BarcodeReader from 'react-barcode-reader'
import { setWarning, toastCenterPosition, setError } from '../../../pos-redux/errorHandler/errorHandler.actions';
import { initCustomer } from '../../../pos-redux/customer/customer.action';
import { logs } from '../../../pos-redux/troubleshoot/troubleshoot.actions';
import { ScreenName } from '../../../helpers/pos-message';

function CustomerRegisterPurchaseCompenent() {
    const dispatch = useDispatch();
    const [uiConfiguration] = useSelector(state => [state.uiConfiguration]);
    const clickNotEmployee = () => {
        logs(ScreenName.CustomerRegisterPurchaseCompenent, "RfidScan.", "clickNotEmployee", uiConfiguration);
        dispatch(initCustomer());
        dispatch(setStart(startConstants.SELF_SERVICE_CONFIRMATION, true));
    }
    useEffect(() => {
        //handleRfidScan('560458');
    },[])
    const handleRfidScan = async (val) => {
        logs(ScreenName.CustomerRegisterPurchaseCompenent, "RfidScan.", "handleRfidScan", uiConfiguration);
        dispatch(getCustomerDetailByInfo(val));
    }

    const handleRfidError = (err) => {
        logs(ScreenName.CustomerRegisterPurchaseCompenent, "RfidScan.", "handleRfidError", uiConfiguration);
        setWarning(err, toastCenterPosition);
    }

    return (
        <div id="memberConfirmationComponent" className="text-center inner-section-height tab-content d-flex align-items-center justify-content-lg-center flex-column">
            <h2 className="mb-2 mb-md-4 mb-xl-4 new-line">Registrer kjøp på <br /> ansattkonto ved å <br /> «tappe» ditt nøkkelkort.</h2>
            <LazyImage
                placeholder={`${preLoadImagePath.Rfid}`}
                uri={`${preLoadImagePath.Rfid}`}
                render={(src, style) => <img src={src} alt="img" />}
            />
            <div className="text-center pt-0 pt-lg-4 py-xl-4">
                <Button
                    stylingMode="contained"
                    className="btn btn-primary mx-auto w-100-mobile cursor-hand btn-icon" onClick={clickNotEmployee}>
                    <LazyImage
                        placeholder={`${preLoadImagePath.default_image_55}`}
                        uri={`${preLoadImagePath.not_user}`}
                        render={(src, style) => <img src={src} alt="img" className="w-50 me-3 img-50" />}
                    />
                    Har ikke nøkkelkort
                </Button>
            </div>
            <BarcodeReader
                onError={handleRfidError}
                onScan={handleRfidScan}
                minLength={2}
            />
        </div>

    );
}

export { CustomerRegisterPurchaseCompenent }