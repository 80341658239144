import { giftCardService } from '../../../services/giftCard.service'
import { GiftCardConstants } from '../../payment/giftCard/giftCard.constants';

export const initGiftCard = () => {
    return {
        type: GiftCardConstants.INIT_GIFTCARD
    }
}

export const initiateGiftCardPaymentRequest = () => {
    return {
        type: GiftCardConstants.GIFTCARD_INITIATE_PAYMENT_REQUEST
    }
}

export const giftCardStatusSuccess = response => {
    return {
        type: GiftCardConstants.GIFTCARD_INITIATE_PAYMENT_SUCCESS,
        payload: response
    }
}

export const withDrawalGiftCardPaymentRequest = () => {
    return {
        type: GiftCardConstants.WITHDRAWAL_GIFTCARD_STATUS_REQUEST
    }
}

export const withDrawalGiftCardStatusSuccess = response => {
    return {
        type: GiftCardConstants.WITHDRAWAL_GIFTCARD_PAYMENT_SUCCESS,
        payload: response
    }
}

export const checkGiftCardNumber = (cardNo, giftCardLines) => async dispatch => {
    dispatch(initiateGiftCardPaymentRequest());
    const response = await giftCardService.getGiftCardNumber(cardNo);
    if (response) {
        var payload = {
            cardDetail: response,
            registeredLedgerPayments: giftCardLines
        }
        dispatch(giftCardStatusSuccess(payload));
    }

    return response;
}

export const withDrawFromGiftCard = (giftCardWithdrawal) => async dispatch => {
    dispatch(withDrawalGiftCardPaymentRequest()); 
    const response = await giftCardService.withdrawal(giftCardWithdrawal);  
    if (response) {
        dispatch(withDrawalGiftCardStatusSuccess(response));
    }

    return response;
}