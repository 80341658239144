import React from 'react';
import { useSelector } from 'react-redux';
import { PaymentConstants } from '../../pos-redux/payment/payment.constants';
import { AddPaymentMethod } from './paymentMethod.component';
import { PaymentInvoice } from './paymentInvoice.component';
import { VippsPayment } from './vipps/vippsPayment.component';
import { VippsPaymentStatus } from './vipps/vippsPaymentStatus.component';
import { BankTerminalWaiting } from './terminal/bankTerminalWaiting.component';
import { VippsRegisterPopUpComponent } from '../popup/vippsRegisterPopup.component';
import { GiftCardScanCompenent } from './gift-card/giftCardScan.component';
import { GiftCardPopupComponent } from '../popup/giftCardPopup.component';
import { GiftCardPaymentComponent } from './gift-card/giftCardPayment.component';

function PaymentComponent() {
    const [payment] = useSelector(state => [state.payment]);
    return (
        <div>
            {payment.name === PaymentConstants.PAYMENT_METHOD && payment.isActive ? <AddPaymentMethod /> : ''}
            {payment.name === PaymentConstants.BANK_TERMINAL_WAITING && payment.isActive ? <BankTerminalWaiting /> : ''}
            {(payment.name === PaymentConstants.INITIATE_VIPPS_PAYMENT || payment.name === PaymentConstants.COMPLETE_VIPPS_PAYMENT) && payment.isActive ? <VippsPaymentStatus /> : ''}
            {payment.name === PaymentConstants.PAYMENT_INVOICE && payment.isActive ? <PaymentInvoice /> : ''}
            {payment.name === PaymentConstants.REGISTER_VIPS && payment.isActive ? <VippsPayment /> : ''}
            {payment.name === PaymentConstants.VIPPS_MEMBER_POPUP && payment.isActive ? <VippsRegisterPopUpComponent /> : ''}
            {payment.name === PaymentConstants.GIFT_CARD_SCAN && payment.isActive ? <GiftCardScanCompenent /> : ''}
            {payment.name === PaymentConstants.POPUP_FAILURE && payment.isActive ? <GiftCardPopupComponent /> : ''}
            {payment.name === PaymentConstants.GIFT_CARD_PAYMENT && payment.isActive ? <GiftCardPaymentComponent /> : ''}
        </div>
    );
}

export { PaymentComponent };