import { apiProvider } from './api/utilities/provider';
import { ApiUrlConstants } from './api/apiUrl.constants';


async function getUIConfiguration(version) {
    return await apiProvider.fetch.getSingle(ApiUrlConstants.RetailLink.UIConfigurationEndPoint, version);
}

async function avaibleLight(action) {
    return apiProvider.fetch.getSingle(ApiUrlConstants.POSAgent.LightTowerEndPoint, "?available=" + action, true);
}

async function occupiedLight(action) {
    return apiProvider.fetch.getSingle(ApiUrlConstants.POSAgent.LightTowerEndPoint, "?occupied=" + action, true);
}

async function assistanceLight(action) {
    return apiProvider.fetch.getSingle(ApiUrlConstants.POSAgent.LightTowerEndPoint, "?assistance=" + action, true);
}

export const UIConfigurationService = {
    getUIConfiguration,
    avaibleLight,
    occupiedLight,
    assistanceLight
};