import { selectBagService } from "../../services/selectBag.service";
import { setWarning } from "../errorHandler/errorHandler.actions";
import { SelectBagConstants } from "./selectBag.constants";
import { addProductToCart, editProductQty, deleteProduct } from '../registerProduct/product.actions';
import { CartQtyEnum } from '../../helpers/pos-message'

//#region Actions
export const initBags = () => {
    return {
        type: SelectBagConstants.INIT_BAGS
    }
}

export const getBagTypesRequest = () => {
    return {
        type: SelectBagConstants.BAG_TYPE_STATUS_REQUEST
    }
}

export const getBagTypesSuccess = response => {
    return {
        type: SelectBagConstants.BAG_TYPE_STATUS_SUCCESS,
        payload: response.map(obj => ({ ...obj, usedQuantity: 0 }))
    }
}

export const getBagTypesFailure = error => {
    return {
        type: SelectBagConstants.BAG_TYPE_STATUS_FAILURE,
        payload: error
    }
}

export const updateBagTypeUsedQty = data => {
    return {
        type: SelectBagConstants.BAG_TYPE_USED_QTY_UPDATE,
        payload: data
    }
}

export const showLoader = data => {
    return {
        type: SelectBagConstants.BAG_LOADING,
        payload: data
    }
}
//#endregion

//#region Action Handlers
export const getBagTypes = () => async dispatch => {
    dispatch(getBagTypesRequest());
    const response = await selectBagService.getBagTypes();
    if (response) {
        dispatch(getBagTypesSuccess(response));
    }
    else {
        dispatch(getBagTypesFailure());
    }
}

export const increaseUsedQty = (bagTypesArr, name, uiConfiguration, employeeDetail, stateOrder) => async dispatch => {
    const index = bagTypesArr.findIndex(bt => bt.name === name);
    const updatedBagTypes = [...bagTypesArr];

    if (updatedBagTypes[index].maxAllowedQty >= updatedBagTypes[index].usedQuantity + 1) {
        updatedBagTypes[index].usedQuantity += 1;

        var product = {
            productDetailId: bagTypesArr[index].id,
            usedQuantity: bagTypesArr[index].usedQuantity
        }
        if (stateOrder.OrderPos.order.orderLines) {
            var prouductIndex = stateOrder.OrderPos.order.orderLines.findIndex(x => x.productDetailID === product.productDetailId);
            dispatch(showLoader(true));

            if (prouductIndex === -1) {                
                await dispatch(addProductToCart(product, uiConfiguration, employeeDetail, stateOrder));
            } else {
                let lineProduct = JSON.parse(JSON.stringify(stateOrder.OrderPos.order.orderLines[prouductIndex])); //Deep copy of object
                lineProduct.amount = product.usedQuantity;

                await dispatch(editProductQty(lineProduct, uiConfiguration, stateOrder, employeeDetail, CartQtyEnum.Plus));
            }
            dispatch(showLoader(false));
        }
    }
    else {
        setWarning(`Sett inn mengde fra 0 og ${updatedBagTypes[index].maxAllowedQty}`);
    }
}

export const decreaseUsedQty = (bagTypesArr, name, uiConfiguration, employeeDetail, stateOrder) => async dispatch => {
    const index = bagTypesArr.findIndex(bt => bt.name === name);
    const updatedBagTypes = [...bagTypesArr];

    if (updatedBagTypes[index].usedQuantity - 1 >= 0) {
        updatedBagTypes[index].usedQuantity -= 1;

        var product = {
            productDetailId: bagTypesArr[index].id,
            usedQuantity: bagTypesArr[index].usedQuantity
        }
        if (stateOrder.OrderPos.order.orderLines) {
            var prouductIndex = stateOrder.OrderPos.order.orderLines.findIndex(x => x.productDetailID === product.productDetailId);
            dispatch(showLoader(true));

            if (prouductIndex > -1) {                
                let lineProduct = JSON.parse(JSON.stringify(stateOrder.OrderPos.order.orderLines[prouductIndex])); //Deep copy of object                
                if (product.usedQuantity <= 0) {                    
                    await dispatch(deleteProduct(lineProduct, uiConfiguration, stateOrder, employeeDetail));
                } else {
                    lineProduct.amount = product.usedQuantity;
                    await dispatch(editProductQty(lineProduct, uiConfiguration, stateOrder, employeeDetail, CartQtyEnum.Minus));
                }
            }
            dispatch(showLoader(false));
        }
    }
    else {
        setWarning(`Sett inn mengde fra 0 og ${updatedBagTypes[index].maxAllowedQty}`);
    }
}

export const resetBagQty = (bagTypesArr) => dispatch => {
    bagTypesArr.forEach(function (bagType) {
        bagType.usedQuantity = 0;
    });
}
//#endregion