import { useDispatch, useSelector } from 'react-redux';
import { startConstants } from '../../pos-redux/start/start.constants';
import { setStart, initStart } from '../../pos-redux/start/start.actions';
import './addCustomerNumber.component.scss';
import { Button } from 'devextreme-react/button';
import { NumberPad } from '../base/numberPad.component';
import { setNumber } from '../../pos-redux/base/numberPad.action';
import { useEffect } from 'react';
import { setWarning, toastCenterPosition } from '../../pos-redux/errorHandler/errorHandler.actions';
import { regxNumber } from '../../helpers/helper';
import { ButtonName, posMessage, ScreenName } from '../../helpers/pos-message';
import { preLoadImagePath } from '../../helpers/images';
import { logs } from '../../pos-redux/troubleshoot/troubleshoot.actions';

function AddFreeCustomerNumberComponent() {
    const dispatch = useDispatch();
    const numberpad = useSelector(state => state.numberPad);

    const uiConfiguration = useSelector(state => state.uiConfiguration);
    const addCustomerNumberTxt = uiConfiguration.addFreeCustomerNumberHeader;

    useEffect(() => {
        dispatch(setNumber(''));
    }, []);

    const clickOk = () => {
        logs(ScreenName.AddFreeCustomerNumberComponent, ButtonName.BecomeMember, startConstants.MEMBER_REGISTER_POPUP + numberpad.number, uiConfiguration);
        if (numberpad.number.length == 8) {
            dispatch(initStart());
            dispatch(setStart(startConstants.MEMBER_REGISTER_POPUP, true));
        }
        else {
            setWarning(posMessage.lengthShouldBeEight, toastCenterPosition);
        }
    }

    const clickNo = () => {
        logs(ScreenName.AddFreeCustomerNumberComponent, ButtonName.NoThanks, startConstants.SELF_SERVICE_CONFIRMATION, uiConfiguration);
        dispatch(setStart(startConstants.SELF_SERVICE_CONFIRMATION, true));
    }

    const numberChange = (val) => {
        if (val === '' || regxNumber.test(val)) {
            numberpad.number = val;
            dispatch(setNumber(numberpad.number));

            if (numberpad.number.length == 8) {
                dispatch(initStart());
                dispatch(setStart(startConstants.MEMBER_REGISTER_POPUP, true));
            }
        }
    }

    return (


        <div id="addFreeCustomerNumberComponent" class="inner-section-height d-flex align-items-center justify-content-center">
            <div class="text-center max-50 dx-theme-generic-typography">
                <h2 className="dx-font-m dx-font-xs new-line">{addCustomerNumberTxt}</h2>
                <div className="my-4 pt-4 d-flex keyPadWrapper">
                    <div className="inputGroupWrapper">
                        <div className="inputGroup">
                            <img className="" src={`${preLoadImagePath.telephone}`} alt="img" />
                            <input ref={(input) => { input && input.focus() }} placeholder="Mobilnummer" className="dx-theme-background-color inputText dx-theme-border-color" type="text" value={numberpad.number} onChange={e => numberChange(e.target.value)} />
                        </div>
                        <div className="keyPadLeftSidebutton flex-column ps-lg-3 ps-lg-4">
                            <Button
                                type="success"
                                stylingMode="contained"
                                className="btn btn-primary mx-auto px-5 cursor-hand justify-content-center btn-icon" onClick={clickOk}> {ButtonName.BecomeMember}</Button>

                            <Button
                                stylingMode="contained"
                                className="btn btn-primary mx-auto px-5 mt-3 cursor-hand justify-content-center btn-icon"
                                onClick={clickNo}> {ButtonName.NoThanks}</Button>

                        </div>
                    </div>
                    <NumberPad screen="freeCustomerNumber"></NumberPad>
                </div>
            </div>
        </div>

    );
}

export { AddFreeCustomerNumberComponent }