import { localStorageService } from './localStorageService';
import notify from 'devextreme/ui/notify';
import { isMobile } from 'react-device-detect';

export function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);

        if (!response.ok) {
            if (response.status === 401) {
                handleResponseError(response);
            }

            const error = (data && data.message) || response.statusText;
            toast(error, 'warning');
            
            const errorReason = data && data?.reason;
            if (errorReason)
                console.error(errorReason);

            return Promise.reject(error);
        }

        return data;
    }).catch(error => {
        handleError(error);
        return Promise.reject(error);
    });
}

export function handleAxiosResponse(response) {
    try {
        if (!response.status === 200) {
            handleResponseError(response);
            const error = response.statusText;
            return Promise.reject(error);
        }

        return response.data;
    }
    catch (error) {
        handleError(error);
        return Promise.reject(error);
    }
}

function handleResponseError(response) {
    localStorageService.clearStorage();
    window.location.reload(true);
    console.log("HTTP error, status = " + response.status + ", status text = " + response.statusText);
}

function handleError(error) {
    console.log(error.message);
}

function toast(message, infoType) {
    if (!isMobile) {
        notify({
            message: message,
            position: {
                my: 'center top',
                at: 'center top'
            }
        }, infoType, 5000);
    } else {
        notify({
            message: message,
            position: {
                my: "bottom center",
                at: "bottom center",
            }
        }, infoType, 5000);
    }
}