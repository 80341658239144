import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RegisterProductConstants } from '../../../pos-redux/registerProduct/registerProduct.constants';
import { setRegisterProduct, setAssortmentData, getPaginatedData, getPaginatedAssortmentData, getProductsImagesAction, setRegisterProductWithInformation } from '../../../pos-redux/registerProduct/registerProduct.actions';
import './assortment.component.scss';
import { assortmentService } from '../../../services/assortment.service';
import { LoadPanel } from 'devextreme-react/load-panel';
import styled from 'styled-components';
import { Button } from 'devextreme-react/button';
import { isMobile } from 'react-device-detect';
import LazyImage from 'react-lazy-blur-image';
import { preLoadImagePath } from '../../../helpers/images';
import { ButtonName, posMessage, ScreenName } from '../../../helpers/pos-message';
import { currencyOptions } from '../../../helpers/helper';
import { showUILoader } from '../../../pos-redux/commonUI/commonUI.actions';
import { addProductToCart, maxLimitExceeded, customerClubMemberCheck, customerRfidCheck } from '../../../pos-redux/registerProduct/product.actions';
import { setPayment } from '../../../pos-redux/payment/payment.actions';
import { PaymentConstants } from '../../../pos-redux/payment/payment.constants';
import { setHeader } from '../../../pos-redux/header/header.actions';
import { headerConstants } from '../../../pos-redux/header/header.constants';
import { setError, setWarning, setMessage } from '../../../pos-redux/errorHandler/errorHandler.actions';
import { ProductConstants } from '../../../pos-redux/registerProduct/product.constants';
import { logs } from '../../../pos-redux/troubleshoot/troubleshoot.actions';
import { CustomerAccountType } from '../../../pos-redux/customer/customer.constant';
import { productWeightScaling } from '../../../pos-redux/troubleshoot/weightScaling/weightScaling.actions';
import { isSpecialProduct, priceFormat, ellipsisText } from '../../../helpers/helper';

function AssortmentComponent() {
    const dispatch = useDispatch();
    const [mainGroupSelector, stateOrder, uiConfiguration, employeeDetail, commonUI, memberStartState, customerDetail] = useSelector(state => [state.RegisterProduct, state.product, state.uiConfiguration, state.employeeDetail, state.commonUIReducer, state.start, state.customerDetail]);
    const { assortmentGridColumns } = useSelector(state => state.uiConfiguration);

    var assortmentDataLimit = 2 * assortmentGridColumns; // here 2 is number of rows.

    var gridRowContainerCss = "";
    for (let index = 0; index < assortmentGridColumns; index++) {
        gridRowContainerCss = gridRowContainerCss + " 300px"
    }
    const CustomGrid = styled.div`
        display: grid;
        @media screen and (min-width: 767px) {
            grid-template-columns: ${gridRowContainerCss};
            }
        @media screen and (max-width: 767px) {
            grid-template-columns: 50% 50%;
            }
        grid-template-rows: auto;
        `;

    useEffect(() => {
        resetData();
        getRootAssortment(uiConfiguration.rootAssortmentId);
    }, [])

    const getRootAssortment = async (parentId, previousAssortment) => {
        try {
            dispatch(showUILoader(true));
            var rootAssortments = await assortmentService.getParentRootAssortment(parentId);
            if (rootAssortments) {
                var payload = {
                    assortments: rootAssortments
                }
                dispatch(setAssortmentData(RegisterProductConstants.SET_REGISTER_ASSORTMENTS, payload));
                if (previousAssortment) {
                    await setSelectedAssortment(previousAssortment)
                } else if (payload.assortments) {
                    if (mainGroupSelector.assortmentChild.assortmentAndProductCache.length === 0 || (uiConfiguration.rootAssortmentId === parentId)) {
                        await setSelectedAssortment(payload.assortments[0]);
                    } else {
                        await setSelectedAssortmentCache(payload.assortments[0])
                    }
                }
            }
            dispatch(showUILoader(false));
        } catch {
            dispatch(showUILoader(false));
            resetData();
        }
    }

    const getAssortmentAndProducts = async (assortment) => {
        try {
            if (assortment) {
                dispatch(showUILoader(true));
                var assortmentWithProducts = await assortmentService.getProductByAssortment(assortment.id);
                if (assortmentWithProducts) {
                    var assortments = GetAssortmentAndItsProducts(assortment, assortmentWithProducts);
                    if (assortments) {

                        var payload = {
                            assortmentAndProduct: assortmentWithProducts,
                            assortmentAndProductCache: assortments,
                            currentPage: 1
                        }
                        payload.paginationData = getPaginatedAssortmentData(payload.assortmentAndProductCache, payload.currentPage, assortmentDataLimit);
                        dispatch(setAssortmentData(RegisterProductConstants.SET_REGISTER_PRODUCT_ASSORTMENTS, payload));
                    }
                }
                dispatch(showUILoader(false));

                await loadImages(assortmentWithProducts, assortments);
            }
        } catch {
            dispatch(showUILoader(false));
            resetData();
        }
    }

    const setSelectedAssortment = async (item) => {
        resetData();
        var payload = {
            selectedAssortment: item
        }

        dispatch(setAssortmentData(RegisterProductConstants.SET_PARENT_ASSORTMENT_SELECTED, payload));
        await getAssortmentAndProducts(item);
    }

    const setSelectedAssortmentCache = async (item) => {
        logs(ScreenName.AssortmentsComponent, item.name, "setAssortmentData: " + item.productDetailId, uiConfiguration);
        resetData();
        var payload = {
            selectedAssortment: item
        }

        dispatch(setAssortmentData(RegisterProductConstants.SET_PARENT_ASSORTMENT_SELECTED, payload));
        var assortments = GetAssortmentAndItsProducts(item, mainGroupSelector.assortmentChild.assortmentAndProduct);
        if (assortments) {

            var payload = {
                ...mainGroupSelector.assortmentChild,
                assortmentAndProductCache: assortments,
                currentPage: 1
            }

            payload.paginationData = getPaginatedAssortmentData(payload.assortmentAndProductCache, payload.currentPage, assortmentDataLimit);
            dispatch(setAssortmentData(RegisterProductConstants.SET_REGISTER_PRODUCT_ASSORTMENTS, payload));

            await loadImages(payload.assortmentAndProductCache, assortments);
        }
    }

    const loadImages = async (productFromCache, assortments) => {
        if (productFromCache) {
            var productsOnly = {
                products: assortments.filter(x => x.assortment == null)
            }
            await dispatch(getProductsImagesAction(productsOnly));
        }
    }

    const clickCancel = async () => {
        logs(ScreenName.AssortmentsComponent, ButtonName.Back, "getParentAssortment", uiConfiguration);
        if (mainGroupSelector.selectedAssortment) {
            if (mainGroupSelector.selectedAssortment.parentId > 0) {
                resetData();
                await getParentAssortment(mainGroupSelector.selectedAssortment);
            }
        }
    }

    const getParentAssortment = async (item) => {
        try {
            dispatch(showUILoader(true));
            var assortment = await assortmentService.getAssortment(item.parentId);
            if (assortment) {
                if (assortment.parentId === uiConfiguration.rootAssortmentId) {
                    await getRootAssortment(assortment.parentId, assortment);
                    dispatch(showUILoader(false));
                    return;
                }

                var assortmentsData = [];
                assortmentsData.push(assortment);

                var payload = {
                    assortments: assortmentsData,
                }
                dispatch(setAssortmentData(RegisterProductConstants.SET_REGISTER_ASSORTMENTS, payload));
                await setSelectedAssortment(assortment);
                dispatch(showUILoader(false));
            }
        } catch {
            resetData();
        }
    }

    const resetData = () => {
        var resetPayLoad = {
            paginationData: [],
            assortmentAndProduct: [],
            currentPage: 1
        }

        dispatch(setAssortmentData(RegisterProductConstants.SET_REGISTER_PRODUCT_ASSORTMENTS, resetPayLoad));

        var payloadSelected = {
            selectedAssortment: {}
        }

        dispatch(setAssortmentData(RegisterProductConstants.SET_PARENT_ASSORTMENT_SELECTED, payloadSelected));
    }

    const renderAssortmentName = (rootAssortment, index) => {
        var classHTML = '';
        var heightCssClass = isMobile ? "" : "";
        if (Number.isInteger((index + 1) / 3)) {
            classHTML = "btn btn-primary cursor-hand place-Center text-break" + heightCssClass;
        } else {
            classHTML = "btn btn-primary me-2 cursor-hand place-Center text-break" + heightCssClass;
        }
        var selected = mainGroupSelector.selectedAssortment.name;
        return (
            <div className="grid-element me-2 mb-2 assortmentItem">
                <Button
                    id={selected === rootAssortment.name ? "active-assortment-top" : ""}
                    type="normal"
                    stylingMode="outlined"
                    className={classHTML}
                    onClick={() => setSelectedAssortmentCache(rootAssortment)}
                >
                    <span>{rootAssortment.name}</span>
                </Button>
            </div>
        );
    }

    const renderAssortmentAndProducts = (item, index, productImages) => {
        var classHTML = '';
        var heightCssClass = isMobile ? "" : "";
        if (Number.isInteger((index + 1) / 3)) {
            classHTML = "btn btn-primary cursor-hand place-Center text-break" + heightCssClass;
        } else {
            classHTML = "btn btn-primary me-2 cursor-hand place-Center text-break" + heightCssClass;
        }
        var productImage = null;
        if (productImages) {
            productImage = productImages.find(pi => pi.productID == item.id);
        }
        var assortmentClassName = "grid-element me-2 mb-2";
        if (item.assortment != null) {
            assortmentClassName += " childAssortmentItem";
        } else {
            assortmentClassName += " productItem";
        }
        return (
            <div className={assortmentClassName}>
                <Button
                    type="normal"
                    stylingMode="outlined"
                    className={classHTML} onClick={() => addProduct(item)}>
                    {
                        item.assortment != null
                            ?
                            <span>{item.assortment.name}</span>
                            :
                            <>
                                {productImage &&
                                    <LazyImage
                                        transitionTime={50}
                                        placeholder={`${preLoadImagePath.default_imge}`}
                                        uri={productImage.imageURL}
                                        render={(src, style) => <img src={src} style={style} className="assortmentProductImage" alt="img" />}
                                    />
                                }
                                <div id="assortment-product-price" className="row">
                                    <label className='cursor-hand'>{item.name}
                                        <br />
                                        <span className="pt-2 dx-button-mode-outlined dx-button-success">
                                            {priceFormat(item.salesPrice) + ' ' + posMessage.kr}
                                        </span>
                                        <span className="dx-button-mode-outlined dx-button-success" id="productUnit">{getUnitText(item)} </span>
                                    </label>
                                </div>
                            </>
                    }
                </Button>
            </div>
        );
    }

    const getUnitText = (product) => {
        if (isSpecialProduct(product) && product.unitText) {
            return '/' + ellipsisText(product.unitText);
        }
    }

    const addProduct = async (product) => {
        logs(ScreenName.AssortmentsComponent, product.name, "Added a product: " + product.productDetailId, uiConfiguration);
        if (product.assortment == null) {
            dispatch(showUILoader(true));
            if (isSpecialProduct(product)) {
                var productResponse = await dispatch(productWeightScaling());
                if (productResponse) {
                    product.usedQuantity = productResponse.Amount;
                } else {
                    dispatch(showUILoader(false));
                    setError('Vennligst legg varen din på vekten.');
                    return;
                }
            } else {
                product.usedQuantity = 1;
            }

            if (uiConfiguration.allowCustomerCredit == CustomerAccountType.Activated)
                var customer = customerRfidCheck(customerDetail, stateOrder);
            else
                var ccMember = customerClubMemberCheck(memberStartState, stateOrder);


            var res = await dispatch(addProductToCart(product, uiConfiguration, employeeDetail, stateOrder, ccMember, customer));
            if (res === false) {
                dispatch(showUILoader(false));
                setError(RegisterProductConstants.NO_ITEM_FOUND);
                return;
            }
            if (res === RegisterProductConstants.CALL_FOR_OPERATIONS) {
                dispatch(showUILoader(false));
                dispatch(setRegisterProduct(RegisterProductConstants.CALL_FOR_OPERATIONS, true));
                return;
            }
            if (res === RegisterProductConstants.STRUCTURE_PRODUCT_POPUP) {
                var type = { isStructure: true };
                dispatch(showUILoader(false));
                dispatch(setRegisterProductWithInformation(RegisterProductConstants.STRUCTURE_PRODUCT_POPUP, true, type));
                return;
            }
            dispatch(showUILoader(false));

            if (!uiConfiguration.stayInProductSelector) {
                dispatch(setRegisterProduct(ProductConstants.ADD_PRODUCT, true));
            }
        } else {
            await getRootAssortment(product.assortment.parentId, product.assortment);
        }
    }

    const onNext = () => {
        logs(ScreenName.AssortmentsComponent, ButtonName.NextPage, "setAssortmentData", uiConfiguration);
        if (Math.ceil(mainGroupSelector.assortmentChild.assortmentAndProductCache.length / assortmentDataLimit) > mainGroupSelector.assortmentChild.currentPage) {

            mainGroupSelector.assortmentChild.currentPage = mainGroupSelector.assortmentChild.currentPage + 1;
            var payload = {
                ...mainGroupSelector.assortmentChild,
            }

            payload.paginationData = getPaginatedAssortmentData(payload.assortmentAndProductCache, payload.currentPage, assortmentDataLimit);
            dispatch(setAssortmentData(RegisterProductConstants.SET_REGISTER_PRODUCT_ASSORTMENTS, payload));
        } else {
            setWarning(RegisterProductConstants.NO_ITEM_FOUND);
        }
    }

    const onPrevious = () => {
        logs(ScreenName.AssortmentsComponent, ButtonName.LastPage, "setAssortmentData", uiConfiguration);
        if (mainGroupSelector.assortmentChild.currentPage > 1) {
            mainGroupSelector.assortmentChild.currentPage = mainGroupSelector.assortmentChild.currentPage === 0 ? 1 : mainGroupSelector.assortmentChild.currentPage - 1
            var payload = {
                ...mainGroupSelector.assortmentChild,
            }

            payload.paginationData = getPaginatedAssortmentData(payload.assortmentAndProductCache, payload.currentPage, assortmentDataLimit);
            dispatch(setAssortmentData(RegisterProductConstants.SET_REGISTER_PRODUCT_ASSORTMENTS, payload));
        } else {
            setWarning(RegisterProductConstants.NO_ITEM_FOUND);
        }
    }

    const clickPay = () => {
        logs(ScreenName.AssortmentsComponent, ButtonName.Payment, "setPayment, setRegisterProduct", uiConfiguration);
        if (stateOrder.OrderPos && (stateOrder.OrderPos.order.orderTotal > 0)) {
            dispatch(setRegisterProduct(RegisterProductConstants.SET_REGISTER_PRODUCT, false));
            dispatch(setHeader(headerConstants.BETAL, true));
            dispatch(setPayment(PaymentConstants.PAYMENT_METHOD, true));
        }
        else {
            setWarning(posMessage.orderSumIsZero);
        }
    }

    const visibleNextButton = () => {
        var selectedAssortment = mainGroupSelector.selectedAssortment;
        if (selectedAssortment) {
            var data = GetAssortmentAndItsProducts(selectedAssortment, mainGroupSelector.assortmentChild.assortmentAndProduct);
            return Math.ceil(data.length / assortmentDataLimit) > mainGroupSelector.assortmentChild.currentPage ? false : true;
        }
        return false;
    }

    function GetAssortmentAndItsProducts(assortment, assortmentWithProductsData) {
        var assortments = [];
        var assortmentChilds = assortmentWithProductsData.filter(x => x.assortment != null);
        assortments = assortmentChilds.filter(x => x.assortment.parentId == assortment.id);
        if (assortments) {
            var assortmentProducts = assortmentWithProductsData.filter(x => x.assortmentId === assortment.id);
            for (let item of assortmentProducts) {
                assortments.push(item);
            }
        }
        return assortments;
    }

    return (
        <div className="fade" id="assortment-component" role="tabpanel" aria-labelledby="nav-profile-tab">
            <div className={isMobile ? "d-flex align-items-center justify-content-center flex-column mv-grid-btn" : "d-flex flex-column"}>
                <div className="text-center break-word mt-4 single-page-buttons">
                    <LoadPanel
                        message={posMessage.loading}
                        shadingColor="rgba(0,0,0,0.4)"
                        visible={commonUI.loading}
                        showIndicator={true}
                    />

                    <CustomGrid className={isMobile ? "jusify-content-center buttonTopAssortmentGroup" : "jusify-content-center buttonTopAssortmentGroup gridButtonAssortmentWrapper"}>
                        {
                            mainGroupSelector.assortments.map((value, index) => {
                                return renderAssortmentName(value, index);
                            })
                        }
                    </CustomGrid>

                    <h2 className="mt-3 my-2">{mainGroupSelector.selectedAssortment.name}</h2>
                    {
                        mainGroupSelector.selectedAssortment.name && mainGroupSelector.assortmentChild
                            ?
                            <CustomGrid className={isMobile ? "jusify-content-center buttonGroupAssortmentAndProducts" : "jusify-content-center buttonGroupAssortmentAndProducts gridButtonChildAssortmentWrapper"}>
                                {
                                    mainGroupSelector.assortmentChild.paginationData.map((value, index) => {
                                        return renderAssortmentAndProducts(value, index, mainGroupSelector.productImages);
                                    })
                                }
                            </CustomGrid>
                            :
                            <></>
                    }

                    <div className="d-flex my-2 actionButtonGroupFooter" id="assortment-btn">
                        <Button
                            id="btn-radius-remove-top-bottom-right"
                            type="success"
                            stylingMode="contained"
                            disabled={mainGroupSelector.assortmentChild.currentPage > 1 ? false : true}
                            className="btn btn-primary cursor-hand" onClick={onPrevious}>
                            <img className="assortmentIcons" src={`${preLoadImagePath.whiteLeftArrow}`} alt="img" /> <span>{ButtonName.LastPage}</span>
                        </Button>
                        <Button
                            id="btn-no-radius"
                            stylingMode="contained"
                            disabled={mainGroupSelector.selectedAssortment ? (uiConfiguration.rootAssortmentId === mainGroupSelector.selectedAssortment.parentId ? true : false) : true}
                            className="btn btn-primary cursor-hand" onClick={clickCancel}>
                            <img className={isMobile ? "customIcon mb-1" : "customIcon"} src={`${preLoadImagePath.undo_white}`} alt="img" /> <span>{ButtonName.Back}</span>
                        </Button>
                        <Button
                            id="btn-radius-remove-top-bottom-left"
                            type="success"
                            stylingMode="contained"
                            disabled={visibleNextButton()}
                            className="btn btn-primary cursor-hand" onClick={onNext} >
                            {!isMobile
                                ?
                                <> <span>{ButtonName.NextPage}</span>  <img className="assortmentIcons" src={`${preLoadImagePath.whiteRightArrow}`} alt="img" />  </>
                                :
                                <>  <img className="assortmentIcons" src={`${preLoadImagePath.whiteRightArrow}`} alt="img" /> <span>{ButtonName.NextPage}</span>  </>
                            }
                        </Button>
                        {
                            uiConfiguration.addPaymentButtonInProducts
                                ?
                                <Button
                                    type="success"
                                    stylingMode="contained"
                                    disabled={stateOrder.OrderPos ? false : true}
                                    className="btn btn-primary cursor-hand align-btn-right" onClick={clickPay}>
                                    <img className="assortmentIcons" src={`${preLoadImagePath.check}`} alt="img" /> <span>{ButtonName.Payment} </span>
                                </Button>
                                :
                                <></>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export { AssortmentComponent }