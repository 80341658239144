import { ProductConstants } from "./product.constants";

const initialState = {
    actionType: '',
    loading: false,
    data: {},
    error: ''
};

function productWithQty(state = initialState, action) {
    switch (action.type) {
        case ProductConstants.SELECT_PRODUCT: {
            let product = action.payload.product;

            return {
                actionType: action.type,
                loading: false,
                data: { ...state.data, product },
                error: ''
            }
        }

        case ProductConstants.SUMMARY_AFTER_ADD_PRODUCT: {
            let summary = action.payload;

            return {
                actionType: action.type,
                loading: false,
                data: { summary },
                error: ''
            }
        }

        case ProductConstants.INIT_SELECTED_PRODUCT: {
            return initialState;
        }

        default:
            return state
    }
}

export { productWithQty };