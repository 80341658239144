import { UIConfigurationConstants } from './uiConfiguration.constants';
import { UIConfigurationService } from '../../services/uiConfiguration.service';
import { localStorageService } from '../../services/api/utilities/localStorageService';

export const getUIConfigRequest = () => {
    return {
        type: UIConfigurationConstants.GET_REQUEST
    }
}

export const getUIConfigSuccess = (response) => {
    return {
        type: UIConfigurationConstants.GET_SUCCESS,
        payload: response
    }
}

export const getUIConfigFailure = error => {
    return {
        type: UIConfigurationConstants.GET_FAILURE,
        payload: error
    }
}

export const getUIConfiguration = (version) => async dispatch => {
    dispatch(getUIConfigRequest());
    const response = await UIConfigurationService.getUIConfiguration(version);
    if (response) {
        localStorageService.setPOSConfiguration(response);
        dispatch(getUIConfigSuccess(response));
    }
    else {
        dispatch(getUIConfigFailure(response));
    }

    return response;
}

function avaibleLight(lightControl) {
    return dispatch => {
        var data = UIConfigurationService.avaibleLight(lightControl);
    }
}


function occupiedLight(lightControl) {
    return dispatch => {
        var data = UIConfigurationService.occupiedLight(lightControl);
    }
}

function assistanceLight(lightControl) {
    return dispatch => {
        var data = UIConfigurationService.assistanceLight(lightControl);
    }
}



export { avaibleLight, occupiedLight, assistanceLight };