import { useDispatch, useSelector } from 'react-redux';
import './vippsPaymentStatus.component.scss';
import { NumberPad } from '../../base/numberPad.component';
import { setNumber } from '../../../pos-redux/base/numberPad.action';
import { initiatePayment, getVippsConfig } from '../../../pos-redux/payment/vipps/vippsPayment.action';
import { setPayment } from '../../../pos-redux/payment/payment.actions';
import { PaymentConstants } from '../../../pos-redux/payment/payment.constants';
import { useEffect } from 'react';
import { setWarning, toastCenterPosition, setError } from '../../../pos-redux/errorHandler/errorHandler.actions';
import { Button } from 'devextreme-react/button';
import { regxNumber } from '../../../helpers/helper'
import { preLoadImagePath } from '../../../helpers/images';
import { ButtonName, posMessage, ScreenName } from '../../../helpers/pos-message';
import { MessageConstants } from '../../../pos-redux/message/message.constants';
import { logs } from '../../../pos-redux/troubleshoot/troubleshoot.actions';


function VippsPayment() {
    const [numberpad, stateOrder, stateGiftCard] = useSelector(state => [state.numberPad, state.product, state.giftCardPayment]);
    const dispatch = useDispatch();
    var vippConfigDetail = null;
    const [uiConfiguration] = useSelector(state => [state.uiConfiguration]);

    useEffect(() => {
        dispatch(setNumber(''));
    }, []);

    const closeComponent = () => {
        logs(ScreenName.VippsPaymentComponent, ButtonName.Cancel, "setPayment", uiConfiguration);
        dispatch(setPayment(PaymentConstants.PAYMENT_METHOD, true));
    }

    const clickOK = async () => {
        logs(ScreenName.VippsPaymentComponent, ButtonName.Ok, "getVippsConfig, initiatePayment", uiConfiguration);
        if (numberpad.number.length == 8) {

            if (vippConfigDetail == null) {
                vippConfigDetail = await dispatch(getVippsConfig());
            }

            if (vippConfigDetail.vippsUserName === '' || vippConfigDetail.vippsPassword === '') {
                setError(MessageConstants.Vipps.VippsConfigurationNotExists, ScreenName.VippsPaymentComponent);
                return;
            }
            let payAmount = 0;
            if (stateGiftCard?.paymentTransactionModel && Object.keys(stateGiftCard?.paymentTransactionModel).length > 0) {
                payAmount = stateGiftCard?.paymentTransactionModel.remainingAmount;
            } else {
                payAmount = stateOrder.OrderPos.order.orderTotal;
            }

            if (payAmount > 0) {
                dispatch(initiatePayment(payAmount, numberpad.number, MessageConstants.Vipps.TranscationSystemName, 20, 2));
            }
        }
        else {
            setWarning(posMessage.lengthShouldBeEight, toastCenterPosition, ScreenName.VippsPaymentComponent);
        }
    }

    const numberChange = (val) => {
        if (val === '' || regxNumber.test(val)) {
            logs(ScreenName.VippsPaymentComponent, "NumberChange", "getVippsConfig, initiatePayment", uiConfiguration);
            numberpad.number = val;
            dispatch(setNumber(numberpad.number));
        }
    }

    return (
        <div id="vippsPayment" className="d-flex align-items-center justify-content-center flex-column h-90-vh">
            <div className="inner-section-height d-flex align-items-center justify-content-center">
                <div className="text-center max-50 dx-theme-generic-typography">
                    <h2 className="dx-font-m dx-font-xs">Skriv inn ditt mobilnummer for å betale med vipps</h2>
                    <div className="my-4 pt-4 d-flex keyPadWrapper">
                        <div className="inputGroupWrapper">
                            <div className="inputGroup">
                                <img className="" src={`${preLoadImagePath.telephone}`} alt="img" />
                                <input ref={(input) => { input && input.focus() }} placeholder="Mobilnummer" className="dx-theme-background-color inputText dx-theme-border-color" type="text" value={numberpad.number} onChange={e => numberChange(e.target.value)} />
                            </div>
                            <div className="keyPadLeftSidebutton text-center flex-column ps-lg-3 ps-lg-4">
                                <Button
                                    type="success"
                                    stylingMode="contained"
                                    className="btn btn-primary mx-auto px-5 cursor-hand justify-content-center btn-icon" onClick={clickOK}> {ButtonName.Ok}</Button>

                                <Button
                                    stylingMode="contained"
                                    className="dx-datagrid-search-text btn btn-cancel-color btn mx-auto px-5 mt-3 cursor-hand justify-content-center btn-icon"
                                    onClick={closeComponent}> {ButtonName.Cancel}</Button>
                            </div>
                        </div>
                        <NumberPad screen="vipps"></NumberPad>
                    </div>
                </div>
            </div>
        </div>
    );
}

export { VippsPayment }