import React from "react";
import { logs } from '../../Pos/pos-redux/troubleshoot/troubleshoot.actions';

export class ErrorBoundary extends React.Component {
  state = { hasError: false };

  componentDidCatch(error, errorLog) {
    // report the error to your favorite Error Tracking tool (ex: Sentry, Bugsnag)
    var uiConfiguration = {
      isPosUILogEnabled: true
    };

    logs(errorLog?.componentStack, "Error-Boundary", error.toString(), uiConfiguration);
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (<div id="welcomePage" className="d-flex align-items-center justify-content-center flex-column h-90-vh">
        <h1 >Failed to Load that component.</h1>;
      </div>);
    } else {
      return this.props.children;
    }
  }
}
