import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store } from './Pos/pos-redux/store';
import { App } from './App';
import themes from "devextreme/ui/themes";
import { useSelector } from "react-redux";
import { localStorageService } from './Pos/services/api/utilities/localStorageService';
import { ErrorBoundary } from "./Pos/pages/ErrorBoundary";
import { posAgentService } from './Pos/services/posAgent.service'

const Index = () => {
    const default_theme = "generic.green.touchsoft";
    let themeProperty = localStorageService.getThemeProperty();
    let currentTheme = null;
    if (themeProperty)
        currentTheme = themeProperty.dx_theme;
    else
        currentTheme = default_theme;

    var localUI = localStorageService.getPOSConfiguration();
    if (localUI != null && Object.keys(localUI).length !== 0) {
        if (localUI.themeColor != '' && currentTheme != localUI.themeColor) {
            var theme = { 'dx_theme': localUI.themeColor };
            localStorageService.setThemeProperty(theme);
            currentTheme = localUI.themeColor;
        } else if (localUI.themeColor == '' && currentTheme == null) {
            var theme = { 'dx_theme': default_theme };
            localStorageService.setThemeProperty(theme);
            currentTheme = default_theme;
        }
    }

    themes.current(currentTheme);

    return (
        <App />
    )
}

window.onerror = function (msg, url, line, col, error) {
    // Note that col & error are new to the HTML 5 spec and may not be 
    // supported in every browser.  It worked for me in Chrome.
    var extra = !col ? '' : '\ncolumn: ' + col;
    extra += !error ? '' : '\nerror: ' + error;

    // You can view the information in an alert to see things working like this:
    posAgentService.posUILog(msg, "Root of Index", error?.stack.toString());
    // TODO: Report this error via ajax so you can keep track
    //       of what pages have JS issues 

    var suppressErrorAlert = true;
    // If you return true, then error alerts (like in older versions of 
    // Internet Explorer) will be suppressed.
    return suppressErrorAlert;
};

window.onunhandledrejection = function (e) {
    posAgentService.posUILog(e.type, "Root of Index", e?.reason?.stack.toString());
} 

ReactDOM.render(
    <Provider store={store}>
        <ErrorBoundary>
            <Index />
        </ErrorBoundary>
    </Provider>,
    document.getElementById('app')
);