import { ErrorHandlerConstants } from "./errorHandler.constants"
import notify from "devextreme/ui/notify"
import { isMobile } from 'react-device-detect';

const customPaymentHeight = '23%';
const toastTypeName = { 'error': 'error', 'success': 'success', 'warning': 'warning' };

//#region  Actions
export function setError(error, pageName) {
    let errorMsg = error ?? ErrorHandlerConstants.DefaultErrorMessage;
    if (pageName)
        toast(errorMsg, toastTypeName.error, customPaymentHeight);
    else
        toast(errorMsg, toastTypeName.error);

    return {
        type: ErrorHandlerConstants.SET_ERROR,
        error: error
    }
}

export function setMessage(message) {
    let toastMessage = message ?? ErrorHandlerConstants.DefaultErrorMessage;
    toast(toastMessage, toastTypeName.success);

    return {
        type: ErrorHandlerConstants.SET_MESSAGE,
        error: toastMessage
    }
}

export function hideError() {
    return {
        type: ErrorHandlerConstants.HIDE_ERROR
    }
}
//#endregion

export const toastCenterPosition = 'bottom';

//#region  Functions
export function setWarning(warning, messagePosition, pageName) {
    let warningMsg = warning ?? ErrorHandlerConstants.DefaultWarningMessage;
    if (pageName)
        toast(warningMsg, toastTypeName.warning, customPaymentHeight);
    else
        toast(warningMsg, toastTypeName.warning);
}

export function setSucess(message, pageName) {
    let sucessMsg = message;
    if (pageName)
        toast(sucessMsg, toastTypeName.success, customPaymentHeight);
    else
        toast(sucessMsg, toastTypeName.success);
}


function toast(message, infoType, toastHeight) {
    if (!isMobile) {
        notify({
            message: message,
            position: {
                my: "bottom center",
                at: "bottom center",
            },
            width: function () { return window.innerWidth; },
            height: toastHeight ? toastHeight : '13%',
        }, infoType, 5000);
    } else {
        notify({
            message: message,
            position: {
                my: "bottom center",
                at: "bottom center",
            },
            width: '80%'
        }, infoType, 5000);
    }
}
//#endregion