
import { useDispatch, useSelector } from 'react-redux';
import './barcodeScanFailurePopup.component.scss';
import { setRegisterProduct } from '../../pos-redux/registerProduct/registerProduct.actions';
import { RegisterProductConstants } from '../../pos-redux/registerProduct/registerProduct.constants';
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import { useState } from 'react';
import { Button } from 'devextreme-react/button';
import { ButtonName, ScreenName } from '../../helpers/pos-message';
import { logs } from '../../pos-redux/troubleshoot/troubleshoot.actions';
import { WeightScalingConstants } from '../../pos-redux/troubleshoot/weightScaling/weightScaling.constants';

function BarcodeScanFailurePopup() {
    const [visiblePopup, setVisiblePopup] = useState(true);
    const dispatch = useDispatch();
    const barcodeFailureMessage = 'Vi finner ikke strekkoden du skannet. \n\n Betjeningen er varslet og vil hjelpe deg så snart som mulig';
    const [uiConfiguration, weightScalingState] = useSelector(state => [state.uiConfiguration, state.weightScalingReducer]);

    const hidePopup = () => {
        logs(ScreenName.BarcodeScanFailurePopup, ButtonName.Ok, "Hide BarcodeScanFailurePopup PopUp", uiConfiguration);
        setVisiblePopup(false);
        dispatch(setRegisterProduct(RegisterProductConstants.ADD_PRODUCT, true));
    }

    const headerText = () => {
        var text = '';
        if (weightScalingState.actionType === WeightScalingConstants.GET_FAILURE) {
            text = 'Vennligst legg varen din på vekten'
        } else {
            text = barcodeFailureMessage.split('\n').map((txt) => <span><br />{txt}</span>);
        }

        return text;
    }

    return (
        <Popup
            visible={visiblePopup}
            onHiding={hidePopup}
            height='auto'
            showCloseButton={false}
            showTitle={false}
            container="#pos"
        >

            <div id="barcodeFailurePopup" className="text-center inner-section-height tab-content d-flex align-items-center justify-content-lg-center flex-column">

                <h2 className="popUptext">{headerText()}</h2>
                <br />
                <div className="text-center">
                    <div className="d-flex h-100 justify-content-center align-items-center">
                        <Button
                            type="success"
                            stylingMode="contained"
                            className="btn btn-primary m-2 px-5 cursor-hand btn-icon btn-font-xs" onClick={hidePopup}>
                            {ButtonName.Ok}
                        </Button>

                    </div>
                </div>
            </div>
        </Popup>

    );
}

export { BarcodeScanFailurePopup }