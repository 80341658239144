import { combineReducers } from 'redux';
import { authentication } from './authentication/authentication.reducer';
import { registration } from './registration/registration.reducer';
import { users } from './users/users.reducer';
import { alert } from './alert/alert.reducer';
import { header } from './header/header.reducer';
import { start } from './start/start.reducer';
import { RegisterProduct } from './registerProduct/registerProduct.reducer';
import { payment } from './payment/payment.reducer';
import { numberPad } from './base/numberPad.reducer'
import { message } from './message/message.reducer'
import { vippsPayment } from './payment/vipps/vippsPayment.reducer';
import { uiConfiguration } from './uiConfiguration/uiConfiguration.reducer';
import { selectBag } from './selectBag/selectBag.Reducer';
import { errorHandler } from './errorHandler/errorHandler.reducer';
import { product } from './registerProduct/product.reducer';
import { productWithQty } from './registerProduct/addProductWithQty.reducer';
import { employeeDetail } from './employee/employee.reducer';
import { terminalPayment } from './payment/terminal/terminal.reducer';
import { commonUIReducer } from './commonUI/commonUI.reducers';
import { troubleShootReducer } from './troubleshoot/troubleshoot.reducer';
import { customerDetail } from './customer/customer.reducer';
import { giftCardPayment } from './payment/giftCard/giftCard.reducer';
import { weightScalingReducer } from './troubleshoot/weightScaling/weightScaling.reducer';

const rootReducer = combineReducers({
    authentication,
    registration,
    users,
    alert,
    header,
    start,
    RegisterProduct,
    payment,
    numberPad,
    message,
    vippsPayment,
    uiConfiguration,
    selectBag,
    errorHandler,
    product,
    productWithQty,
    employeeDetail,
    terminalPayment,
    commonUIReducer,
    troubleShootReducer,
    customerDetail,
    giftCardPayment,
    weightScalingReducer
});

export default rootReducer;