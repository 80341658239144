export const headerConstants = {
    //header name
    START: 'Start',
    REGISTERVARER: 'Registrer varer',
    VELGPOSE: 'Velg pose',
    BETAL: 'Betal',
    SKANNKVITTERING: 'Skann kvittering',

    //haeder events types
    SET_HEADER: 'SET_HEADER',
    SET_SELECTED_HEADER: 'SET_SELECTED_HEADER'
};