import { getAppSettings } from '../../../config';

const appSettings = getAppSettings();
const vippsAPIBaseUrl = appSettings.vipps.API_URL;
const retailLinkAPIBaseUrl = appSettings.apiGateway.API_URL;
const paymentTerminalAPIBaseUrl = 'http://localhost:4040';
const imageURL = window.location.origin;


export const ApiUrlConstants = {
    Vipps: {
        PaymentInitiateEndPoint: vippsAPIBaseUrl + "/api/payment/initiate",
        PaymentCancelEndPoint: vippsAPIBaseUrl + "/api/payment/cancel/",
        PaymentDetailEndPoint: vippsAPIBaseUrl + "/api/payment/detail/",
        PaymentCaptureEndPoint: vippsAPIBaseUrl + "/api/payment/capture/",
        ExistingTransactionEndPoint: vippsAPIBaseUrl + "/api/payment/transaction/",
        Callback: "touchsoft.vipps.akeodev.com",
    },

    RetailLink: {
        ProductMainGroupsEndPoint: retailLinkAPIBaseUrl + "/ProductMainGroups",
        ProductSubGroupsEndPoint: retailLinkAPIBaseUrl + "/ProductSubGroups",
        ProductsEndPoint: retailLinkAPIBaseUrl + "/Products",
        ProductFromBarcodeEndPoint: retailLinkAPIBaseUrl + "/VadilateProductByBarcode",
        ProductImagesEndPoint: retailLinkAPIBaseUrl + "/SupplierProduct/Images",
        BagTypesEndPoint: retailLinkAPIBaseUrl + "/BagTypes",
        EmployeeDetailByNumEndPoint: retailLinkAPIBaseUrl + "/EmployeeDetailByNumber",
        CustomerClubMemberEndPoint: retailLinkAPIBaseUrl + "/CustomerClubMember",
        OrderPosEndPoint: retailLinkAPIBaseUrl + "/OrderPos",
        OrderEndPoint: retailLinkAPIBaseUrl + "/Order",
        ProductAllSubGropupsEndPoint: retailLinkAPIBaseUrl + "/ProductSubGroups",
        AssortmentWithParentIdEndPoint: retailLinkAPIBaseUrl + "/AssortmentsByParentID",
        SubAssortmentsAndProductsEndPoint: retailLinkAPIBaseUrl + "/AssortmentsAndProducts",
        AssortmentEndPoint: retailLinkAPIBaseUrl + "/Assortment",
        CustomerEndpoint: retailLinkAPIBaseUrl + "/Customer/CustomerCardNo",
        GiftCardEndpoint: retailLinkAPIBaseUrl + "/GiftCardVerifyCardNo",
        GiftCardWithDrawalEndpoint: retailLinkAPIBaseUrl + "/GiftCard/CheckCardForWithdrawal",
        
        //UI-Configuration
        UIConfigurationEndPoint: retailLinkAPIBaseUrl + "/PosUIConfiguration",
        VIPPSConfigurationEndPoint: retailLinkAPIBaseUrl + "/VippsConfiguration"
    },

    POSAgent: {
        TerminalInitiatePaymentEndPoint: paymentTerminalAPIBaseUrl + "/startpaymentservice",
        TerminalPaymentStatusEndPoint: paymentTerminalAPIBaseUrl + "/paymentstatus",
        TerminalCancelPayment: paymentTerminalAPIBaseUrl + "/cancelpayment",
        PrintReceiptEndPoint: paymentTerminalAPIBaseUrl + "/printreceipt",
        LightTowerEndPoint: paymentTerminalAPIBaseUrl + "/lightstatus",
        ServiceStatusEndPoint: paymentTerminalAPIBaseUrl + "/servicestatuscheck",
        TroubleShootCheckEndPoint: paymentTerminalAPIBaseUrl + "/troubleshootcheck",
        PosUILog: paymentTerminalAPIBaseUrl + "/log",
        ProductScaling: paymentTerminalAPIBaseUrl + "/productscaling"
    },

    PreLoadImage: {
        ImageDirEndPoint: imageURL + '/images/preload/'
    }
}