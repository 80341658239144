import { setWarning } from "../errorHandler/errorHandler.actions";
import { ProductConstants } from "./product.constants"
const MaxStock = 10;
//#region Actions
export const selectProduct = (product, isNew = true) => {
    if (isNew) {
        return {
            type: ProductConstants.SELECT_PRODUCT,
            payload: { "product": { ...product, usedQuantity: 1 } }
        }
    }
    else {
        return {
            type: ProductConstants.SELECT_PRODUCT,
            payload: { "product": { ...product } }
        }
    }
}

export const summaryAfterAddProduct = (totalQty, totalAmt) => {
    return {
        type: ProductConstants.SUMMARY_AFTER_ADD_PRODUCT,
        payload: {
            totalQty,
            totalAmt
        }
    }
}

export const initSelectedproduct = () => {
    return {
        type: ProductConstants.INIT_SELECTED_PRODUCT
    }
}
//#endregion

//#region  Action Handlers
export const increaseSelectedProductUsedQty = (product) => dispatch => {
    if (MaxStock >= product.usedQuantity + 1) {
        product.usedQuantity += 1;
        dispatch(selectProduct(product, false));
    }
    else {
        setWarning(`Sett inn mengde fra 1 og ${MaxStock}`);
    }
}

export const decreaseSelectedProductUsedQty = (product) => dispatch => {
    if (product.usedQuantity - 1 > 0) {
        product.usedQuantity -= 1;

        dispatch(selectProduct(product, false));
    }
    else {
        setWarning(`Sett inn mengde fra 1 og ${MaxStock}`);
    }
}
//#endregion