import { customerService } from "../../services/customer.service"
import { CustomerConstants } from "./customer.constant"

function setStart(name, isActive) {
    return dispatch => {
        dispatch(
            {
                type: CustomerConstants.SET_START,
                payload: {
                    name,
                    isActive
                }
            })
    };
}

//#region Actions
export const customerDetailRequest = () => {
    return {
        type: CustomerConstants.CUSTOMER_DETAIL_REQUEST
    }
}

export const customerDetailSuccess = response => {
    return {
        type: CustomerConstants.CUSTOMER_DETAIL_SUCCESS,
        payload: response
    }
}

export const customerDetailFailure = error => {
    return {
        type: CustomerConstants.CUSTOMER_DETAIL_ERROR,
        payload: error
    }
}

export const initCustomer = () => {
    return {
        type: CustomerConstants.INIT_CUSTOMER
    }
}
//#endregion

//#region  Action Handlers
export const getCustomerDetailByInfo = (info) => async dispatch => {
    dispatch(customerDetailRequest());
    const response = await customerService.getCustomerByInfo(info);
    if (response) {
        dispatch(customerDetailSuccess(response));
        dispatch(setStart(CustomerConstants.CUSTOMER_ACCOUNT_CONFIRMATION, true));
    }
    else {
        dispatch(setStart(CustomerConstants.CUSTOMER_ACCOUNT_CONFIRMATION, true));
    }
}
//#endregion

export { setStart };