import { headerConstants } from './header.constants';

function setHeader(name, isActive) {
    return dispatch => {
        dispatch(
            {
                type: headerConstants.SET_HEADER,
                payload: {
                    name,
                    isActive
                }
            })
    };
}

function setTabSelectedHeader(selectedIndex) {
    return dispatch => {
        dispatch(
            {
                type: headerConstants.SET_SELECTED_HEADER,
                payload: {
                    selectedIndex
                }
            })
    };
}

export { setHeader, setTabSelectedHeader };