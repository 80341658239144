import { handleResponse } from './response';
import { authentication } from './auth-header';
import { getAppSettings } from '../../../../config';
import { posMessage } from '../../../helpers/pos-message';
import { localStorageService } from './localStorageService';

const config = {
    apiUrl: getAppSettings().apiGateway.API_URL
};

const authHead = async () => {
    return await authentication.authenticationHeader();
}

function errorHandler(error) {
    if (error === posMessage.retailLinkAPIInternalServerError) {
        var count = localStorageService.getApiErrorCount();
        count++;
        localStorageService.setApiErrorCount(count);
    }
    console.log(error);
}

async function readJSON(url) {
    return await fetch(url).then(handleResponse).catch(errorHandler);
}

async function getAll(url) {
    var authHeader = await authHead();

    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader, 'Content-Type': 'application/json' }
    };

    return await fetch(url, requestOptions).then(handleResponse).catch(errorHandler);
}

async function getSingle(url, id, flagQuery) {
    var authHeader = await authHead();

    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader, 'Content-Type': 'application/json' }
    };

    if (flagQuery) {
        return await fetch(`${url}${id}`, requestOptions).then(handleResponse).catch(errorHandler);
    } else {
        return await fetch(`${url}/${id}`, requestOptions).then(handleResponse).catch(errorHandler);

    }
}

async function post(url, model) {
    var authHeader = await authHead();
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader, 'Content-Type': 'application/json' },
        body: JSON.stringify(model)
    };
    return await fetch(url, requestOptions).then(handleResponse).catch(errorHandler);

}

async function put(url, model) {
    var authHeader = await authHead();
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader, 'Content-Type': 'application/json' },
        body: JSON.stringify(model)
    };

    return await fetch(url, requestOptions).then(handleResponse).catch(errorHandler);

}

async function remove(url, id) {
    var authHeader = await authHead();
    const requestOptions = {
        method: 'DELETE',
        headers: { ...authHeader, 'Content-Type': 'application/json' }
    };

    return await fetch(`${url}/${id}`, requestOptions).then(handleResponse).catch(errorHandler);

}

export const fetchProvider = {
    getAll,
    getSingle,
    post,
    put,
    remove,
    readJSON
};