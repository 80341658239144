import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'devextreme-react/button';
import LazyImage from 'react-lazy-blur-image';
import { preLoadImagePath } from '../../../helpers/images';
import { checkGiftCardNumber } from '../../../pos-redux/payment/giftCard/giftCard.actions';
import BarcodeReader from 'react-barcode-reader'
import { setWarning, toastCenterPosition, setError } from '../../../pos-redux/errorHandler/errorHandler.actions';
import { logs } from '../../../pos-redux/troubleshoot/troubleshoot.actions';
import { ButtonName, ScreenName } from '../../../helpers/pos-message';
import { setPayment } from '../../../pos-redux/payment/payment.actions';
import { PaymentConstants } from '../../../pos-redux/payment/payment.constants';

function GiftCardScanCompenent() {
    const dispatch = useDispatch();
    const [uiConfiguration, stateGiftCard] = useSelector(state => [state.uiConfiguration, state.giftCardPayment]);

    const clickBackToPayment = () => {
        logs(ScreenName.GiftCardScanCompenent, "RfidScan.", "clickBackToPayment", uiConfiguration);
        dispatch(setPayment(PaymentConstants.PAYMENT_METHOD, true));
    }

    useEffect(() => {
        //handleRfidScan('Y41232100205672');
    }, [])

    const handleRfidScan = async (val) => {
        logs(ScreenName.GiftCardScanCompenent, "RfidScan.", "handleRfidScan", uiConfiguration);
        var response = await dispatch(checkGiftCardNumber(val, stateGiftCard?.paymentTransactionModel));
        if (response.errorMessage) {
            dispatch(setPayment(PaymentConstants.POPUP_FAILURE, true));
            return;
        } else if (response.balance <= 0) {
            setWarning('Gavekortet har ikke tilstrekkelig saldo.', toastCenterPosition);
            dispatch(setPayment(PaymentConstants.PAYMENT_METHOD, true));
            return;
        }

        dispatch(setPayment(PaymentConstants.GIFT_CARD_PAYMENT, true));
    }

    const handleRfidError = (err) => {
        logs(ScreenName.GiftCardScanCompenent, "RfidScan.", "handleRfidError", uiConfiguration);
        setWarning(err, toastCenterPosition);
    }

    return (
        <div id="giftCardScanComponent" className="text-center inner-section-height tab-content d-flex align-items-center justify-content-lg-center flex-column">
            <h2 className="text-center new-line pt-1 pt-lg-4 py-xl-4">Skann strekkoden på gavekortet</h2>
            <LazyImage
                placeholder={`${preLoadImagePath.scanGiftCard}`}
                uri={`${preLoadImagePath.scanGiftCard}`}
                render={(src, style) => <img src={src} style={style} alt="img" className="width-150px me-1" />}
            />
            <div className="text-center pt-1 pt-lg-4 py-xl-4">
                <Button
                    stylingMode="contained"
                    className="dx-datagrid-search-text btn btn-cancel-color btn mx-auto px-5 mt-3 cursor-hand justify-content-center btn-icon"
                    onClick={clickBackToPayment}> {ButtonName.Cancel}</Button>
            </div>
            <BarcodeReader
                onError={handleRfidError}
                onScan={handleRfidScan}
                minLength={2}
            />
        </div>
    );
}

export { GiftCardScanCompenent }