import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setHeader } from '../../pos-redux/header/header.actions';
import { headerConstants } from '../../pos-redux/header/header.constants';
import './selectBag.component.scss';
import { useEffect } from 'react';
import { decreaseUsedQty, getBagTypes, increaseUsedQty } from '../../pos-redux/selectBag/selectBag.actions';
import { ScrollView } from 'devextreme-react/scroll-view';
import { Button } from 'devextreme-react/button';
import { currencyOptions } from '../../helpers/helper';
import { LoadPanel } from 'devextreme-react';
import { localStorageService } from '../../services/api/utilities/localStorageService';
import { setPayment } from '../../pos-redux/payment/payment.actions';
import { PaymentConstants } from '../../pos-redux/payment/payment.constants';
import LazyImage from 'react-lazy-blur-image';
import { ButtonName, posMessage, ScreenName } from '../../helpers/pos-message';
import { customerClubMemberCheck, customerRfidCheck } from '../../pos-redux/registerProduct/product.actions';
import { ApiUrlConstants } from '../../services/api/apiUrl.constants';
import { preLoadImagePath } from '../../helpers/images';
import { logs } from '../../pos-redux/troubleshoot/troubleshoot.actions';
import { CustomerAccountType } from '../../pos-redux/customer/customer.constant';
import { CartQtyEnum } from '../../helpers/pos-message'

function SelectBagComponent() {
    const [selectBagTypes, stateOrder, uiConfiguration, employeeDetail, memberStartState, customerDetail] = useSelector(state =>
        [state.selectBag
            , state.product
            , state.uiConfiguration
            , state.employeeDetail
            , state.start
            , state.customerDetail]);
    const dispatch = useDispatch();

    useEffect(() => {
        if (selectBagTypes.data.length == 0)
            dispatch(getBagTypes());

        if (selectBagTypes.data.length > 0) {
            if (stateOrder.OrderPos.order.orderLines && stateOrder.OrderPos.order.orderLines.length > 0) {
                for (const item of stateOrder.OrderPos.order.orderLines) {
                    var bagIndex = selectBagTypes.data.findIndex(x => x.id === item.productDetailID);
                    if (bagIndex > -1) {
                        selectBagTypes.data[bagIndex].usedQuantity = item.amount;
                    }
                }
            }
        }

    }, []);

    const bindBagTypesHTML = () => {
        return selectBagTypes.data.map((bagType) => {
            return (
                <div className={selectBagTypes.data.length === 3 ? "more-bag" : "single-bag"} key={bagType.id}>
                    <h3 id="bags-header-text">{bagType.name}</h3>
                    <h3 id="bags-subHeader-text">({(bagType.price).toLocaleString('nb-NB', {
                        ...currencyOptions,
                        style: 'decimal',
                    })} {posMessage.kr}/{posMessage.stk})</h3>
                    <div id="selectBagImg" className="bagMobileView">
                        <div className="dx-theme-background-color dx-theme-border-color bagBox">
                            <LazyImage
                                transitionTime={50}
                                placeholder={`${bagType.imageURL}`}
                                uri={bagType.imageURL ? `${bagType.imageURL}` : `${preLoadImagePath.default_image_245}`}
                                render={(src, style) => <img src={src} style={style} alt="img" />}
                            />
                        </div>
                        <div className="dx-button-mode-contained btn-primary dx-theme-border-color bagBox addSubtractSection p-0 d-flex justify-content-between mt-3">
                            <div className="cursor-hand" onClick={() => changeUsedQty(bagType.name, CartQtyEnum.Minus)}>
                                <i className="icon dx-icon-minus"></i>
                            </div>
                            <div className="dx-theme-background-color">
                                {bagType.usedQuantity}
                            </div>
                            <div className="cursor-hand" onClick={() => changeUsedQty(bagType.name, CartQtyEnum.Plus)}>
                                <i className="icon dx-icon-add cursor-hand"></i>
                            </div>
                        </div>
                    </div>
                </div>
            );
        });
    }

    const changeUsedQty = async (bagName, buttonType) => {
        if (uiConfiguration.allowCustomerCredit == CustomerAccountType.Activated)
            customerRfidCheck(customerDetail, stateOrder);
        else
            var ccMember = customerClubMemberCheck(memberStartState, stateOrder);

        if (buttonType === CartQtyEnum.Minus) {
            logs(ScreenName.SelectBagComponent, buttonType + " " + bagName, "decreaseUsedQty()", uiConfiguration);
            await dispatch(decreaseUsedQty(selectBagTypes.data, bagName, uiConfiguration, employeeDetail, stateOrder))
        }
        if (buttonType === CartQtyEnum.Plus) {
            logs(ScreenName.SelectBagComponent, buttonType + " " + bagName, "increaseUsedQty()", uiConfiguration);
            await dispatch(increaseUsedQty(selectBagTypes.data, bagName, uiConfiguration, employeeDetail, stateOrder))
        }
    }

    const clickNext = () => {
        logs(ScreenName.SelectBagComponent, ButtonName.Next, "setPayment", uiConfiguration);
        dispatch(setHeader(headerConstants.BETAL, true));
        dispatch(setPayment(PaymentConstants.PAYMENT_METHOD, true));
    }

    return (
        <>
            <div className="inner-section-height d-flex align-items-center justify-content-center" id="bagTypes">
                <LoadPanel
                    message={posMessage.loading}
                    shadingColor="rgba(0,0,0,0.4)"
                    visible={selectBagTypes.loading}
                    showIndicator={true}
                />
                <div className="flex-row">
                    <ScrollView
                        scrollByContent={true}
                        showScrollbar={'onScroll'}
                    >
                        <div className="d-flex justify-content-between bagBoxes">
                            {bindBagTypesHTML()}
                        </div>

                    </ScrollView>
                    <div id="bags-btn" className="mt-4 mb-1">
                        {
                            selectBagTypes.data.length ?
                                <Button
                                    type="success"
                                    stylingMode="contained"
                                    width={'9%'}
                                    height={'8%'}
                                    className="btn btn-primary mx-auto px-5 cursor-hand justify-content-center btn-icon" onClick={clickNext}> {ButtonName.Next}</Button>
                                : <></>
                        }
                    </div>
                </div>

            </div>
        </>
    );
}

export { SelectBagComponent };