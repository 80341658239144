import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { RegisterProductConstants } from '../../pos-redux/registerProduct/registerProduct.constants';
import { ProductGroupConfiguration } from '../../pos-redux/registerProduct/productGroupConfiguration.constants';
import { setRegisterProduct, setProductData, getPaginatedData, getProductsAction, setReload, getProductsImagesAction, setRegisterProductWithInformation } from '../../pos-redux/registerProduct/registerProduct.actions';
import { useSelector } from "react-redux";
import { LoadPanel } from 'devextreme-react/load-panel';
import styled from 'styled-components';
import './products.component.scss';
import { Button } from 'devextreme-react/button';
import { setError, setWarning, setMessage } from '../../pos-redux/errorHandler/errorHandler.actions';
import { isMobile } from 'react-device-detect';
import { addProduct, addProductToCart, maxLimitExceeded, customerClubMemberCheck, customerRfidCheck, editProductQty } from '../../pos-redux/registerProduct/product.actions';
import { currencyOptions } from '../../helpers/helper';
import { selectProduct } from '../../pos-redux/registerProduct/addProductWithQty.actions';
import LazyImage from 'react-lazy-blur-image';
import { ButtonName, posMessage, ScreenName, CartQtyEnum } from '../../helpers/pos-message';
import { preLoadImagePath } from '../../helpers/images';
import { productGroupService } from '../../services/product-group.service';
import { showUILoader } from '../../pos-redux/commonUI/commonUI.actions';
import { ProductConstants } from '../../pos-redux/registerProduct/product.constants';
import { setPayment } from '../../pos-redux/payment/payment.actions';
import { PaymentConstants } from '../../pos-redux/payment/payment.constants';
import { setHeader } from '../../pos-redux/header/header.actions';
import { headerConstants } from '../../pos-redux/header/header.constants';
import { logs } from '../../pos-redux/troubleshoot/troubleshoot.actions';
import { productWeightScaling } from '../../pos-redux/troubleshoot/weightScaling/weightScaling.actions';
import { isSpecialProduct, priceFormat, ellipsisText } from '../../helpers/helper';
import { CustomerAccountType } from '../../pos-redux/customer/customer.constant';

function ProductsComponent() {
    const [prdouctsSelector, uiConfiguration, stateOrder, employeeDetail, selectedProduct, commonUI, memberStartState, customerDetail] = useSelector(state => [state.RegisterProduct, state.uiConfiguration, state.product, state.employeeDetail, state.productWithQty, state.commonUIReducer, state.start, state.customerDetail]);
    const dispatch = useDispatch();
    const images = [];
    const { productGroupColumn, productGroupConfiguration, addNewRowExistingProduct, allowCustomerCredit } = useSelector(state => state.uiConfiguration);

    var gridRowContainerCss = "";
    for (let index = 0; index < productGroupColumn; index++) {
        gridRowContainerCss = gridRowContainerCss + " 300px"
    }
    const CustomGrid = styled.div`
        display: grid;
        @media screen and (min-width: 767px) {
            grid-template-columns: ${gridRowContainerCss};
            }
        @media screen and (max-width: 767px) {
            grid-template-columns: 50% 50%;
            }
        grid-template-rows: auto;
        `;

    const clickCancel = () => {
        logs(ScreenName.ProductsComponent, ButtonName.Back, "setRegisterProduct()", uiConfiguration);
        registerProductRedirect();
    }

    const registerProductRedirect = () => {
        if (!prdouctsSelector.isActive)
            return;
        if (prdouctsSelector.name === RegisterProductConstants.ADD_ITEM_GROUP) {
            dispatch(setRegisterProduct(RegisterProductConstants.ADD_PRODUCT, true));
        } else if (prdouctsSelector.name === RegisterProductConstants.ADD_SUBITEM_GROUP) {
            dispatch(setRegisterProduct(RegisterProductConstants.ADD_ITEM_GROUP, true));
        } else if (prdouctsSelector.name === RegisterProductConstants.ALL_PRODUCTS_COMPONENT) {
            var payload = {
                selectedMainGroup: prdouctsSelector.selectedMainGroup
            }
            dispatch(setProductData(RegisterProductConstants.SET_REGISTER_PRODUCT_MAINGROUP_SELECTED, payload));
            dispatch(setRegisterProduct(RegisterProductConstants.ADD_SUBITEM_GROUP, true));
        }
    }

    useEffect(() => {
        if (prdouctsSelector.reload) {
            resetData(true);
            var options = {
                skip: prdouctsSelector.currentPage === 1 ? 0 : prdouctsSelector.currentPage,
                take: prdouctsSelector.dataLimit
            }

            getProducts(options);
            dispatch(setReload(true));
        }
    }, [])

    const getProducts = async (options) => {
        try {
            if (prdouctsSelector.selectedSubGroup) {
                dispatch(showUILoader(true));
                var data = await dispatch(getProductsAction(prdouctsSelector.selectedSubGroup.id, options, 0));
                if (data != null && data.products) {
                    dispatch(showUILoader(false));
                    await dispatch(getProductsImagesAction(data));
                }
                dispatch(showUILoader(false));
            }
        } catch {
            dispatch(showUILoader(false));
            resetData(false);
        }
    }

    const getUnitText = (product) => {
        if (isSpecialProduct(product) && product.unitText) {
            return '/' + ellipsisText(product.unitText);
        }
    }

    const renderGroupName = (product, index, productImages) => {
        var classHTML = '';
        if (Number.isInteger((index + 1) / 3)) {
            classHTML = "btn btn-primary cursor-hand place-Center text-break";
        } else {
            classHTML = "btn btn-primary me-2 cursor-hand place-Center text-break";
        }
        var productImage = productImages.find(pi => pi.productID == product.id);
        return (
            <div className="grid-element me-2 mb-2 subgroup">
                <Button
                    type="normal"
                    stylingMode="outlined"
                    className={classHTML} onClick={() => clickItem(product)}>
                    {productImage && !isMobile &&
                        <LazyImage
                            transitionTime={50}
                            placeholder={`${preLoadImagePath.default_imge}`}
                            uri={productImage.imageURL}
                            render={(src, style) => <img src={src} style={style} alt="img" hidden={productGroupConfiguration === ProductGroupConfiguration.ShowText} />}
                        />
                    }
                    <div id="product-price" className="row">

                        <label className='cursor-hand'>{productGroupConfiguration === ProductGroupConfiguration.ShowIcon ? "" : product.name}
                            <br />
                            <span className="pt-2 dx-button-mode-outlined dx-button-success">
                                {priceFormat(product.salesPrice) + ' ' + posMessage.kr}
                            </span>
                            <span className="dx-button-mode-outlined dx-button-success" id="productUnit">{getUnitText(product)} </span>
                        </label>
                    </div>
                </Button>
            </div>

        );
    }

    const onNext = async () => {
        logs(ScreenName.ProductsComponent, ButtonName.NextPage, "setRegisterProduct()", uiConfiguration);
        var payload = {
            ...prdouctsSelector,
            currentPage: prdouctsSelector.currentPage + 1,
        }

        if (Math.ceil(prdouctsSelector.totalCount / prdouctsSelector.dataLimit) > payload.currentPage) {
            dispatch(showUILoader(true));
            dispatch(setProductData(RegisterProductConstants.PRODUCTS_SUCCESS, payload));

            var options = {
                skip: (payload.currentPage * prdouctsSelector.dataLimit),
                take: prdouctsSelector.dataLimit
            }

            var data = await dispatch(getProductsAction(prdouctsSelector.selectedSubGroup.id, options, payload.currentPage));
            dispatch(showUILoader(false));
            if (data != null && data.products)
                await dispatch(getProductsImagesAction(data));
        } else {
            setWarning(RegisterProductConstants.NO_ITEM_FOUND);
        }
    }

    const onPrevious = async () => {
        logs(ScreenName.ProductsComponent, ButtonName.LastPage, "setProductData()", uiConfiguration)
        if (prdouctsSelector.currentPage > 0) {
            dispatch(showUILoader(true));
            var payload = {
                ...prdouctsSelector,
                currentPage: prdouctsSelector.currentPage - 1
            }

            dispatch(setProductData(RegisterProductConstants.PRODUCTS_SUCCESS, payload));

            var options = {
                skip: (payload.currentPage * prdouctsSelector.dataLimit),
                take: prdouctsSelector.dataLimit
            }

            var data = await dispatch(getProductsAction(prdouctsSelector.selectedSubGroup.id, options, payload.currentPage));
            dispatch(showUILoader(false));
            if (data != null && data.products)
                await dispatch(getProductsImagesAction(data));
        } else {
            setWarning(RegisterProductConstants.NO_ITEM_FOUND);
        }
    }

    const resetData = (loading) => {
        var resetPayLoad = {
            products: [],
            currentPage: 1,
            paginationData: [],
            loading: loading,
            selectedMainGroup: {},
            totalCount: 0
        }
        dispatch(setProductData(RegisterProductConstants.PRODUCTS_SUCCESS, resetPayLoad));
    }

    const clickItem = async (item) => {
        logs(ScreenName.ProductsComponent, item.name, RegisterProductConstants.ADD_PRODUCT + ": " + item.productDetailId, uiConfiguration);

        if (uiConfiguration.skipProductAmountSelection) {
            dispatch(showUILoader(true));
            if (isSpecialProduct(item)) {
                var productResponse = await dispatch(productWeightScaling());
                if (productResponse) {
                    item.usedQuantity = productResponse.Amount;
                } else {
                    dispatch(showUILoader(false));
                    dispatch(setRegisterProduct(RegisterProductConstants.BARCODE_FAILURE, true));
                    return;
                }
            } else {
                item.usedQuantity = 1;
            }

            if (allowCustomerCredit === CustomerAccountType.Activated)
                var customer = customerRfidCheck(customerDetail, stateOrder);
            else
                var ccMember = customerClubMemberCheck(memberStartState, stateOrder);

            var res = await dispatch(addProductToCart(item, uiConfiguration, employeeDetail, stateOrder, ccMember, customer));

            if (res === false) {
                dispatch(showUILoader(false));
                setError(RegisterProductConstants.NO_ITEM_FOUND);
                return;
            }
            if (res === RegisterProductConstants.CALL_FOR_OPERATIONS) {
                dispatch(showUILoader(false));
                dispatch(setRegisterProduct(RegisterProductConstants.CALL_FOR_OPERATIONS, true));
                return;
            }
            if (res === RegisterProductConstants.STRUCTURE_PRODUCT_POPUP) {
                var type = { isStructure: true };
                dispatch(showUILoader(false));
                dispatch(setRegisterProductWithInformation(RegisterProductConstants.STRUCTURE_PRODUCT_POPUP, true, type));
                return;
            }
            dispatch(showUILoader(false));

            if (uiConfiguration.stayInProductSelector) {
                setMessage(RegisterProductConstants.ITEM_ADDED_SUCESSFULLY);
            }
            else {
                dispatch(setRegisterProduct(ProductConstants.ADD_PRODUCT, true));
            }
        }
        else {
            dispatch(selectProduct(item));
            dispatch(setRegisterProduct(RegisterProductConstants.ADD_PRODUCT_WITH_QTY, true));
        }
    }

    const clickPay = () => {
        logs(ScreenName.ProductsComponent, ButtonName.Payment, "setRegisterProduct()", uiConfiguration);
        if (stateOrder.OrderPos && (stateOrder.OrderPos.order.orderTotal > 0)) {
            dispatch(setRegisterProduct(RegisterProductConstants.SET_REGISTER_PRODUCT, false));
            dispatch(setHeader(headerConstants.BETAL, true));
            dispatch(setPayment(PaymentConstants.PAYMENT_METHOD, true));
        }
        else {
            setWarning(posMessage.orderSumIsZero);
        }
    }

    return (
        <div className="tab-pane fade" id="products-component" role="tabpanel" aria-labelledby="nav-profile-tab">
            <div className={isMobile ? "d-flex align-items-center justify-content-center flex-column mv-grid-btn" : "d-flex align-items-center justify-content-center flex-column"}>
                <div className="text-center break-word mt-4">
                    <h2>{prdouctsSelector.selectedSubGroup.subGroup}</h2>
                    <LoadPanel
                        message={posMessage.loading}
                        shadingColor="rgba(0,0,0,0.4)"
                        visible={commonUI.loading}
                        showIndicator={true}
                    />

                    <CustomGrid id={prdouctsSelector.products && prdouctsSelector.products.length < prdouctsSelector.dataLimit ? "" : (isMobile ? "" : "product-render")} className={isMobile ? "products-grid-icons justify-content-center buttonGropu my-4" : "products-grid-icons justify-content-center buttonGropu my-4 gridHeight"}>
                        {
                            prdouctsSelector.products.map((value, index) => {
                                return renderGroupName(value, index, prdouctsSelector.productImages);
                            })
                        }
                    </CustomGrid>

                    <div className="d-flex my-2 pt-2 actionButtonGroup justify-content-center" id="product-btn">
                        <Button
                            type="success"
                            stylingMode="contained"
                            disabled={prdouctsSelector.currentPage > 0 ? false : true}
                            className="btn btn-primary cursor-hand" onClick={onPrevious}>
                            <img className="vippsIconPhone" src={`${preLoadImagePath.whiteLeftArrow}`} alt="img" /> <span>{ButtonName.LastPage}</span>
                        </Button>
                        <Button
                            stylingMode="contained"
                            className="btn btn-primary cursor-hand" onClick={clickCancel}>
                            <img className={isMobile ? "customIcon mb-1" : "customIcon"} src={`${preLoadImagePath.undo_white}`} alt="img" /> <span>{ButtonName.Back}</span>
                        </Button>
                        <Button
                            type="success"
                            stylingMode="contained"
                            disabled={Math.ceil(prdouctsSelector.totalCount / prdouctsSelector.dataLimit) > prdouctsSelector.currentPage + 1 ? false : true}
                            className="btn btn-primary cursor-hand" onClick={onNext}>
                            {!isMobile
                                ?
                                <> <span>{ButtonName.NextPage}</span>  <img className="vippsIconPhone" src={`${preLoadImagePath.whiteRightArrow}`} alt="img" />  </>
                                :
                                <>  <img className="vippsIconPhone" src={`${preLoadImagePath.whiteRightArrow}`} alt="img" /> <span>{ButtonName.NextPage}</span>  </>
                            }
                        </Button>
                        {
                            uiConfiguration.addPaymentButtonInProducts
                                ?
                                <Button
                                    type="success"
                                    stylingMode="contained"
                                    disabled={stateOrder.OrderPos ? false : true}
                                    className="btn btn-primary cursor-hand align-btn-right" onClick={clickPay}>
                                    <img className="vippsIconPhone" src={`${preLoadImagePath.check}`} alt="img" /> <span>{ButtonName.Payment} </span>
                                </Button>
                                :
                                <></>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export { ProductsComponent }