export const posMessage = {
    userIsNotCustoerClubMember: 'Du er ikke kundeklubbmedlem',
    lengthShouldBeEight: 'Tast inn ditt mobilnummer, 8 siffer',
    corporateCustomerMessage: 'Skann kundekort eller tast inn ditt kundenummer, 8 siffer',
    stk: 'stk',
    stkDot: 'stk.',
    krDot: 'kr.',
    kr: 'kr',
    loading: 'Laster, vennligst vent..',
    orderSumIsZero: 'Total sum bør være større enn 0',
    invalidRfidInput: 'Inngangen ble ikke gjenkjent',
    invalidCustomer: 'Kunden eksisterer ikke',
    retailLinkAPIInternalServerError: 'Noe gikk galt. Vær så snill, prøv på nytt',
    apiErrorLimitRate: 3,
    upper: 'upper',
    bottom: 'bottom'
}

export const paymentMethodEnum = {
    Invoice: 12,
    Vipps: 4,
    BankTerminal: 2,
    GiftCard: 3
}

export const lightEnum = {
    TurnOn: 1,
    TurnOff: 0,
}

export const ScreenName = {
    Welcome: "Welcome page",
    Home: "Home page",
    Greet: "Greet page",
    Troubleshoot: "Troubleshoot page",
    TroubleShootComponent: "TroubleShoot component",
    Memberconfirmation: "Memberconfirmation component",
    CustomerClubMember: "CustomerClubMember component",
    BusinessCustomer: "BusinessCustomer component",
    NotMember: "SELFSERVICECONFIRMATION component",
    AddPhoneNumber: "ADDPHONENUMBER component",
    AddCustomerNumberComponent: "AddCustomerNumber Component",
    CustomerClubComponent: "CustomerClubComponent",
    StartComponent: "StartComponent",
    CorporateCustomerComponent: "CorporateCustomerComponent",
    AddFreeCustomerNumberComponent: "AddFreeCustomerNumberComponent",
    HeaderComponent: "HeaderComponent",
    BarcodeScanFailurePopup: "BarcodeScanFailurePopup",
    CancelLimitPopUpComponent: "CancelLimitPopUpComponent",
    CancelOrder: "cancelOrder",
    MemberCommonPopUpComponent: "MemberCommonPopUpComponent",
    MemberRegisterPopUpComponent: "MemberRegisterPopUpComponent",
    ProductConfirmationComponent: "ProductConfirmationComponent",
    ProductEditComponent: "ProductEditComponent",
    ProductStructureComponentPopup: "ProductStructureComponentPopup",
    SelfServiceConfirmationComponent: "SelfServiceConfirmationComponent",
    VippsRegisterPopUpComponent: "VippsRegisterPopUpComponent",
    AddProductWithQty: "AddProductWithQty",
    CallForOperationsComponent: "CallForOperationsComponent",
    ProductsComponent: "ProductsComponent",
    ItemGroupComponent: "MainGroupComponent",
    SubGroupComponent: "SubGroupComponent",
    AssortmentsComponent: "AssortmentsComponent",
    SelectBagComponent: "SelectBagComponent",
    BankTerminalWaitingComponent: "BankTerminalWaitingComponent",
    VippsPaymentComponent: "VippsPaymentComponent",
    VippsPaymentStatusComponent: "VippsPaymentStatusComponent",
    PaymentInvoiceComponent: "PaymentInvoiceComponent",
    PaymentMethodComponent: "PaymentMethodComponent",
    ProductComponent: "ProductComponent",
    CustomerAccountConfirmationComponent: "CustomerAccountConfirmationComponent",
    CustomerRegisterPurchaseCompenent: "CustomerRegisterPurchaseCompenent",
    GiftCardScanCompenent: "GiftCardScanCompenent",
    GiftCardPaymentComponent: "GiftCardPaymentComponent"
}

export const ButtonName = {
    Ok: "OK",
    Cancel: "Avbryt",
    Yes: "Ja",
    No: "Nei",
    CallForService: "Tilkall betjening",
    Continue: "Fortsett",
    RegisterMoreItems: "Registrer flere varer",
    Finished: "Ferdig",
    RemoveItemLine: "Fjern varelinje",
    Accept: "Godta",
    Add: "Legg til",
    Back: "Tilbake",
    NextPage: "Neste side",
    Payment: "Betaling",
    LastPage: "Forrige side",
    Next: "Neste",
    Empty: "Tøm",
    Bankkort: "Bankkort",
    Faktura: "Faktura",
    Edit: "Endre",
    CustomerClubMember: "Kundeklubbmedlem",
    BusinessCustomer: "Bedriftskunde",
    NotMember: "Jeg er ikke medlem",
    NoThanks: "Nei takk",
    BecomeMember: "Bli medlem",
    ContinueWithout: "Fortsett uten",
    Ansattkonto: 'Ansattkonto',
    ClickConfirm: 'Bekreft',
    Gavekort: "Gavekort",
}

export const CartQtyEnum = {
    Plus: 'plus',
    Minus: 'minus'
}

export const ArticleScanTypeEnum = {
    Scanner: 1,
    Toppmeny: 2,
    Anbrekk: 3,
    HentFraVareregister: 4,
    SelectInSort: 5,
    Search: 6,
    Submeny: 7
}