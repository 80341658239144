import { fetchProvider } from '../utilities/fetchProvider';
import { axiosProvider } from './axiosProvider';

export const apiProvider = {
    fetch: fetchProvider,
    axios: axiosProvider,
    objectToQueryString: objectToString
};

function objectToString(dataOptions) {
    var queryString = Object.keys(dataOptions).map(function (key) {
        return key + '=' + dataOptions[key]
    }).join('&');
    return queryString;
}
