import { RegisterProductConstants } from './registerProduct.constants';
import { productGroupService } from '../../services/product-group.service';
const dataLimit = 12;

//#region Actions
export const productsRequest = () => {
    return {
        type: RegisterProductConstants.PRODUCTS_REQUEST
    }
}

export const productsSuccess = response => {
    return {
        type: RegisterProductConstants.PRODUCTS_SUCCESS,
        payload: response
    }
}

export const productsFailure = error => {
    return {
        type: RegisterProductConstants.PRODUCTS_FAILURE,
        payload: error
    }
}

export const initRegisterProduct = () => {
    return {
        type: RegisterProductConstants.INIT_REGISTER_PRODUCT
    }
}

function setRegisterProduct(name, isActive) {
    return dispatch => {
        dispatch(
            {
                type: RegisterProductConstants.SET_REGISTER_PRODUCT,
                payload: {
                    name,
                    isActive
                }
            })
    };
}

export function setRegisterProductWithInformation(name, isActive, infoDetail) {
    return dispatch => {
        dispatch(
            {
                type: RegisterProductConstants.SET_REGISTER_PRODUCT_INFO,
                payload: {
                    name,
                    isActive,
                    infoDetail
                }
            })
    };
}

function setProductData(type, payload) {
    return dispatch => {
        dispatch(
            {
                type: type,
                payload: payload
            })
    };
}

export function setAssortmentData(type, payload) {
    return dispatch => {
        dispatch(
            {
                type: type,
                payload: payload
            })
    };
}

function getPaginatedData(data, currentPage) {
    if (data != null) {
        const startIndex = currentPage * dataLimit - dataLimit;
        const endIndex = startIndex + dataLimit;
        return data.slice(startIndex, endIndex);
    }
};

export function getPaginatedAssortmentData(data, currentPage, dataLimit) {
    if (data != null) {
        const startIndex = currentPage * dataLimit - dataLimit;
        const endIndex = startIndex + dataLimit;
        return data.slice(startIndex, endIndex);
    }
};

export const setReload = val => {
    return {
        type: RegisterProductConstants.SET_RELOAD,
        payload: val
    }
}
//#endregion
export const getProductsAction = (subGroupID, options, currentPage) => async (dispatch) => {
    await dispatch(productsRequest());
    const response = await productGroupService.getProducts(subGroupID, options);
    if (response && response.data) {
        var payload = {
            products: response.data,
            currentPage: currentPage,
            totalCount: response.totalCount,
            productImages: []
        }

        await dispatch(productsSuccess(payload));
        return payload;
    }
    else {
        await dispatch(productsFailure(response));
        return response;
    }
}

export const getProductsImagesAction = (prdouctsSelector) => async (dispatch) => {
    if (prdouctsSelector.products && prdouctsSelector.products.length > 0) {
        var response = await productGroupService.getProductImages(prdouctsSelector.products.map(p => p.id));
        if (response) {
            var payload = {
                ...prdouctsSelector,
                productImages: response
            }
            await dispatch(productsSuccess(payload));
        }
    }
}

export const updateProduct = (product) => {
    return {
        type: RegisterProductConstants.EDIT_PRODUCT,
        payload: product
    }
}



export { setRegisterProduct, setProductData, getPaginatedData };