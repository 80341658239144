import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from './rootReducer';

const loggerMiddleware = createLogger();

const loggerCustomMiddleware = (store) => (next) => (action) => {
    next(action);
  };

export const store = createStore(
    rootReducer,
    applyMiddleware(
        thunkMiddleware,
        loggerCustomMiddleware
    )
);