import { ProductConstants } from "./product.constants";

const initialState = {
    actionType: '',
    loading: false,
    data: [],
    error: '',
    OrderPos: null,
};

function product(state = initialState, action) {
    switch (action.type) {
        case ProductConstants.ADD_PRODUCT: {
            let payload = action.payload.product;
            let addNewRow = action.payload.addNewRowExistingProduct;


            if (!addNewRow) {
                const index = state.data.findIndex(p => p.id === payload.id);
                if (index >= 0) {
                    state.data[index].usedQuantity++;
                    return {
                        actionType: action.type,
                        loading: false,
                        data: [...state.data],
                        error: ''
                    }
                }
            }

            return {
                actionType: action.type,
                loading: false,
                data: [...state.data, payload],
                error: ''
            }
        }

        case ProductConstants.REMOVE_PRODUCT: {
            let payload = action.payload;
            const index = state.data.findIndex(p => p.id === payload.id);
            state.data.splice(index, 1);

            return {
                actionType: action.type,
                loading: false,
                data: [...state.data],
                error: ''
            }
        }

        case ProductConstants.UPDATE_PRODUCT_USED_QTY:
            return {
                ...state,
                data: action.payload
            }

        case ProductConstants.EDIT_PRODUCT_USED_QTY: {
            let payload = action.payload;
            const index = state.data.findIndex(p => p.id === payload.id);
            if (index > -1) {
                state.data[index].usedQuantity = payload.usedQuantity;
                return {
                    actionType: action.type,
                    loading: false,
                    data: [...state.data],
                    error: ''
                }
            }
        }

        case ProductConstants.INIT_PRODUCT:
            return initialState;

        case ProductConstants.ADD_ORDER_POS:
            return {
                ...state,
                OrderPos: action.payload.OrderPos
            };

        default:
            return state
    }
}

export { product };