import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setStart } from '../../../pos-redux/customer/customer.action';
import { CustomerConstants } from '../../../pos-redux/customer/customer.constant';
import { Button } from 'devextreme-react/button';
import LazyImage from 'react-lazy-blur-image';
import { preLoadImagePath } from '../../../helpers/images';
import { startConstants } from '../../../pos-redux/start/start.constants';
import { initCustomer } from '../../../pos-redux/customer/customer.action';

function CustomerConfirmationComponent() {
    const dispatch = useDispatch();
    const clickEmployeeAccount = () => {
        dispatch(setStart(CustomerConstants.CUSTOMER_REGISTER_PURCHASE, true));
    }
    const clickNotEmployee = () => {
        dispatch(initCustomer());
        dispatch(setStart(startConstants.SELF_SERVICE_CONFIRMATION, true));
    }
    return (
        <div id="memberConfirmationComponent" className="text-center inner-section-height tab-content d-flex align-items-center justify-content-lg-center flex-column">
            <h2 className="mb-2 mb-md-4 mb-xl-4 new-line">Har du ansattkonto hos oss?</h2>
            <div className="d-flex py-4 flex-column flex-lg-row">
                <Button
                    type="success"
                    stylingMode="contained"
                    className="btn cursor-hand btn-icon" onClick={clickEmployeeAccount}>
                    <LazyImage
                        placeholder={`${preLoadImagePath.default_image_55}`}
                        uri={`${preLoadImagePath.user2}`}
                        render={(src, style) => <img src={src} alt="img" className="w-30 me-3 img-50" />}
                    />
                    Ansattkonto
                </Button>
            </div>
            <div className="text-center pt-0 pt-lg-4 py-xl-4">
                <Button
                    stylingMode="contained"
                    className="btn btn-primary mx-auto w-100-mobile cursor-hand btn-icon" onClick={clickNotEmployee}>
                    <LazyImage
                        placeholder={`${preLoadImagePath.default_image_55}`}
                        uri={`${preLoadImagePath.not_user}`}
                        render={(src, style) => <img src={src} alt="img" className="w-50 me-3 img-50" />}
                    />
                    Ingen ansattkonto
                </Button>
            </div>
        </div>

    );
}

export { CustomerConfirmationComponent }