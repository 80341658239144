
import { useDispatch, useSelector } from 'react-redux';
import './cancelOrderPopup.component.scss';
import { setRegisterProduct } from '../../pos-redux/registerProduct/registerProduct.actions';
import { RegisterProductConstants } from '../../pos-redux/registerProduct/registerProduct.constants';
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import { useState } from 'react';
import { Button } from 'devextreme-react/button';
import { initProduct } from '../../pos-redux/registerProduct/product.actions';
import { initSelectedproduct } from '../../pos-redux/registerProduct/addProductWithQty.actions';
import { useHistory } from 'react-router-dom';
import { initEmployee } from '../../pos-redux/employee/employee.actions';
import { setHeader } from '../../pos-redux/header/header.actions';
import { setStart, initStart } from '../../pos-redux/start/start.actions';
import { startConstants } from '../../pos-redux/start/start.constants';
import { headerConstants } from '../../pos-redux/header/header.constants';
import { initRegisterProduct } from '../../pos-redux/registerProduct/registerProduct.actions';
import { ButtonName, ScreenName, posMessage } from '../../helpers/pos-message';
import { logs } from '../../pos-redux/troubleshoot/troubleshoot.actions';
import { posOrderCancel, customerClubMemberCheck, customerRfidCheck } from '../../pos-redux/registerProduct/product.actions';
import { CustomerAccountType } from '../../pos-redux/customer/customer.constant';
import { initBags, resetBagQty } from '../../pos-redux/selectBag/selectBag.actions';
import { showUILoader } from '../../pos-redux/commonUI/commonUI.actions';
import { LoadPanel } from 'devextreme-react';
import { useEffect } from 'react';

function CancelOrderPopup() {
    const [visiblePopup, setVisiblePopup] = useState(true);
    const dispatch = useDispatch();
    const history = useHistory();
    const cancelOrderMessage = 'Vil du avbryte denne ordren?';
    const [uiConfiguration, stateOrder, customerDetail, memberStartState, employeeDetail, commonUI] = useSelector(state => [state.uiConfiguration
        , state.product
        , state.customerDetail
        , state.start
        , state.employeeDetail
        , state.commonUIReducer]); 

    const cancelOrder = async () => {
        dispatch(showUILoader(true));

        logs(ScreenName.CancelOrder, ButtonName.Yes, "cancelOrder() function and move to " + startConstants.MEMBER_COMFIRMATION, uiConfiguration);
        var posOrder = stateOrder.OrderPos?.order;
        if (posOrder && posOrder.orderLines.length > 0) {
            if (uiConfiguration.allowCustomerCredit === CustomerAccountType.Activated)
                var customer = customerRfidCheck(customerDetail, stateOrder);
            else
                var ccMember = customerClubMemberCheck(memberStartState, stateOrder);
            var res = await dispatch(posOrderCancel(stateOrder.OrderPos, uiConfiguration, employeeDetail, stateOrder, ccMember, customer));
        }

        dispatch(initBags());
        dispatch(initProduct());
        dispatch(initRegisterProduct());
        dispatch(initSelectedproduct());
        dispatch(initEmployee());
        dispatch(initStart());
        setVisiblePopup(false);
        dispatch(setHeader(headerConstants.START, true));
        dispatch(setStart(startConstants.MEMBER_COMFIRMATION, true));

        dispatch(showUILoader(false));


        history.push("/welcome");
    }

    const hidePopup = () => {
        logs(ScreenName.CancelOrder, ButtonName.No, "Hide cancelOrder PopUp", uiConfiguration);
        setVisiblePopup(false);
        dispatch(setRegisterProduct(RegisterProductConstants.ADD_PRODUCT, true));
    }
    return (
        <Popup
            visible={visiblePopup}
            onHiding={hidePopup}
            height='auto'
            showCloseButton={false}
            showTitle={false}
            container="#pos"
        >
            <div id="cancelOrderPopup" className="text-center inner-section-height tab-content d-flex align-items-center justify-content-lg-center flex-column">

                <LoadPanel
                    message={posMessage.loading}
                    shadingColor="rgba(0,0,0,0.4)"
                    visible={commonUI.loading}
                    showIndicator={true}
                />
                <div className="text-center">
                    <h2 className="mt-3">{cancelOrderMessage}</h2>

                    <div className="d-flex justify-content-center pt-4 actionButtonGroup" id="product-btn">
                        <Button
                            type="success"
                            stylingMode="contained"
                            className="d-lg-flex d-none btn btn-primary cursor-hand btn-icon m-2 px-5 btn-font-xs" onClick={cancelOrder}>
                            {ButtonName.Yes}
                        </Button>

                        <Button
                            type="danger"
                            stylingMode="contained"
                            className="d-lg-flex d-none btn btn-primary cursor-hand btn-icon m-2 px-5 btn-font-xs" onClick={hidePopup}>
                            {ButtonName.No}
                        </Button>
                    </div>
                </div>
            </div>
        </Popup>

    );
}

export { CancelOrderPopup }