import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'devextreme-react/button';
import { Popup } from 'devextreme-react/popup';
import './memberRegisterPopup.component.scss';
import { startConstants } from '../../pos-redux/start/start.constants';
import { setStart, initStart } from '../../pos-redux/start/start.actions';
import { ButtonName, ScreenName } from '../../helpers/pos-message';
import { logs } from '../../pos-redux/troubleshoot/troubleshoot.actions';

function MemberRegisterPopUpComponent() {
    const dispatch = useDispatch();
    const start = useSelector(state => state.start);
    const [uiConfiguration] = useSelector(state => [state.uiConfiguration]);

    const numberpad = useSelector(state => state.numberPad);
    const headerTxt = 'Du er ikke registert i kundeklubb på dette telefonnummeret, vennligst hendvend deg';
    const hederConfirmTxt = 'i kasse for registering eller for å legge til ditt telefonnummer.';

    const headerCCTxt = 'Ditt kundeklubb medlemskap er ikke aktivert i denne butikken';
    const hederCCConfirmTxt = 'vennligst henvend deg i en betjent kasse.';

    const headerCustomerClubTxt = 'Kundeklubb medlemskap funnet';

    const clickOK = () => {
        logs(ScreenName.MemberRegisterPopUpComponent , ButtonName.Ok, startConstants.SELF_SERVICE_CONFIRMATION, uiConfiguration);
        dispatch(initStart());
        dispatch(setStart(startConstants.SELF_SERVICE_CONFIRMATION, true));
    }

    const continueAsCustomerClubMember = () => {
        logs(ScreenName.MemberRegisterPopUpComponent , ButtonName.Continue, startConstants.SELF_SERVICE_CONFIRMATION, uiConfiguration);
        dispatch(setStart(startConstants.SELF_SERVICE_CONFIRMATION, true));
    }

    const renderCustomerClubRegisterPopUp = () => {
        return (
            <div id="member-popup" className="text-center inner-section-height tab-content d-flex align-items-center justify-content-lg-center flex-column">
                <h2 className="popUptext">{headerCCTxt}</h2>
                <br />
                <h2 className="popUptext">{hederCCConfirmTxt}</h2>
                <br />
                <div className="text-center">
                    <div className="d-flex h-100 justify-content-center align-items-center mt-3">
                        <Button
                            type="success"
                            stylingMode="contained"
                            className="btn cursor-hand btn-icon btn-font-xs" onClick={clickOK}>
                            {ButtonName.Ok}
                    </Button>
                    </div>
                </div>
            </div>);
    }

    const renderNotCustomerClubMemberPopUp = () => {
        return (<div id="member-popup" className="text-center inner-section-height tab-content d-flex align-items-center justify-content-lg-center flex-column">
            <h2 className="popUptext">{headerTxt}</h2>
            <br />
            <h2 className="popUptext">{hederConfirmTxt}</h2>
            <br />
            <div className="text-center">
                <div className="d-flex py-4 flex-column flex-lg-row">

                    <Button
                        type="success"
                        stylingMode="contained"
                        className="btn cursor-hand btn-icon btn-font-xs" onClick={clickOK}>
                        {ButtonName.Ok}
                </Button>
                </div>
            </div>
        </div>);
    }

    const renderCustomerClubMemberPopUp = () => {
        return (<div id="member-popup" className="text-center inner-section-height tab-content d-flex align-items-center justify-content-lg-center flex-column">
            <h2 className="popUptext">{headerCustomerClubTxt}</h2>
            <br />
            <div className="text-center">
                <div className="d-flex py-4 flex-column flex-lg-row">

                    <Button
                        type="success"
                        stylingMode="contained"
                        className="btn cursor-hand btn-icon btn-font-xs" onClick={continueAsCustomerClubMember}>
                        {ButtonName.Continue}
            </Button>
                </div>
            </div>
        </div>);
    }

    const renderPopUp = () => {
        if (start.actionType === startConstants.CUSTOMERCLUB_MEMBER && start.data.customerClubID == null) {
            return renderNotCustomerClubMemberPopUp();
        } else if (start.actionType === startConstants.CUSTOMERCLUB_MEMBER && start.data.customerClubID != null && !start.data.hasLocalCustomer) {
            return renderCustomerClubRegisterPopUp();
        } else if (start.actionType === startConstants.CUSTOMERCLUB_MEMBER && start.data.customerClubID != null && start.data.hasLocalCustomer) {
            return renderCustomerClubMemberPopUp();
        }
    }

    return (
        <Popup
            visible={true}
            width="95%"
            height={640}
            showTitle={false}
            dragEnabled={false}
            container="#pos"
        >
            {
                renderPopUp()
            }
        </Popup>
    );
}

export { MemberRegisterPopUpComponent }