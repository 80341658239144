
import { useDispatch, useSelector } from 'react-redux';
import './barcodeScanFailurePopup.component.scss';
import { Popup } from 'devextreme-react/popup';
import { useEffect, useState } from 'react';
import { Button } from 'devextreme-react/button';
import { ButtonName, ScreenName } from '../../helpers/pos-message';
import { logs } from '../../pos-redux/troubleshoot/troubleshoot.actions';
import { setPayment } from '../../pos-redux/payment/payment.actions';
import { PaymentConstants } from '../../pos-redux/payment/payment.constants';
import { GiftCardConstants } from '../../pos-redux/payment/giftCard/giftCard.constants';

function GiftCardPopupComponent() {
    const [visiblePopup, setVisiblePopup] = useState(true);
    const dispatch = useDispatch(); 
    const [uiConfiguration, giftCardReducer] = useSelector(state => [state.uiConfiguration, state.giftCardPayment]);

    const hidePopup = () => {
        logs(ScreenName.GiftCardPopupComponent, ButtonName.Ok, "Hide GiftCardPopupComponent Popup", uiConfiguration);
        setVisiblePopup(false);
        dispatch(setPayment(PaymentConstants.GIFT_CARD_SCAN, true));
    }

    return (
        <Popup
            visible={visiblePopup}
            onHiding={hidePopup}
            height='auto'
            showCloseButton={false}
            showTitle={false}
            container="#pos"
        >

            <div id="giftCardPopupComponent" className="text-center inner-section-height tab-content d-flex align-items-center justify-content-lg-center flex-column">
                <h2 className="popUptext">{giftCardReducer.actionType === GiftCardConstants.GIFTCARD_INITIATE_PAYMENT_SUCCESS ? giftCardReducer?.data?.errorMessage : ''}</h2>
                <br />
                <div className="text-center">
                    <div className="d-flex h-100 justify-content-center align-items-center">
                        <Button
                            type="success"
                            stylingMode="contained"
                            className="btn btn-primary m-2 px-5 cursor-hand btn-icon btn-font-xs" onClick={hidePopup}>
                            {ButtonName.Ok}
                        </Button>
                    </div>
                </div>
            </div>
        </Popup>

    );
}

export { GiftCardPopupComponent }