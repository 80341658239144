import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'devextreme-react/button';
import { Popup } from 'devextreme-react/popup';
import './memberRegisterPopup.component.scss';
import { PaymentConstants } from '../../pos-redux/payment/payment.constants';
import { setPayment } from '../../pos-redux/payment/payment.actions';
import { ButtonName, ScreenName } from '../../helpers/pos-message';
import { logs } from '../../pos-redux/troubleshoot/troubleshoot.actions';

function VippsRegisterPopUpComponent() {
    const dispatch = useDispatch();
    const start = useSelector(state => state.start);
    const [uiConfiguration] = useSelector(state => [state.uiConfiguration]);

    const numberpad = useSelector(state => state.numberPad);
    const headerTxt = 'Mobilnummer {mrNo} er ikke registrert  hos Vipps.';
    const hederConfirmTxt = 'Velg betalingsmåte på nytt';

    const clickOK = () => {
        logs(ScreenName.VippsRegisterPopUpComponent , ButtonName.Ok, "setPayment(PaymentConstants.PAYMENT_METHOD, true)", uiConfiguration);
        dispatch(setPayment(PaymentConstants.PAYMENT_METHOD, true));
    }

    const renderRegisterPopUp = () => {
        return (
            <div id="member-popup" className="text-center inner-section-height tab-content d-flex align-items-center justify-content-lg-center flex-column">
                <h2 className="popUptext">{headerTxt.replace('{mrNo}', numberpad.number).toString()}</h2>
                <br/>
                <h2 className="popUptext">{hederConfirmTxt}</h2>
                <br/>
                <div className="d-flex h-100 justify-content-center align-items-center pt-4">
                    <div className="d-flex h-100 justify-content-center align-items-center">
                        <Button
                            type="success"
                            stylingMode="contained"
                            className="btn btn-primary mx-auto px-5 cursor-hand btn-icon btn-font-xs" onClick={clickOK}>
                            {ButtonName.Ok}
                    </Button>
                    </div>
                </div>
            </div>);
    }

    return (
        <Popup
            visible={true}
            width="95%"
            height={640}
            showTitle={false}
            dragEnabled={false}
            container="#pos"
        >
            {
                renderRegisterPopUp()
            }
        </Popup>
    );
}

export { VippsRegisterPopUpComponent }