import { posAgentService } from '../../../services/posAgent.service'
import { WeightScalingConstants } from '../weightScaling/weightScaling.constants';
import { dateFormat } from '../../../helpers/helper';

//#region Actions
export const initWeightScaling = () => {
    return {
        type: WeightScalingConstants.INIT_WEIGHTSCALING
    }
}

export const getRequest = () => {
    return {
        type: WeightScalingConstants.GET_REQUEST
    }
}

export const getSuccess = (response) => {
    return {
        type: WeightScalingConstants.GET_SUCCESS,
        payload: response
    }
}

export const getFailure = error => {
    return {
        type: WeightScalingConstants.GET_FAILURE,
        payload: error
    }
}

export const productWeightScaling = () => async dispatch => {
    dispatch(getRequest());
    var requestDate = dateFormat(new Date());
    const response = await posAgentService.productWeightScaling(requestDate);
    if (response) {
        dispatch(getSuccess(response));
    }
    else {
        dispatch(getFailure());
    }

    return response;
}