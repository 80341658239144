import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'devextreme-react/button';
import { RegisterProductConstants } from '../../pos-redux/registerProduct/registerProduct.constants';
import { setRegisterProduct } from '../../pos-redux/registerProduct/registerProduct.actions';
import { Popup } from 'devextreme-react/popup';
import './cancelLimitPopup.component.scss';
import { getEmployeeDetailByNumber } from '../../pos-redux/employee/employee.actions';
import { setError, setWarning } from '../../pos-redux/errorHandler/errorHandler.actions';
import BarcodeReader from 'react-barcode-reader'
import { occupiedLight } from '../../pos-redux/uiConfiguration/uiConfiguration.actions';
import { ButtonName, lightEnum, ScreenName } from '../../helpers/pos-message';
import { logs } from '../../pos-redux/troubleshoot/troubleshoot.actions';

function CancelLimitPopUpComponent() {
    const [visiblePopup, setVisiblePopup] = useState(true);
    const dispatch = useDispatch();
    const [employeeDetail, startTroubleShoot] = useSelector(state => [state.employeeDetail, state.troubleShootReducer]);
    const [uiConfiguration] = useSelector(state => [state.uiConfiguration]);

    const canelLimitTxt = 'Betjeningen er varslet \n og vil hjelpe deg så snart som mulig.';

    useEffect(() => {
        // This is testing purpose , if barcode reader is not available
        //  handleBarcodeScan('X112200033085');
    }, []);

    const hidePopup = () => {
        logs(ScreenName.CancelLimitPopUpComponent , ButtonName.Ok, "Hide CancelLimitPopUpComponent PopUp", uiConfiguration);
        setVisiblePopup(false);
        dispatch(setRegisterProduct(RegisterProductConstants.ADD_PRODUCT, true));
    }

    const handleBarcodeScan = async (val) => {
        logs(ScreenName.CancelLimitPopUpComponent , "Barcode Scaned", "scaned a barcode ");
        if (!employeeDetail.validCard) {
            if (startTroubleShoot?.data?.data) {
                if (startTroubleShoot?.data?.data.LightTowerVisible) {
                    dispatch(occupiedLight(lightEnum.TurnOn));
                }
            }
            dispatch(getEmployeeDetailByNumber(val));
        }
    }

    const handleBarcodeError = (err) => {
        dispatch(setRegisterProduct(RegisterProductConstants.BARCODE_FAILURE, true));
    }

    return (
        <Popup
            visible={true}
            onHiding={hidePopup}
            height='auto'
            showCloseButton={false}
            showTitle={false}
            container="#pos"
        >
            <div id="cancelLimitPopup" className="text-center inner-section-height tab-content d-flex align-items-center justify-content-lg-center flex-column">
                <div class="text-center">
                    <h2 className="popUptext">{canelLimitTxt.split('\n').map((txt) => <span><br />{txt}</span>)}</h2>
                    <br />
                    <div className="text-center">
                        <div className="d-flex h-100 justify-content-center align-items-center">
                            <Button
                                type="success"
                                stylingMode="contained"
                                className="btn btn-primary m-2 px-5 cursor-hand btn-icon btn-font-xs"
                                onClick={hidePopup} >{ButtonName.Ok}</Button>
                        </div>
                    </div>
                </div>
            </div>

            <BarcodeReader
                onError={handleBarcodeError}
                onScan={handleBarcodeScan}
                minLength={1}
            />
        </Popup>

    );
}

export { CancelLimitPopUpComponent }