import { env } from './posenv';

const prod = {
    apiGateway: {
        API_URL: env.REACT_GATEWAY_API || "http://localhost:81"
    },
    vipps: {
        API_URL: "https://vipps.touchsoft.no/v1"
    }
};

const stage = {    
    apiGateway: {
        API_URL: env.REACT_GATEWAY_API || "https://portalapi.touchsoft.akeodev.com"
    },
    vipps: {
        API_URL: "https://vipps.touchsoft.akeodev.com/v1"
    }
};

const dev = {
    apiGateway: {
        API_URL: "https://localhost:44382/v1/api"
    },
    vipps: {
        API_URL: "https://vipps.touchsoft.akeodev.com/v1"
    }
};

export function getAppSettings() {
    if (process.env.REACT_APP_ENV !== undefined && process.env.REACT_APP_ENV.toString().trim() === "production") {
        return prod;
    }
    else if (process.env.REACT_APP_ENV !== undefined && process.env.REACT_APP_ENV.toString().trim() === "staging") {
        return stage;
    }
    else {
        return dev;
    }
} 