import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Popup } from 'devextreme-react/popup';
import LazyImage from 'react-lazy-blur-image';
import { preLoadImagePath } from '../../helpers/images';
import './statusCheckPopUp.component.scss';
import { getCheckAgentStatus, getAgentSuccess } from '../../pos-redux/troubleshoot/troubleshoot.actions';
import { assistanceLight } from '../../pos-redux/uiConfiguration/uiConfiguration.actions';
import { lightEnum } from '../../helpers/pos-message';

export function StatusCheckPopUpComponent() {
    const dispatch = useDispatch();
    const [startTroubleShoot, uiConfiguration] = useSelector(state => [state.troubleShootReducer, state.uiConfiguration]);
    const [towerLight, setTowerLight] = useState(false);
    const [popUpVisible, setPopUpVisible] = useState(true);

    const selfServiceHeaderTxt = "Oisann. Noe er galt";
    const selfServiceTxt = "Vennligst prøv igjen senere eller gjennomfør kjøpet i en betjent kasse.";

    const outOfServiceHeaderTxt = "Selvbetjent kasse er stengt";
    const outOfServiceTxt = uiConfiguration.messageOnClosedService;
    const telephone = "Telefon";

    useEffect(() => {
        // only run where hardware or pos-agent response get.
        if (startTroubleShoot?.data && startTroubleShoot?.data.length !== 0) {
            renderView();
        }
    }, []);

    const renderView = () => {
        const timerId = setInterval(() => {
            var urlPaths = window.location.href.split('#');
            if (urlPaths[1] === '/welcome') {
                dispatch(getCheckAgentStatus());
            } else {
                clearInterval(timerId);
            }
        }, 10000);
    }

    function iconsSet(data) {
        if (data) {
            return (Object.values(data).map((value, index) => {
                if (value) {
                    if (data) {
                        if (data.LightTowerVisible && !towerLight) {
                            dispatch(assistanceLight(lightEnum.TurnOn));
                            setTowerLight(true);
                        }
                        if (data.PaymentTerminalConnected && popUpVisible) {
                            setPopUpVisible(false);
                        }
                    }
                    return <span className="dx-button-mode-contained dx-button-success"></span>
                } else if (index == 2) { // For LightTower
                    return <></>
                }
                else {
                    return <span className="dx-button-mode-contained dx-button-danger"></span>
                }
            }))
        } else {
            return <span className="light-backGround-color-grey"></span>
        }
    }

    const renderPaymentGateway = () => {
        if (!uiConfiguration.isBankTerminalConnectionExists) {
            return <> </>
        }

        return (
            <>
                <div id="self-service-status" className="text-center inner-section-height tab-content d-flex align-items-center justify-content-lg-center flex-column">
                    <LazyImage
                        placeholder={`${preLoadImagePath.default_image_150}`}
                        uri={`${preLoadImagePath.warning_black}`}
                        className="width-150px"
                        render={(src, style) => <img className="img-150" src={src} alt="img" />}
                    />
                    <br />
                    <h2 className="popUptext">{selfServiceHeaderTxt}</h2>
                    <br />
                    <br />
                    <div className="text-center">
                        <h2 className="popUptext">{selfServiceTxt}</h2>
                    </div>
                </div>

                <div id="icon" className="position-icons">
                    {
                        iconsSet(startTroubleShoot?.data?.data)
                    }
                </div>
            </>
        )
    }

    const renderErrorReason = () => {
        return (
            <>
                <div id="self-service-status" className="text-center inner-section-height tab-content d-flex align-items-center justify-content-lg-center flex-column">
                    <LazyImage
                        placeholder={`${preLoadImagePath.default_image_150}`}
                        uri={`${preLoadImagePath.warning_black}`}
                        className="width-150px"
                        render={(src, style) => <img className="img-150" src={src} alt="img" />}
                    />
                    <br />
                    <h2 className="popUptext">{selfServiceHeaderTxt}</h2>
                    <br />
                    <br />
                    <div className="text-center">
                        <h2 className="popUptext">{selfServiceTxt}</h2>
                    </div>
                </div>
            </>
        )
    }

    const renderOutOfService = () => {
        var indexOfTelephone = outOfServiceTxt.includes(telephone) ? outOfServiceTxt.indexOf(telephone) : -1;
        var supportText = outOfServiceTxt;
        if (indexOfTelephone >= 0) {
            supportText = outOfServiceTxt.substr(0, indexOfTelephone).concat('\n\n ').concat(supportText.substr(indexOfTelephone));
        }

        return (
            <>
                <div id="self-service-status" className="text-center inner-section-height tab-content d-flex align-items-center justify-content-lg-center flex-column">
                    <LazyImage
                        placeholder={`${preLoadImagePath.default_image_150}`}
                        uri={`${preLoadImagePath.lock_black}`}
                        className="width-150px"
                        render={(src, style) => <img className="img-150" src={src} alt="img" />}
                    />
                    <br />
                    <h2 className="popUptext">{outOfServiceHeaderTxt}</h2>

                    <div className="text-center">
                        <h2 className="popUptext">{supportText.split('\n').map((txt) => <span><br />{txt}</span>)}</h2>
                    </div>
                </div>
            </>
        )
    }

    return (
        <Popup
            visible={popUpVisible}
            width="95%"
            height={640}
            showTitle={false}
            dragEnabled={false}
            container="#posStatusChecker"
        >
            {
                startTroubleShoot?.data && startTroubleShoot?.data.length !== 0
                    ?
                    (startTroubleShoot?.data?.data?.ServiceStatusClosed && uiConfiguration.isClosedSelfServiceSystem ? renderOutOfService() : renderPaymentGateway())
                    :
                    renderErrorReason()
            }
        </Popup>
    );
} 