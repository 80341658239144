import React from 'react';
import { useSelector } from 'react-redux';
import { RegisterProductConstants } from '../../pos-redux/registerProduct/registerProduct.constants';
import { AddProductComponent } from './addProduct.component';
import { AddItemGroupComponent } from './ItemGroup/itemGroup.component';
import { CallForOperationsComponent } from './callForOperations.component';
import { AddSubGroupComponent } from './ItemGroup/subGroup.component';
import { ProductConfirmationComponent } from '../popup/productConfirmation.component';
import { ProductsComponent } from './products.component';
import { CancelOrderPopup } from '../popup/cancelOrderPopup';
import { AddProductWithQty } from './addProductWithQty';
import { ProductEditComponent } from '../popup/productEdit.component';
import { BarcodeScanFailurePopup } from '../popup/barcodeScanFailurePopup.component';
import { CancelLimitPopUpComponent } from '../popup/cancelLimitPopup.component';
import { AssortmentComponent } from './Assortment/assortment.component';
import { ProductStructureComponentPopup } from '../popup/productStruturePopup.compoent';

function RegisterProductComponent() {
    const RegisterProduct = useSelector(state => state.RegisterProduct);
    return (
        <div>
            {RegisterProduct.name === RegisterProductConstants.ADD_PRODUCT && RegisterProduct.isActive ? <AddProductComponent /> : ''}
            {RegisterProduct.name === RegisterProductConstants.ADD_ITEM_GROUP && RegisterProduct.isActive ? <AddItemGroupComponent /> : ''}
            {RegisterProduct.name === RegisterProductConstants.ADD_SUBITEM_GROUP && RegisterProduct.isActive ? <AddSubGroupComponent /> : ''}
            {RegisterProduct.name === RegisterProductConstants.CALL_FOR_OPERATIONS && RegisterProduct.isActive ? <CallForOperationsComponent /> : ''}
            {RegisterProduct.name === RegisterProductConstants.ADD_PRODUCT_WITH_QTY && RegisterProduct.isActive ? <AddProductWithQty /> : ''}
            {RegisterProduct.name === RegisterProductConstants.CANCEL_ORDER && RegisterProduct.isActive ? <CancelOrderPopup /> : ''}
            {RegisterProduct.name === RegisterProductConstants.BARCODE_FAILURE && RegisterProduct.isActive ? <BarcodeScanFailurePopup /> : ''}
            {RegisterProduct.name === RegisterProductConstants.PRODUCT_CONFIRMATION && RegisterProduct.isActive ? <ProductConfirmationComponent /> : ''}
            {RegisterProduct.name === RegisterProductConstants.ALL_PRODUCTS_COMPONENT && RegisterProduct.isActive ? <ProductsComponent /> : ''}
            {RegisterProduct.name === RegisterProductConstants.EDIT_PRODUCT && RegisterProduct.isActive ? <ProductEditComponent /> : ''}
            {RegisterProduct.name === RegisterProductConstants.CALL_FOR_HELP && RegisterProduct.isActive ? <CancelLimitPopUpComponent /> : ''}
            {RegisterProduct.name === RegisterProductConstants.ASSORTMENT_COMPONETNT && RegisterProduct.isActive ? <AssortmentComponent /> : ''}
            {RegisterProduct.name === RegisterProductConstants.STRUCTURE_PRODUCT_POPUP && RegisterProduct.isActive ? <ProductStructureComponentPopup /> : ''}
        </div>
    );
}

export { RegisterProductComponent };