import { PaymentConstants } from './payment.constants';

function setPayment(name, isActive) {
    return dispatch => {
        dispatch(
            {
                type: PaymentConstants.SET_PAYMENT,
                payload: {
                    name,
                    isActive
                }
            })
    };
}

function setPOSOrder(name, isActive, posOrder) {
    return dispatch => {
        dispatch(
            {
                type: PaymentConstants.POS_CONFIRM_ORDER,
                payload: {
                    name,
                    isActive,
                    posOrder
                }
            })
    };
}

export { setPayment, setPOSOrder };