import { VippsPaymentConstants } from "./vippsPayment.constant";

const initialState = {
    actionType: '',
    loading: false,
    data: [],
    error: ''
};

function vippsPayment(state = initialState, action) {
    switch (action.type) {
        case VippsPaymentConstants.INITIATE_PAYMENT_REQUEST:
            return {
                ...state,
                actionType: action.type,
                loading: true
            }

        case VippsPaymentConstants.INITIATE_PAYMENT_SUCCESS:
            return {
                actionType: action.type,
                loading: false,
                data: action.payload,
                error: ''
            }

        case VippsPaymentConstants.INITIATE_PAYMENT_FAILURE:
            return {
                actionType: action.type,
                loading: false,
                data: [],
                error: action.payload
            }

        case VippsPaymentConstants.PAYMENT_STATUS_REQUEST:
            return {
                ...state,
                actionType: action.type,
                loading: true
            }

        case VippsPaymentConstants.PAYMENT_STATUS_SUCCESS:
            return {
                actionType: action.type,
                loading: false,
                data: action.payload,
                error: ''
            }

        case VippsPaymentConstants.PAYMENT_STATUS_FAILURE:
            return {
                actionType: action.type,
                loading: false,
                data: [],
                error: action.payload
            }

        case VippsPaymentConstants.CANCEL_PAYMENT_REQUEST:
            return {
                ...state,
                actionType: action.type,
                loading: true
            }

        case VippsPaymentConstants.CANCEL_PAYMENT_SUCCESS:
            return {
                actionType: action.type,
                loading: false,
                data: action.payload,
                error: ''
            }

        case VippsPaymentConstants.CANCEL_PAYMENT_FAILURE:
            return {
                actionType: action.type,
                loading: false,
                data: [],
                error: action.payload
            }

        case VippsPaymentConstants.PENDING_PAYMENT_REQUEST:
            return {
                ...state,
                actionType: action.type,
                loading: true
            }

        case VippsPaymentConstants.PENDING_PAYMENT_SUCCESS:
            return {
                actionType: action.type,
                loading: false,
                data: action.payload,
                error: ''
            }

        case VippsPaymentConstants.PENDING_PAYMENT_FAILURE:
            return {
                actionType: action.type,
                loading: false,
                data: [],
                error: action.payload
            }

        case VippsPaymentConstants.INIT_VIPPS:
            return initialState;

        default:
            return state
    }
}

export { vippsPayment };