import React from "react";
import { HashRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import routes from '../Pos/pos-routes';
import "./App.scss";
import 'simple-keyboard/build/css/index.css';
import { useEffect } from "react";
import { hideVirtualKeyBoard, initVirtualKeyboard } from "../common/virtualkeyboard";
import { isMobile } from "react-device-detect";
import { apiProvider } from '../Pos/services/api/utilities/provider';

export const Settings = {
  AppErrorHandler
}

function AppErrorHandler() {
  return apiProvider.fetch.readJSON('errors-handler.json');
}

function App() {
  return (
    <>
      <div id="pos">
        <div className={"content"}>
          <Router>
            <Switch>
              {/* <Route exact path="/home" component={HomePage} />
            <Route path="/welcome" component={WelcomePage} />
            <Redirect from="*" to="/welcome" /> */}

              {routes.map(({ path, component }) => (
                <Route
                  exact
                  key={path}
                  path={path}
                  component={component}
                />
              ))}
              <Redirect to={'/welcome'} />
            </Switch>
          </Router>

        </div>
      </div>
    </>
  );
}

export { App };
