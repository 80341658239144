import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from 'react';
import { Button } from 'devextreme-react/button';
import { posAgentService } from '../../services/posAgent.service'
import { showUILoader, waitingTimer } from '../../pos-redux/commonUI/commonUI.actions';
import { preLoadImagePath } from '../../helpers/images';
import './troubleshoot.component.scss';
import { useHistory } from 'react-router-dom';
import { ScreenName } from "../../helpers/pos-message";
import { logs } from "../../pos-redux/troubleshoot/troubleshoot.actions";
import { localStorageService } from "../../services/api/utilities/localStorageService";
import { setSucess } from '../../pos-redux/errorHandler/errorHandler.actions';

function TroubleShootComponent() {
    const dispatch = useDispatch();
    const [commonUI] = useSelector(state => [state.commonUIReducer]);
    const history = useHistory();
    const [uiConfiguration] = useSelector(state => [state.uiConfiguration]);

    const welcomeTxt = "Feilsøkingsvindu for selvbetjent kasse";
    var items = [{ name: "Agent", status: null, displayName: "Agent" }, { name: "Kvitteringsskriver", status: null, displayName: "Printer" }, { name: "Bankterminal", status: null, displayName: "BankTerminal" }, { name: "Lystårn", status: null, displayName: "LightTower" }];

    const [actionIcon, setActionIcon] = useState(items);

    useEffect(() => {
        startAllService();
    }, []);

    const startAllService = async _ => {
        const response = await posAgentService.troublshootStausCheck();
        var result = null;
        if (response && response.data) {
            result = response;
        } else {
            result = response;
        }

        for (const item of actionIcon) {
            await clickItem(item, result);
        }
    };

    const back = () => {
        logs(ScreenName.TroubleShootComponent, "Tilbake", "Back to" + ScreenName.Welcome, uiConfiguration);
        history.push("/welcome");
    }

    const clearLocalStorage = () => {
        logs(ScreenName.TroubleShootComponent, "clearLocalStorage", "Location storage is cleared", uiConfiguration);
        localStorageService.clearStorage();
        setSucess('Cache data is clear successfully.');
    }

    const clickItem = async (products, response) => {
        logs(ScreenName.TroubleShootComponent, products.name, "Clicked on: " + products.name, uiConfiguration);

        if (products.name == items[0].name) {
            dispatch(showUILoader(true));
            if (response && response.data) {
                products.status = 1;
            } else {
                products.status = 0;
            }
            dispatch(showUILoader(false));
        }

        if (products.name == items[1].name) {
            dispatch(showUILoader(true));
            if (response && response.data) {
                var data = response.data;
                if (data.PrinterConnected) {
                    products.status = 1;
                } else {
                    products.status = 0;
                }
            } else {
                products.status = 0;
            }
            dispatch(showUILoader(false));
        }

        if (products.name == items[2].name) {
            dispatch(showUILoader(true));
            if (response && response.data) {
                var data = response.data;
                if (data.PaymentTerminalConnected) {
                    products.status = 1;
                } else {
                    products.status = 0;
                }
            } else {
                products.status = 0;
            }
            dispatch(showUILoader(false));
        }

        if (products.name == items[3].name) {
            dispatch(showUILoader(true));
            if (response && response.data) {
                var data = response.data;
                if (data.LightTowerVisible) {
                    products.status = 1;
                } else {
                    products.status = 0;
                }
            } else {
                products.status = 0;
            }
            dispatch(showUILoader(false));
        }
    }

    const icons = (products) => {
        if (products.status === null) {
            return preLoadImagePath.loading_squre;
        }
        if (products.status === 0) {
            return preLoadImagePath.white_cross_top;
        }
        if (products.status === 1) {
            return preLoadImagePath.check;
        }
    }

    const iconsClass = (products) => {
        if (products.status === null) {
            return 'mx-4 w-30'
        }
        if (products.status === 0) {
            return 'mx-4 w-30 icon-round dx-button-mode-contained dx-button-danger';
        }
        if (products.status === 1) {
            return 'mx-4 w-30 icon-round dx-button-mode-contained dx-button-success';
        }

    }

    const renderGroupName = (products, index) => {
        var classHTML = "btn btn-primary me-2 cursor-hand place-Center text-break";        
        // if (Number.isInteger((index + 1) / 3)) {
        //     classHTML = "btn btn-primary cursor-hand place-Center text-break";
        // } else {
        //     classHTML = "btn btn-primary me-2 cursor-hand place-Center text-break";
        // }
        return (
            <div className="grid-element me-2 mb-2 subgroup">
                <Button
                    type="normal"
                    stylingMode="outlined"
                    className={classHTML}>
                    <div id="troubleshoot-name" className="row">
                        <span>{products.name}</span>
                    </div>

                    <img
                        id={`${products.name}`}
                        src={icons(products)}
                        alt="img"
                        className={iconsClass(products)}
                    />

                </Button>
            </div>

        );
    }

    const renderHomeButton = () => {
        var classHTML = 'btn btn-primary cursor-hand place-Center text-break';

        return (
            <div className="grid-element me-2 mb-2 subgroup">
                <div className="me-2 mb-2">
                    <Button
                        type="normal"
                        stylingMode="outlined"
                        className={classHTML}
                        onClick={(clearLocalStorage)}
                    >
                        <div id="troubleshoot-name" className="row">
                            <span>Clear application cache</span>
                        </div>

                        <img src={`${preLoadImagePath.deleteblack}`}
                            alt="img"
                            className="mx-4 w-30"
                        />

                    </Button>
                </div>
                <div className="me-2 mb-2">
                    <Button
                        type="normal"
                        stylingMode="outlined"
                        className={classHTML}
                        onClick={(back)}
                    >
                        <div id="troubleshoot-name" className="row">
                            <span>Tilbake</span>
                        </div>

                        <img src={preLoadImagePath.undo_white}
                            alt="img"
                            className="mx-4 w-30"
                        />

                    </Button>
                </div>
            </div>
        );
    }

    return (
        <>
            <div id="troubleshootPage" className="d-flex align-items-center justify-content-center flex-column h-90-vh">
                <div className="inner-section-height d-flex align-items-center justify-content-center">
                    <div className="text-center">
                        <h2>{welcomeTxt}</h2>
                        <br />
                        {
                            actionIcon.map((value, index) => {
                                return renderGroupName(value, index);
                            })
                        }
                        {
                            renderHomeButton()
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export { TroubleShootComponent };
